import { Button, ToggleButtonGroup } from '@mui/joy'

const EventViewToggle = ({ className, options, activeValue, onChange }) => {
  return (
    <div className={`EventViewToggle ${className} inline-flex rounded-md`} role="group">
      <ToggleButtonGroup value={activeValue}>
        {options.map((option) => {
          return (
            <Button
              key={option.value}
              size="sm"
              type="button"
              onClick={() => onChange(option)}
              value={option.value}
            >
              {option.label}
            </Button>
          )
        })}
      </ToggleButtonGroup>
    </div>
  )
}

export default EventViewToggle