import { Button, Input } from '@mui/joy'
import { useState } from 'react'
import classNames from 'classnames'
// import SaveIcon from '@mui/icons-material/Save'

const TaskForm = ({ onSave, task = { name: '', description: '' }, disabled, isLoading }) => {
  const [displayMore, setDisplayMore] = useState(false)
  const [internalTask, setInternalTask] = useState(task)

  const handleSubmit = async (e) => {
    e.preventDefault()
    await onSave(internalTask)
    setInternalTask({ name: '', description: '' })
  }

  const handleInputChange = (e) => {
    setInternalTask({ ...internalTask, name: e.target.value })
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        disabled={disabled}
      >
        <div className={classNames('flex md:gap-4', { 'opacity-90': isLoading })}>
          <div className="w-full">
            <Input
              id="task-name"
              name="task-name"
              size="sm"
              value={internalTask.name || ''}
              onChange={handleInputChange}
              variant="outlined"
              required
              fullWidth
              className="w-full"
            />
          </div>
          <Button size="sm" type="submit" disabled={isLoading}>
            Add
          </Button>
        </div>
        {/*<div>*/}
        {/*  <Button type="button" variant="link" size="sm" onClick={() => setDisplayMore(!displayMore)}>*/}
        {/*    {displayMore ? '- Less' : '+ More'}*/}
        {/*  </Button>*/}
        {/*</div>*/}
        {displayMore && (
          <div>
            <div className="form-group flex flex-col">
              <label htmlFor="task-description">Description</label>
              <textarea
                className="form-control border my-2"
                id="task-description"
                name="task-description"
                value={internalTask.description}
                onChange={(e) => setInternalTask({ ...internalTask, description: e.target.value })}
              />
            </div>
          </div>
        )}
      </form>
    </div>
  )
}

export default TaskForm