import { SignupButton } from "../../../auth0-components/buttons/signup-button";

export default function SectionQuote() {
  return (
    <section>
        <div className="mx-auto container px-6 py-20 text-center">
          <div className="pb-10">
            <div className="relative inline-block">
              <blockquote className="absolute -left-9 -top-4 text-7xl opacity-25">&ldquo;</blockquote>
              <p className="text-xl text-gray-500">
                We are not given a short life but we make it short… Life is long if you know how to use it.<br/>
                <span className="text-gray-400">― Seneca</span>
              </p>
              <blockquote className="absolute -right-9 -top-4 text-7xl opacity-25">&ldquo;</blockquote>
            </div>
          </div>

          <SignupButton
            className="text-xl
              group
              inline-flex
              items-center
              justify-center
              rounded-full
              py-2
              px-8
              font-semibold
              focus:outline-none
              focus-visible:outline-2
              focus-visible:outline-offset-2
              bg-slate-900
              text-white
              hover:bg-slate-800
              hover:text-slate-100
              active:bg-slate-800
              active:text-slate-300
              focus-visible:outline-slate-900
              transition
              duration-75
              ease-in-out
              transform
              hover:scale-105"
          >
            Start for free now
          </SignupButton>
        </div>
      </section>
  )
}