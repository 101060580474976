import React, { createContext, useContext, useReducer } from 'react';

const initialState = {
  selectedCalendars: [],
  filters: [],
  page: 1,
};

function reducer(state, action) {
  switch (action.type) {
    case 'ADD_FILTER':
      const uniqueFilters = [...state.filters.filter(filter => filter.field !== action.payload.field), action.payload];
      return { ...state, filters: uniqueFilters, page: 1 };
    case 'REMOVE_FILTER':
      return { ...state, filters: state.filters.filter(filter => filter.field !== action.payload.field), page: 1 };
    case 'SET_PAGE':
      return { ...state, page: action.payload };
    case 'SET_SELECTED_CALENDARS':
      return { ...state, selectedCalendars: action.payload };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

const CalendarsStateContext = createContext();
const CalendarsDispatchContext = createContext();

function CalendarsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CalendarsStateContext.Provider value={state}>
      <CalendarsDispatchContext.Provider value={dispatch}>
        {children}
      </CalendarsDispatchContext.Provider>
    </CalendarsStateContext.Provider>
  );
}

function useCalendarsState() {
  const context = useContext(CalendarsStateContext);
  if (context === undefined) {
    throw new Error('useCalendarsState must be used within a CalendarsProvider');
  }
  return context;
}

function useCalendarsDispatch() {
  const context = useContext(CalendarsDispatchContext);
  if (context === undefined) {
    throw new Error('useCalendarsDispatch must be used within a CalendarsProvider');
  }
  return context;
}

export { CalendarsProvider, useCalendarsState, useCalendarsDispatch };
