import { toast } from 'react-hot-toast'
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined'
import ToastMessage from '../components/molecules/ToastMessage'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

const noop = (e) => {
  e?.preventDefault()
  e?.stopPropagation()
}

const featureNotAvailableToast = (e = null) => {
  noop(e)
  openToast({
    title: 'System Message',
    description: 'Sorry! This feature is not available in beta. We\'ll add it soon!',
    icon: <SmartToyOutlinedIcon fontSize="large"/>,
    e
  })
}

const openToast = ({ title, description, icon, e = null, options = {} }) => {
  noop(e)
  toast.remove()
  toast.custom((t) => (
    <ToastMessage currentToast={t} title={title} description={description} icon={icon}/>
  ), {
    position: 'top-right',
    duration: 15000,
    ...options
  })
}

const openSuccessToast = ({ title, description, e = null, options = {} }) => {
  noop(e)
  toast.remove()
  toast.custom((t) => (
    <ToastMessage currentToast={t} title={title} description={description} icon={<CheckCircleOutlinedIcon/>}/>
  ), {
    position: 'top-right',
    duration: 15000,
    ...options
  })
}

const openErrorToast = ({ title, description, e = null, options = {} }) => {
  noop(e)
  toast.remove()
  toast.custom((t) => (
    <ToastMessage currentToast={t} title={title} description={description} icon={<ErrorOutlineIcon/>}/>
  ), {
    position: 'top-right',
    duration: 15000,
    ...options
  })
}

export { openToast, featureNotAvailableToast, openSuccessToast, openErrorToast }

