import { useQuery } from '@tanstack/react-query'
import { fetchCalendars } from '../../services/calendar.service'
import { useCalendarsState } from '../../stores/calendars'

const defaultProps = {
  meta: { page: 1, perPage: 10, orderBy: 'id', orderDirection: 'asc', filters: [] },
}

export const useCalendarQuery = ({ meta } = defaultProps) => {
  const { filters } = useCalendarsState()
  const metaWithFilters = { ...meta, filters }
  const flattenFilters = filters.map((f) => `${f.field}__${f.operator}=${f.value}`)
  const queryKey = ['calendars', meta, flattenFilters.join('&')]

  const { isLoading, isError, data, error } = useQuery({
    queryKey,
    queryFn: () => fetchCalendars({ meta: metaWithFilters }),
  })

  return {
    isLoading,
    isError,
    data: data?.data,
    meta: data?.meta,
    error,
    queryKey
  }
}