import classNames from 'classnames'
import React, { useState } from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function FeatureList({ features }) {
  const [collapsedList, setCollapsedList] = useState(features.map(({ name }) => name))

  const toggleFeature = (name) => {
    if (collapsedList.includes(name)) {
      setCollapsedList(collapsedList.filter((item) => item !== name))
    } else {
      setCollapsedList([...collapsedList, name])
    }
  }

  return <ul role="list" className="my-4 space-y-4 text-left">
    {features.map(({ name, description, supported }) => (
      <li key={name} className={classNames('flex items-start space-x-3', { 'line-through decoration-gray-500': !supported })}>
          <svg className={classNames({
            'flex-shrink-0 w-5 h-5 mt-1': true,
            'text-gray-400': !supported,
            'text-green-500 dark:text-green-400': supported,
          })} fill="currentColor"
               viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"></path>
          </svg>
        <div className="block">
          <button type="button" className={classNames({
            'text-left': true,
            'text-gray-400': !supported,
            'cursor-default': !description,
          })} onClick={() => toggleFeature(name)}>
            {name}
            {description && (
              <span className="text-gray-400 ml-2">
                {collapsedList.includes(name) ?
                  <KeyboardArrowRightIcon /> :
                  <KeyboardArrowDownIcon />}
              </span>
            )}
          </button>
          {!collapsedList.includes(name) && description && <div className="text-gray-400">{description}</div>}
        </div>
      </li>
    ))}
  </ul>
}