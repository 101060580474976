import classNames from 'classnames'
import React from 'react'
import FeatureList from './FeatureList'

export default function PriceCard ({
  name = 'Starter',
  description,
  price = 0,
  frequency = 'month',
  actionButtonDescription = '100% satisfaction guaranteed, cancel anytime.',
  features = [],
  comingSoon = false,
  className,
  actionButton,
  children = null
}) {
  return (
    <div
      className={`flex flex-col max-w-lg p-6 mx-auto text-gray-900 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 xl:p-8 dark:bg-gray-800 dark:text-white ${className ?? ''}`}
    >
      <h3 className="mb-4 text-2xl font-semibold">
        {name}
        {/*{comingSoon && (*/}
        {/*  <span className="ml-2 font-normal text-xs relative -top-1 text-white dark:text-gray-400 bg-orange-400 p-1 rounded">Coming soon</span>*/}
        {/*)}*/}
      </h3>
      <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">{description}</p>
      <div className="flex items-baseline my-4 justify-center">
        {comingSoon ? (
          <span className="mr-2 text-5xl text-gray-300 font-light">Coming Soon</span>
        ) : (
          <>
            <span className="mr-2 text-5xl font-extrabold">${price}</span>
            <span className="text-gray-500 dark:text-gray-400">/{frequency}</span>
          </>
        )}
      </div>
      <div className="flex flex-1 justify-center mb-2">
        {actionButton}
      </div>
      <div className="text-center text-gray-500 my-2 font-bold">
        {actionButtonDescription}
      </div>

      <div className="border-t-2 border-dashed pt-4 mt-4">
        <FeatureList features={features}/>
      </div>

      {/* <ul role="list" className="my-8 space-y-4 text-left border-t-2 border-dashed pt-8">
        {features.map(({ name, supported }) => (
          <li className={classNames('flex items-center space-x-3', { 'line-through decoration-gray-500': !supported })}
              key={name}>
            {supported ? (
              <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                   viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"></path>
              </svg>
            ) : (
              <svg className="flex-shrink-0 w-4 h-4 text-gray-400 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                   fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"></path>
              </svg>
            )}
            <span>{name}</span>
          </li>
        ))}
      </ul> */}
      {children}
    </div>
  )
}