import CalendarsIndex from '../components/organisms/CalendarsIndex'
import { CalendarsProvider } from '../stores/calendars'

export const CalendarsPage = () => {
  return (
    <CalendarsProvider>
      <div className="CalendarsPage h-full overflow-y-auto bg-white">
        <CalendarsIndex />
      </div>
    </CalendarsProvider>
  );
}