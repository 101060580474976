import VerticalChart from './VerticalChart'
import _ from 'lodash'

const ChartEventsByTimeFrame = ({ data, title, onClick, minWidth, minHeight, height, width, className = '', options={ }}) => {
  let firstKey = Object.keys(data)[0]
  let dataWithGapsFilledIn = {}
  if (typeof firstKey == 'number') {
    // fill in the gaps
    let keys = Object.keys(data).map((key) => parseInt(key)).sort((a, b) => a - b)
    let firstVal = keys[0]
    let lastVal = keys[keys.length - 1]
    for (let currentVal = firstVal; currentVal <= lastVal; currentVal++) {
      dataWithGapsFilledIn[currentVal] = typeof data[currentVal] == 'undefined' ? 0 : data[currentVal]
    }
  } else {
    dataWithGapsFilledIn = data
  }

  let chartData = []
  _.each(dataWithGapsFilledIn, (total, label) => {
    chartData.push({ label, total })
  })

  return (
    <div className={`ChartEventsByTimeFrame ${className}`}>
      <div>{title}</div>
      <VerticalChart data={chartData} title={title} onClick={onClick} minWidth={minWidth} minHeight={minHeight} width={width} height={height} options={options.chartOptions}/>
    </div>
  );
}

export default ChartEventsByTimeFrame