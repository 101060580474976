import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined'
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined'
import { toast } from 'react-hot-toast'
import classNames from 'classnames'
import { useEventMutation } from '../hooks/useEventMutation'
import { useQueryClient } from '@tanstack/react-query'

const ProvideFeedback = ({ eventId, feedbackKey, data }) => {
  const queryClient = useQueryClient()
  const { mutate: mutateEvent, isError, isSuccess } = useEventMutation()

  const handleFeedback = async (content) => {
    await mutateEvent({
        id: eventId,
        payload: { id: data?.id, key: feedbackKey, content },
        action: 'CREATE_OR_UPDATE_FEEDBACK'
      },
      {
        onSuccess: () => {
          toast.success('Feedback submitted')
        },
        onError: () => {
          toast.error('There was an error submitting feedback')
        }
      })

    queryClient.invalidateQueries({ queryKey: ['events'] })
  }
  const isPositive = data?.content === 'positive'
  const isNegative = data?.content === 'negative'

  return (
    <span className="ProvideFeedback flex items-center gap-1">
      <span className="mr-4">How accurate is this info?</span>
      <button
        className={classNames('font-bold py-1 px-1 rounded hover:bg-gray-400 hover:text-white', { 'bg-green-500 text-white': isPositive })}
        onClick={() => handleFeedback('positive')}>
        <ThumbUpAltOutlinedIcon fontSize="small"/>
      </button>
      <button
        className={classNames('font-bold py-1 px-1 rounded text-gray-800 hover:bg-gray-400 hover:text-white', { 'bg-red-500 text-white': isNegative })}
        onClick={() => handleFeedback('negative')}>
        <ThumbDownAltOutlinedIcon fontSize="small"/>
      </button>
    </span>
  )
}

export default ProvideFeedback

