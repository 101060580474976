import * as React from 'react'
import Select from '@mui/joy/Select'
import Option from '@mui/joy/Option'
import { useCalendarQuery } from '../hooks/useCalendarQuery'
import { useCalendarsDispatch, useCalendarsState } from '../../stores/calendars'
import { useEffect } from 'react'
import { Skeleton } from '@mui/joy'
import { useState } from 'react'
import {
  fetchPreferredCalendarsFromLocalStorage,
  savePreferredCalendarsToLocalStorage
} from '../../utils/calendars.utils'
import { useEventsDispatch, useEventsState } from '../../stores/events'
import { Link } from 'react-router-dom'

export default function CalendarSelect () {
  const [isSetupInProgress, setIsSetupInProgress] = useState(true)
  const { isLoading, isError, data: calendarsResp, error, queryKey: calendarQueryKey } = useCalendarQuery({
    meta: { page: 1, perPage: 100, orderBy: 'name', orderDirection: 'asc', filters: [] },
  })
  const { filter: eventsFilters } = useEventsState()
  const eventsDispatch = useEventsDispatch()
  const calendarsDispatch = useCalendarsDispatch()
  const { selectedCalendars } = useCalendarsState()
  const { data: calendars = [], meta } = calendarsResp || {}

  const selectedCalendarsFromStorage = fetchPreferredCalendarsFromLocalStorage()

  const handleChange = (_e, newValue) => {
    calendarsDispatch({ type: 'SET_SELECTED_CALENDARS', payload: newValue })
    savePreferredCalendarsToLocalStorage(newValue)

    // check if eventFilters already has the selectedCalendars
    if (newValue && !eventsFilters?.some(filter => filter.field === 'calendar_id' && filter.operator === 'eq' && filter.value === newValue)) {
      eventsDispatch({ type: 'ADD_FILTER', payload: { field: 'calendar_id', operator: 'eq', value: newValue } })
    }
  }

  useEffect(() => {
    if (isLoading) return

    if (selectedCalendarsFromStorage.length > 0 && calendars.length) {
      const validPreferredCalendars = selectedCalendarsFromStorage.filter(calendarId => calendars.some(calendar => calendar.id === calendarId))
      const updatedSelectedCalendars = validPreferredCalendars.length > 0 ? validPreferredCalendars : calendars.map((calendar) => calendar.id)

      // initially set the selected calendars to the preferred calendars
      if (updatedSelectedCalendars !== selectedCalendarsFromStorage) {
        calendarsDispatch({ type: 'SET_SELECTED_CALENDARS', payload: updatedSelectedCalendars })
      }
    }
    setTimeout(() => setIsSetupInProgress(false), 10)
  }, [isLoading, calendars])

  if (isError) {
    return <div className="p-4 text-center">Something went wrong. Please refresh the page and try again.</div>
  }

  if (isLoading || isSetupInProgress) {
    return (
      <Skeleton variant="rectangular" width={208} height={36}/>
    )
  }

  if (!isLoading && !calendars.length) {
    return <div className="p-4 text-center">No calendars found. <Link className="underline" to="/my/calendars">Upload a
      calendar</Link>.</div>
  }

  return (
    <Select
      defaultValue={selectedCalendars}
      multiple
      onChange={handleChange}
      sx={{
        minWidth: '13rem',
      }}
      slotProps={{
        listbox: {
          sx: {
            width: '100%',
          },
        },
      }}
    >
      {calendars.map((calendar) => (
        <Option key={calendar.id} value={calendar.id}>
          {calendar.name}
        </Option>
      ))}
    </Select>
  )
}
