import { callExternalApi } from './external-api.service'

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL

export const me = async ({ withSubscriptions = false }) => {
  const accessToken = localStorage.getItem('accessToken')

  const config = {
    url: `${apiServerUrl}/api/users/me?with_subscriptions=${!!withSubscriptions}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }

  const { data, error } = await callExternalApi({ config })

  return {
    data: data || null,
    error,
  }
}

export const syncMe = async (accessToken, user) => {
  const config = {
    url: `${apiServerUrl}/api/users/sync_me`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: user
  }

  const { data, error } = await callExternalApi({ config })

  return {
    data: data || null,
    error,
  }
}

export const backendLogout = async () => {
  const accessToken = localStorage.getItem('accessToken')

  const config = {
    url: `${apiServerUrl}/api/users/logout`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }

  const { data, error } = await callExternalApi({ config })

  return {
    data: data || null,
    error,
  }
}