import * as React from 'react'
import FormControl from '@mui/joy/FormControl'
import Autocomplete from '@mui/joy/Autocomplete'
import CircularProgress from '@mui/joy/CircularProgress'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Tooltip from '@mui/joy/Tooltip'
import { usePeopleDispatch, usePeopleState } from '../../stores/people'
import { usePeopleQuery } from '../hooks/usePeopleQuery'
import { useState } from 'react'
import { useCalendarMutation } from '../hooks/useCalendarMutation'
import FormHelperText from '@mui/joy/FormHelperText'
import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'

function sleep (duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, duration)
  })
}

export default function CalendarUser ({ calendarId, calendarName, userEmail }) {
  const queryClient = useQueryClient()
  const [open, setOpen] = useState(false)
  const { page, filters } = usePeopleState()
  const peopleDispatch = usePeopleDispatch()
  const { isLoading, isError, data: peopleResp, queryKey: peopleQueryKey } = usePeopleQuery({
    meta: { page, perPage: 10, orderBy: 'email', orderDirection: 'asc', filters },
  })
  const {
    mutate: mutateCalendar,
    isLoading: setMainPersonLoading,
    isError: setMainPersonError,
    data: setMainPersonResp
  } = useCalendarMutation()
  const [value, setValue] = useState(userEmail || '')
  const [inputValue, setInputValue] = useState(userEmail || '')
  const loading = isLoading || setMainPersonLoading
  // concat('') otherwise a warning will be shown in the console for the empty string value
  const options = peopleResp?.data.map(person => person.email).concat('') || [userEmail || '']

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: peopleQueryKey })
  }, [calendarName])

  const handleValueChange = async (_e, value) => {
    setValue(value)
    await mutateCalendar({ action: 'SET_MAIN_PERSON', payload: { id: calendarId, user_email: value } })
  }

  const handleInputChange = (e, value) => {
    setInputValue(value)
    value && peopleDispatch({ type: 'ADD_FILTER', payload: { field: 'email', operator: 'like', value } })
  }

  return (
    <FormControl id="calendar-user">
      <div className="flex justify-between items-center py-2 gap-2">
        <Autocomplete
          className="w-full"
          placeholder="Who's the main user?"
          open={open}
          onOpen={() => {
            setOpen(true)
          }}
          onClose={() => {
            setOpen(false)
          }}
          // isOptionEqualToValue={(option, value) => option === value}
          // getOptionLabel={(option) => option.email}
          options={options}
          loading={loading}
          endDecorator={
            loading ? (
              <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }}/>
            ) : null
          }
          value={value}
          onChange={handleValueChange}
          inputValue={inputValue}
          onInputChange={handleInputChange}
        />
        <Tooltip title="Used to display user stats, and striked-out declined events." arrow
                 placement="right">
          <HelpOutlineIcon className="scale-75 relative"/>
        </Tooltip>
      </div>
      {isError && <FormHelperText>Oops! something went wrong.</FormHelperText>}
    </FormControl>
  )
}
