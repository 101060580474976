import { useEventsDispatch } from "../../stores/events"
import CalendarStats from "./CalendarStats"

const CalendarStatsWithEventFilterActions = ({ calendarId, className }) => {
  const eventsDispatch = useEventsDispatch()

  const handleOnClick = (_event, value) => {
    const utc = new Date(value)
    eventsDispatch({ type: 'ADD_FILTER', payload: { field: 'year', operator: 'eq', value: parseInt(utc.getUTCFullYear()) } })
    eventsDispatch({ type: 'ADD_FILTER', payload: { field: 'month', operator: 'eq', value: parseInt(utc.getUTCMonth() + 1) } })
    eventsDispatch({ type: 'ADD_FILTER', payload: { field: 'dom', operator: 'eq', value: parseInt(utc.getUTCDate()) } })
  }

  return (
    <CalendarStats calendarId={calendarId} className={className} onCalendarHeatmapClick={handleOnClick} />
  )
}

export default CalendarStatsWithEventFilterActions
