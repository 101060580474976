import React, { useEffect } from 'react'
import { Grid, Tooltip } from '@mui/joy'
import ChartEventsByTimeFrame from './molecules/ChartEventsByTimeFrame'
import { noop } from 'lodash'
import DashboardBlock from './molecules/DashboardBlock'
import { useStatsQuery } from './hooks/useStatsQuery'
import { Loader } from './Loader'
import ButtonLink from './atoms/ButtonLink'
import CalendarHeatmap from './organisms/CalendarHeatmap'
import CollapsableCard from './molecules/CollapsableCard'
import AngledTopRightLabel from './atoms/AngledTopRightLabel'
import CalendarSelect from './organisms/CalendarSelect'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useCalendarsState } from '../stores/calendars'
import { useStatsDispatch } from '../stores/stats'

export const MainDashboard = () => {
  const { selectedCalendars } = useCalendarsState()
  const { data: statsData, isLoading: statsLoading } = useStatsQuery()
  const dispatchStats = useStatsDispatch()

  useEffect(() => {
    dispatchStats({ type: 'ADD_FILTER', payload: { field: 'calendar_id', operator: 'eq', value: selectedCalendars } })
  }, [dispatchStats, selectedCalendars])

  if (statsLoading || !statsData) {
    return <Loader/>
  }

  const thisWeekDash = [
    {
      id: 'events',
      title: 'Events',
      percentage: statsData.data.this_week.events.percentage,
      subtitle: 'vs. last week',
      data: statsData.data.this_week.events.data
    },
    {
      id: 'stress',
      title: 'Stress',
      percentage: statsData.data.this_week.stress.percentage,
      subtitle: 'vs. last week',
      data: statsData.data.this_week.stress.data
    },
    {
      id: 'time-in-meetings',
      title: 'Time-in-meetings (hrs)',
      percentage: statsData.data.this_week.time_in_meetings.percentage,
      subtitle: 'vs. last week',
      data: statsData.data.this_week.time_in_meetings.data,
    },
    {
      id: 'conflicts',
      title: <span>Conflicts <AngledTopRightLabel>Coming Soon</AngledTopRightLabel></span>,
      percentage: statsData.data.this_week.conflicts.percentage,
      subtitle: 'vs. last week',
      data: statsData.data.this_week.conflicts.data
    },
    {
      id: 'cost',
      title: (
        <div>
          Est. Cost <AngledTopRightLabel>Coming Soon</AngledTopRightLabel>
        </div>
      ),
      percentage: statsData.data.this_week.conflicts.percentage,
      subtitle: 'vs. last week',
      data: statsData.data.this_week.conflicts.data
    },
  ]

  const nextWeekDash = [
    {
      id: 'events',
      title: 'Events',
      percentage: statsData.data.next_week.events.percentage,
      subtitle: 'vs. this week',
      data: statsData.data.next_week.events.data
    },
    {
      id: 'stress',
      title: 'Stress',
      percentage: statsData.data.next_week.stress.percentage,
      subtitle: 'vs. this week',
      data: statsData.data.next_week.stress.data
    },
    {
      id: 'time-in-meetings',
      title: 'Time-in-meetings (hrs)',
      percentage: statsData.data.next_week.time_in_meetings.percentage,
      subtitle: 'vs. this week',
      data: statsData.data.next_week.time_in_meetings.data,
    },
    {
      id: 'conflicts',
      title: <span>Conflicts <AngledTopRightLabel>Coming Soon</AngledTopRightLabel></span>,
      percentage: statsData.data.this_week.conflicts.percentage,
      subtitle: 'vs. last week',
      data: statsData.data.this_week.conflicts.data
    },
    {
      id: 'cost',
      title: <span>Est. Cost <AngledTopRightLabel>Coming Soon</AngledTopRightLabel></span>,
      percentage: statsData.data.this_week.conflicts.percentage,
      subtitle: 'vs. last week',
      data: statsData.data.this_week.conflicts.data
    },
  ]

  return (
    <div className="h-full px-4">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold pt-4">Dashboard</h2>
        
        <div className="flex justify-end pt-4">
          <Tooltip title="Select the calendars from the list to show only their stats" arrow placement="right" className="relative top-2">
            <HelpOutlineIcon className="scale-75 relative ml-2" />
          </Tooltip>
          <CalendarSelect/>
        </div>
      </div>


      <CollapsableCard className="p-0 mt-4">
        <CalendarHeatmap className="border-none p-0" calendarId={selectedCalendars} />
      </CollapsableCard>

      <h2 className="text-2xl font-semibold pt-4">This week</h2>
      <Grid container sx={{ flexGrow: 1 }} className="py-4 gap-4">
        {thisWeekDash.map((item, index) => (
          <DashboardBlock
            key={'this-week-' + item.id}
            title={<span className="text-xl">{item.title}</span>}
            percentage={item.percentage}
            subtitle={item.subtitle}
            options={{ percentageOnTopRight: true }}
            percentageColorDirection={item.percentageColorDirection || 1}
          >
            <ChartEventsByTimeFrame
              className="mt-6"
              minWidth={300}
              height={200}
              data={item.data}
              onClick={noop}
              options={{
                chartOptions: { hideYAxis: true }
              }}
            />
          </DashboardBlock>
        ))}
      </Grid>

      <h2 className="text-2xl font-semibold pt-2">Next week</h2>
      <Grid container sx={{ flexGrow: 1 }} className="py-4 gap-4">
        {nextWeekDash.map((item, index) => (
          <DashboardBlock
            key={'next-week-' + item.id}
            title={<span className="text-xl">{item.title}</span>}
            percentage={item.percentage}
            subtitle={item.subtitle}
            options={{ percentageOnTopRight: true }}
            percentageColorDirection={item.percentageColorDirection || 1}
          >
            <ChartEventsByTimeFrame
              className="mt-6"
              minWidth={300}
              height={200}
              data={item.data}
              onClick={noop}
              options={{
                chartOptions: { hideYAxis: true }
              }}
            />
          </DashboardBlock>
        ))}
      </Grid>

      <h2 className="text-2xl font-semibold pt-2">Overall</h2>
      <Grid container sx={{ flexGrow: 1 }} className="py-4 gap-4">
        <DashboardBlock
          title={<span className="flex items-center gap-2">Events last 30 days</span>}
          subtitle="vs. next 30 days"
          value={statsData.data.overall.events_last_30d.data}
          percentage={statsData.data.overall.events_last_30d.percentage}
        />
        <DashboardBlock
          title={<span className="flex items-center gap-2">Events next 30 days</span>}
          subtitle="vs. last 30 days"
          value={statsData.data.overall.events_next_30d.data}
          percentage={statsData.data.overall.events_next_30d.percentage}
        />
        <DashboardBlock
          title={
            <span className="flex items-center gap-2">
              PTO this year
              <AngledTopRightLabel>Coming Soon</AngledTopRightLabel>
            </span>
          }
          subtitle="vs. last year"
          value={0}
          percentage={0}
        />
      </Grid>

      <div className="flex justify-start py-4">
        <ButtonLink to="/contact" variant="plain">
          Suggest a new statistic panel <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                             xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
        </svg>
        </ButtonLink>
      </div>
    </div>
  )
}