import { Link } from 'react-router-dom';
import BackgroundBlob from '../../../assets/background-blob.png';

export default function SectionFaq({ faqData }) {
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
    >
      <img
        alt=""
        loading="lazy"
        width="1558"
        height="946"
        decoding="async"
        data-nimg="1"
        className="absolute left-1/2 top-0 max-w-none -translate-y-1/4 translate-x-[-30%] opacity-10"
        style={{ color: 'transparent' }}
        src={BackgroundBlob}
      />
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
        <div className="mx-auto max-w-3xl lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-neutral sm:text-4xl"
          >
            Frequently asked questions
          </h2>
          <p className="mt-4 text-lg tracking-tight text-neutral">
            If you can’t find what you’re looking for,{' '}
            <Link to="/contact" className="text-primary hover:text-secondary underline">
              email our support team
            </Link>. We'll be happy to help you.
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
        >
          {faqData.map((questionAndAnswer, index) => (
            <li key={index} className="flex flex-col gap-y-4">
              <h3 className="text-lg font-semibold leading-6 text-gray-900">
                {questionAndAnswer.title}
              </h3>
              <p className="text-sm text-gray-700">
                {questionAndAnswer.description}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
