import React from 'react'
import { PageFooter } from '../components/PageFooter'
import Header from '../components/Header'

const links = [
  {
    href: '/',
    label: 'Home',
  },
  // disable the playground during BETA
  // {
  //   href: "/playground/dashboard",
  //   label: "Playground",
  // },
  {
    href: '/pricing',
    label: 'Pricing',
  },
  {
    href: 'https://blog.calendartrends.com',
    label: 'Blog',
  },
  {
    href: '/contact',
    label: 'Contact',
  },
  // {
  //   href: "/about",
  //   label: "About",
  // },
]

export const PageLayout = ({ children, withFooter = true }) => {
  return (
    <div className="page-layout h-screen flex flex-col">
      <Header links={links}/>
      <div className="page-layout__content flex-1">{children}</div>
      {withFooter && <PageFooter/>}
    </div>
  )
}
