import { toast } from 'react-hot-toast'
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined'

const ToastMessage = ({ currentToast, icon, title, description }) => (
  <div
    className={`${
      currentToast.visible ? 'translate-x-0' : 'translate-x-[200%]'
    } transition-all max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
  >
    <div className="flex-1 w-0 p-4">
      <div className="flex items-start">
        <div className="flex-shrink-0 pt-0.5 bg-gray-200 w-16 h-16 rounded-full flex justify-center items-center">
          {icon}
        </div>
        <div className="ml-3 flex-1">
          <p className="text-sm font-medium text-gray-900">
            {title}
          </p>
          <p className="mt-1 text-sm text-gray-500">
            {description}
          </p>
        </div>
      </div>
    </div>
    <div className="flex border-l border-gray-200">
      <button
        onClick={() => toast.dismiss(currentToast.id)}
        className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
      >
        Close
      </button>
    </div>
  </div>
)

ToastMessage.defaultProps = {
  icon: <SmartToyOutlinedIcon className="h-8 w-8 text-gray-800"/>,
}

export default ToastMessage

