export const transformEvents = (events) => {
  return events.map((event) => {
    return {
      id: event.id,
      title: event.summary,
      allDay: event.all_day,
      start: new Date(event.starts_at),
      end: new Date(event.ends_at),
      originalEvent: event
    }
  })
}

export const getDateFromEvents = (events) => {
  const lastEvent = events[0] // events are sorted by start date desc
  const isLastEventInThePast = lastEvent && lastEvent.end < new Date()
  if (isLastEventInThePast) {
    return lastEvent.start
  } else {
    return new Date() // today
  }
}

export const getColorFromId = (id) => {
  const hue = id * 137.508 // Use golden angle to spread colors evenly
  return `hsl(${hue}, 50%, 75%)` // Use fixed saturation and lightness for consistency
}

export const scrollToCurrentTime = () => {
  const currentTimeIndicator = document.querySelector('.rbc-current-time-indicator')
  if (!currentTimeIndicator) return

  const scrollContainer = document.querySelector('.rbc-time-content')
  const scrollHeight = -80 // enough to see the whole hour block above the current time indicator
  scrollContainer.scrollTo({
    top: currentTimeIndicator.offsetTop + scrollHeight,
    behavior: 'smooth'
  })
}

export const getTimezoneFromEvents = (events) => {
  return events[0]?.tzid
}

export const getBrowserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}
