
const FreeTrialCard = ({ hasActiveSubscription, trialEndsAt }) => {
  const hasFreeTrialEnded = trialEndsAt && new Date(trialEndsAt) < new Date()
  const trialExpiresInDays = trialEndsAt && Math.round((new Date(trialEndsAt) - new Date()) / (1000 * 60 * 60 * 24))

  if (hasActiveSubscription) {
    return null
  }

  return (
    <div className={`flex flex-col max-w-lg p-6 mx-auto text-gray-900 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 xl:p-8 dark:bg-gray-800 dark:text-white`}>
      <h3 className="mb-4 text-2xl font-semibold">
        Free Trial
        {hasFreeTrialEnded
          ? <span className="ml-2 font-normal text-xs relative -top-1 text-white dark:text-gray-400 bg-red-600 p-1 rounded">ended</span>
          : <span className="ml-2 font-normal text-xs relative -top-1 text-white dark:text-gray-400 bg-orange-400 p-1 rounded">Expiring soon</span>}
      </h3>
      <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
        {hasFreeTrialEnded 
        ? "Your trial period has ended. Start your monthly subscription today to continue using our services."
        : `Your trial period is expiring in ${trialExpiresInDays} days. You can either wait until the end of the trial or start your monthly subscription today to avoid service interruption.` }
      </p>
    </div>
  )
}

export default FreeTrialCard

