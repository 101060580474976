import React from "react";
import '../styles/components/code-snippet.css'
import { Link } from 'react-router-dom'

export const CodeSnippet = ({ title, code = "", path = null }) => {
  const copyCodeToClipboard = () => {
    const el = document.createElement('textarea');
    el.value = code;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    alert('copied!')
  }

  return (
    <div className="bg-gray-200 rounded-lg p-4 relative">
      <div className="absolute top-2 right-3 opacity-50">
        {path && <Link to={path} target="_blank" className="mr-4 hover:underline"><i className="fa-solid fa-up-right-from-square"></i> open in new tab</Link>}

        <button onClick={copyCodeToClipboard} className="hover:underline">
          <i className="far fa-copy"></i> copy to clipboard
        </button>
      </div>

      <h2 className="text-2xl mb-6">
        {path ? <Link to={path} className="hover:underline" target="_blank">{title}</Link> : title}
      </h2>

      <pre className="text-gray-800 text-sm font-mono">{code}</pre>
    </div>
  )
};
