import { useEventsStatsQuery } from '../hooks/useEventsStatsQuery'
import ChartEventsByTimeFrame from '../molecules/ChartEventsByTimeFrame'
import { useEventsDispatch, useEventsState } from '../../stores/events'
import classNames from 'classnames'
import CollapsableCard from '../molecules/CollapsableCard'

const EventsStats = ({ className = '' }) => {
  const eventsDispatch = useEventsDispatch()
  const { filters } = useEventsState()

  const { isError, data: eventsStatsResp, error } = useEventsStatsQuery()
  const handleYearChartClick = (data) => {
    eventsDispatch({ type: 'ADD_FILTER', payload: { field: 'year', operator: 'eq', value: parseInt(data.label) } })
  }

  const handleMonthChartClick = (data) => {
    eventsDispatch({ type: 'ADD_FILTER', payload: { field: 'month', operator: 'eq', value: parseInt(data.label) } })
  }

  const handleDOWChartClick = (data) => {
    eventsDispatch({ type: 'ADD_FILTER', payload: { field: 'dow', operator: 'eq', value: parseInt(data.label) } })
  }

  const handleDOMChartClick = (data) => {
    eventsDispatch({ type: 'ADD_FILTER', payload: { field: 'dom', operator: 'eq', value: parseInt(data.label) } })
  }

  const handleRemoveYearFilter = () => {
    eventsDispatch({ type: 'REMOVE_FILTER', payload: { field: 'year', operator: 'eq' } })
  }

  const handleRemoveMonthFilter = () => {
    eventsDispatch({ type: 'REMOVE_FILTER', payload: { field: 'month', operator: 'eq' } })
  }

  const handleRemoveDOWFilter = () => {
    eventsDispatch({ type: 'REMOVE_FILTER', payload: { field: 'dow', operator: 'eq' } })
  }

  const handleRemoveDOMFilter = () => {
    eventsDispatch({ type: 'REMOVE_FILTER', payload: { field: 'dom', operator: 'eq' } })
  }

  if (isError) {
    console.log({ error })
    return <div className="p-4 text-center">Something went wrong. Please refresh the page and try again.</div>
  }

  const defaultEventsStats = {
    data: {
      total_by_month: {},
      total_by_year: {},
      total_by_day_of_week: {},
      total_by_day_of_month: {},
    }
  }
  const { data: eventsStats } = eventsStatsResp || defaultEventsStats

  let eventsByMonth = eventsStats.total_by_month
  if (typeof eventsByMonth[12] == 'undefined') {
    eventsByMonth[12] = 0
  }
  if (typeof eventsByMonth[1] == 'undefined') {
    eventsByMonth[1] = 0
  }

  let eventsByDOW = eventsStats.total_by_day_of_week
  if (typeof eventsByDOW[6] == 'undefined') {
    eventsByDOW[6] = 0
  }
  if (typeof eventsByDOW[0] == 'undefined') {
    eventsByDOW[0] = 0
  }

  let eventsByDOM = eventsStats.total_by_day_of_month
  if (typeof eventsByDOM[31] == 'undefined') {
    eventsByDOM[31] = 0
  }
  if (typeof eventsByDOM[1] == 'undefined') {
    eventsByDOM[1] = 0
  }

  const clearBtnClasses = 'ml-3 py-0 px-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700'

  return (
    <div className={classNames(`EventsStats ${className} p-4 overflow-hidden`)}
         style={styles.eventsStats}>
      <CollapsableCard title="Filters" tooltip="Click on a bar on the graph to filter" initiallyExpanded={true}>
        <div className="flex">
          <div>
            <ChartEventsByTimeFrame
              minWidth={300}
              height={200}
              data={eventsStats.total_by_year}
              title={
                <div className="flex justify-center">
                  <div style={{ marginLeft: '60px' }}>
                    Events by Year
                  </div>
                  <button
                    className={`${clearBtnClasses} ${filters.find((f) => f.field == 'year') ? '' : 'invisible'}`}
                    onClick={handleRemoveYearFilter}
                  >
                    Clear
                  </button>
                </div>}
              onClick={handleYearChartClick}
            />
          </div>
          <div>
            <ChartEventsByTimeFrame
              minWidth={300}
              height={200}
              data={eventsByMonth}
              title={
                <div className="flex justify-center">
                  <div style={{ marginLeft: '60px' }}>Events by Month</div>
                  <button
                    className={`${clearBtnClasses} ${filters.find((f) => f.field == 'month') ? '' : 'invisible'}`}
                    onClick={handleRemoveMonthFilter}
                  >
                    Clear
                  </button>
                </div>}
              onClick={handleMonthChartClick}
            />
          </div>
          <div>
            <ChartEventsByTimeFrame
              minWidth={300}
              height={200}
              data={eventsByDOW}
              title={
                <div className="flex justify-center">
                  <div style={{ marginLeft: '60px' }}>Events by Day of Week</div>
                  <button
                    className={`${clearBtnClasses} ${filters.find((f) => f.field == 'dow') ? '' : 'invisible'}`}
                    onClick={handleRemoveDOWFilter}
                  >
                    Clear
                  </button>
                </div>}
              onClick={handleDOWChartClick}
            />
          </div>
          <div>
            <ChartEventsByTimeFrame
              minWidth={300}
              height={200}
              data={eventsByDOM}
              title={
                <div className="flex justify-center">
                  <div style={{ marginLeft: '60px' }}>Events by Day of Month</div>
                  <button
                    className={`${clearBtnClasses} ${filters.find((f) => f.field == 'dom') ? '' : 'invisible'}`}
                    onClick={handleRemoveDOMFilter}
                  >
                    Clear
                  </button>
                </div>}
              onClick={handleDOMChartClick}
            />
          </div>
        </div>
      </CollapsableCard>
    </div>
  )
}

const styles = {
  eventsStats: {
    maxWidth: '100%',
    overflow: 'auto'
  }
}

export default EventsStats