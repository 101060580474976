import React, { createContext, useContext, useEffect, useState } from 'react'
import { createConsumer } from "@rails/actioncable";

const CableContext = createContext();

function CableProvider({ children }) {
  const accessToken = localStorage.getItem('accessToken')
  const actionCableUrl = process.env.NODE_ENV === 'production' ? `wss://calendartrends.com/cable?token=${accessToken}` : `ws://localhost:6060/cable?token=${accessToken}`

  const CableApp = {}
  CableApp.cable = createConsumer(actionCableUrl)

  return <CableContext.Provider value={CableApp}>{children}</CableContext.Provider>;
}

function useCable({ subscription, onReceived, onConnected, onDisconnected, onRejected }) {
  const context = useContext(CableContext);
  if (context === undefined) {
    throw new Error("useCable must be used within a CableProvider");
  }

  const [channel, setChannel] = useState(null)

  useEffect(() => {
    if (context.cable) {
      const existingChannel = context.cable.subscriptions.subscriptions.find(sub => sub.identifier === JSON.stringify(subscription));
      if (!existingChannel) {
        const newChannel = context.cable.subscriptions.create(subscription, {
        received: onReceived,
        connected: onConnected,
        disconnected: onDisconnected,
        rejected: onRejected
      })
        setChannel(newChannel)
      } else {
        setChannel(existingChannel);
      }
    }

    return () => {
      if (channel) {
        channel.unsubscribe()
      }
    }
  }, [context.cable])

  return channel
}

export { CableContext, CableProvider, useCable };