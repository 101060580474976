import * as React from 'react'
import Avatar from '@mui/joy/Avatar'
import AvatarGroup from '@mui/joy/AvatarGroup'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import Card from '@mui/joy/Card'
import CardContent from '@mui/joy/CardContent'
import CardActions from '@mui/joy/CardActions'
import Typography from '@mui/joy/Typography'
import classNames from 'classnames'
import EventParticipantsTooltip from './EventParticipantsTooltip'
import { DateTime } from 'luxon'
import AlarmOnIcon from '@mui/icons-material/AlarmOn'
import AlarmOffIcon from '@mui/icons-material/AlarmOff'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Tooltip from '@mui/joy/Tooltip'
import { useEffect, useState } from 'react'
import AvTimerIcon from '@mui/icons-material/AvTimer'
import EventRepeatIcon from '@mui/icons-material/EventRepeat'
import CrisisAlertOutlinedIcon from '@mui/icons-material/CrisisAlertOutlined'
import Grid3x3OutlinedIcon from '@mui/icons-material/Grid3x3Outlined'
import { useEventsQuery } from '../hooks/useEventsQuery'
import { Skeleton } from '@mui/joy'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import ThumbsUpDownOutlinedIcon from '@mui/icons-material/ThumbsUpDownOutlined'
import ProvideFeedback from '../organisms/ProvideFeedback'
import { useEventsState } from '../../stores/events'
import { formatDate } from '../../utils/calendars.utils'

const Event = ({ event: eventInProps = {}, onClose }) => {
  const { filters } = useEventsState()
  const isGroupBySummaryChecked = filters.find((filter) => filter.field === 'summary' && filter.operator === 'group')?.value
  const { isLoading, isError, data: eventsResp, error } = useEventsQuery({
    meta: {
      page: 1,
      perPage: 1,
      orderBy: 'id',
      orderDirection: 'asc',
      filters: [
        { field: 'event_id', operator: 'eq', value: eventInProps.id },
        { field: 'with_stress', operator: 'eq', value: true },
        { field: 'with_people', operator: 'eq', value: true },
      ]
    }
  })
  const [event, setEvent] = useState(eventInProps)

  useEffect(() => {
    if (!isGroupBySummaryChecked && !isLoading && !isError && eventsResp?.data?.[0]?.id === eventInProps.id) {
      setEvent(eventsResp.data[0])
    }
  }, [eventsResp])

  const getPersonByIndex = (index) => (event?.people?.[index] || { email: 'Unknown' })
  const eventLocation = () => {
    if (event.location) {
      const isUrl = event.location.startsWith('http')
        || event.location.startsWith('www')
      const doNotLinkIdentifiers = [
        'zoom',
        'team',
        'online',
        'hangouts',
        'meet',
        'in person',
        'offline',
        'whatsapp'
      ]
      const doNotLink = doNotLinkIdentifiers.some((identifier) => event.location.toLowerCase().includes(identifier))

      return (
        <Tooltip title="Location" arrow placement="left">
          <Typography
            level="body-sm"
            className="mt-4"
            startDecorator={<LocationOnIcon/>}
            sx={{ alignItems: 'flex-start' }}
          >
            {doNotLink ?
              event.location
              : (
                <a href={isUrl ? event.location : `https://www.google.com/maps/search/${event.location}`}
                   target="_blank"
                   rel="noreferrer">
                  {event.location}
                </a>)
            }
          </Typography>
        </Tooltip>
      )
    }
  }

  const recurrence = () => {
    if (event.recurrence_id) {
      return (
        <Tooltip title="This event is part of a series of events" arrow placement="left">
          <Typography
            level="body-sm"
            className="mt-4"
            startDecorator={<EventRepeatIcon/>}
            sx={{ alignItems: 'flex-start' }}
          >
            This event repeats
          </Typography>
        </Tooltip>
      )
    }
  }

  const stress = () => (
    <Tooltip title="Stress level" arrow placement="left">
      <Typography level="body-sm" className="mt-4" startDecorator={<CrisisAlertOutlinedIcon/>}>
        {!isLoading ? (
          <>
            {event.stress_key ? (
              <>
                {event.stress_key}
                <Tooltip
                  title={`Stress level is ${event.stress_level}. Factors: ${event.stress_factors.map((f) => f.replace(/_/g, ' ')).join(', ') || 'Default factors'}`}
                  arrow placement="top-end">
                  <HelpOutlineIcon className="scale-75 relative"/>
                </Tooltip>
              </>
            ) : 'N/A'}
          </>
        ) : (
          <Skeleton variant="rectangular" width="100%" height={24}/>
        )}
      </Typography>
    </Tooltip>
  )

  const registerEscKeyClosesEventPanel = (e) => {
    if (e.key === 'Escape' || e.key === 'Esc' || e.key === ']') {
      onClose()
    }
  }

  // register the event listener
  useEffect(() => {
    window.addEventListener('keydown', registerEscKeyClosesEventPanel)
    return () => {
      window.removeEventListener('keydown', registerEscKeyClosesEventPanel)
    }
  }, [])

  return (
    <Card
      className="event-card z-10"
      variant="plain"
      sx={{
        width: 320,
        // to make the card resizable
        resize: 'horizontal',
        position: 'fixed',
        bottom: 0,
        right: 0,
        height: 'calc(100vh - 65px)',
        boxShadow: 'lg',
        borderLeft: '1px solid #e0e0e0',
        '--Card-radius': '0px',
      }}
    >
      {event ? (
        <>

          <Tooltip title="Press ] or Esc to close" arrow placement="left">
            <Button
              variant="soft"
              size="sm"
              onClick={onClose}
              className="!absolute top-2 -left-5 !rounded-3xl z-10 !p-1 scale-75 transition ease-in-out hover:scale-100"
              title="Close"
            >
              <ChevronRightIcon/>
            </Button>
          </Tooltip>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div className="flex gap-4 items-center">
              <Avatar size="lg" title={event.organizer || getPersonByIndex(0).email || 'Unknown'}>
                <Skeleton variant="circular" width={36} height={36} loading={isLoading}>
                  {(event.organizer || getPersonByIndex(0).email || 'Unknown')[0].toUpperCase()}
                </Skeleton>
              </Avatar>
              <Typography
                level="body-sm"
                className={classNames('overflow-ellipsis overflow-hidden', {
                  'max-w-[120px]': event.people?.length > 1
                })}
                title={event.organizer || getPersonByIndex(0).email || 'Unknown'}
              >
                <Skeleton variant="rectangular" width="100%" height={24} loading={isLoading}>
                  {event.organizer || getPersonByIndex(0).email || <i className="text-gray-400">Unknown</i>}
                </Skeleton>
              </Typography>
            </div>

            {!isLoading ? (
              <>
                {event.people?.length > 1 && (
                  <EventParticipantsTooltip event={event}>
                    <AvatarGroup
                      size="sm"
                      sx={{ '--Avatar-size': '28px' }}
                      className="transition ease-in-out hover:scale-110 cursor-pointer"
                    >
                      {event.people.slice(0, 3).map((person) => (
                        <Avatar key={person.email}>{(person.email[0] || 'U').toUpperCase()}</Avatar>
                      ))}
                      {event.people.length > 4 && <Avatar>+{event.people.length - 4}</Avatar>}
                    </AvatarGroup>
                  </EventParticipantsTooltip>
                )}
              </>
            ) : (
              <Skeleton variant="rectangular" width="100%" height={24}/>
            )}
          </Box>
          <CardContent sx={{
            overflow: 'auto',
          }}>
            <Typography level="title-lg" className="pt-2 pb-3">{event.summary}</Typography>
            <Typography
              level="body-sm"
              className={classNames('max-h-fit overflow-auto', { 'opacity-50': !event.description })}
            >
              {event.description || <i>This event does not have a description.</i>}
            </Typography>
          </CardContent>
          <CardActions buttonFlex="0 1 120px" style={{ flexDirection: 'column' }}>
            <div className="w-full flex flex-col justify-end border-t-2 pt-4">
              <div className="flex flex-col gap-2 mb-3">
                {eventLocation()}
                {recurrence()}
                {stress()}
                <Tooltip title="Starts at" arrow placement="left">
                  <Typography level="body-sm" className="mt-4" startDecorator={<AlarmOnIcon/>}>
                    {formatDate(event.starts_at)}
                  </Typography>
                </Tooltip>
                <Tooltip title="Ends at" arrow placement="left">
                  <Typography level="body-sm" className="mt-4" startDecorator={<AlarmOffIcon/>}>
                    {formatDate(event.ends_at)}
                  </Typography>
                </Tooltip>
                <Tooltip title="Duration" arrow placement="left">
                  <Typography level="body-sm" className="mt-4" startDecorator={<AvTimerIcon/>}>
                    {event.duration_sentence}
                  </Typography>
                </Tooltip>
                <Tooltip title="Event ID" arrow placement="left">
                  <Typography level="body-sm" className="mt-4" startDecorator={<Grid3x3OutlinedIcon/>}>
                    <div className="overflow-x-auto whitespace-nowrap">
                      ({event.id}) - {event.uid}
                    </div>
                  </Typography>
                </Tooltip>
                <Tooltip title="Provide feedback about this event" arrow placement="left">
                  <Typography level="body-sm" className="mt-4" startDecorator={<ThumbsUpDownOutlinedIcon/>}>
                    <ProvideFeedback
                      eventId={event.id}
                      feedbackKey="event_info"
                      data={event.feedbacks.find((f) => f.key === 'event_info')}
                    />
                  </Typography>
                </Tooltip>
              </div>
              <Button variant="solid" color="primary" onClick={onClose} size="sm">
                Close
              </Button>
            </div>

            {/*<IconButton variant="outlined" color="neutral" sx={{ mr: 'auto' }}>*/}
            {/*  <FavoriteBorder />*/}
            {/*</IconButton>*/}
            {/*<Button variant="outlined" color="neutral">*/}
            {/*  View*/}
            {/*</Button>*/}
          </CardActions>
        </>
      ) : (
        <div>
          Loading event...
        </div>
      )}
    </Card>
  )
}

export default Event