import { useState } from 'react'
import { DateTime } from 'luxon'
import TableSortAndSelection from '../atoms/TableSortAndSelection'
import { useEventsQuery } from '../hooks/useEventsQuery'
import { Loader } from '../Loader'
import { useEventsState, useEventsDispatch } from '../../stores/events'
import { formatDate } from '../../utils/calendars.utils'

window.DateTime = DateTime

const EventTable = ({ className = '' }) => {
  const { page, filters } = useEventsState()
  const eventsDispatch = useEventsDispatch()
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(localStorage.getItem('rowsPerPage') || 10))
  const [orderBy, setOrderBy] = useState('starts_at')
  const [orderDirection, setOrderDirection] = useState('desc')
  const { isLoading, isError, data: eventsResp, error } = useEventsQuery({
    meta: { page, perPage: rowsPerPage, orderBy, orderDirection, filters },
  })

  const handleSetPage = (newPage) => {
    eventsDispatch({ type: 'SET_PAGE', payload: newPage })
  }

  if (isLoading) {
    return <Loader/>
  }

  if (isError) {
    return <div className="p-4 text-center">Something went wrong. Please refresh the page and try again.</div>
  }

  const { data: events, meta } = eventsResp || {}

  if (events.length === 0) {
    return (
      <div className="p-4 text-center">
        No events found.
        {/*Upload a <Link to="/my/calendars">calendar to get started</Link>.*/}
      </div>
    )
  }
  const isGroupBySummaryChecked = filters.find((filter) => filter.field === 'summary' && filter.operator === 'group')?.value

  const headCells = [
    {
      id: 'summary',
      numeric: false,
      label: 'Summary',
      style: { width: 200 },
      format: (value) => value || <i className="text-gray-400">Unknown</i>
    },
    {
      id: 'organizer',
      numeric: false,
      label: 'Organizer',
      style: { width: 200 },
      title: (row) => row.organizer || 'Unknown',
      format: (value) => value || <i className="text-gray-400">Unknown</i>
    },
    {
      id: 'total_people',
      numeric: false,
      label: 'People',
      style: { width: 60 },
    },
    {
      id: 'stress_level',
      numeric: false,
      label: 'Stress',
      style: { width: 60 },
      format: (_value, row) => row.stress_key
    },
    {
      id: 'starts_at',
      numeric: false,
      label: 'Starts At',
      styles: { width: 180 },
      format: (value) => formatDate(value, {...DateTime.DATETIME_MED_WITH_WEEKDAY, month: '2-digit', year: '2-digit', timeZoneName: 'short'})
    },
    {
      id: 'ends_at',
      numeric: false,
      label: 'Ends At',
      styles: { width: 180 },
      format: (value) => formatDate(value, {...DateTime.DATETIME_MED_WITH_WEEKDAY, month: '2-digit', year: '2-digit', timeZoneName: 'short'})
    },
    {
      id: 'duration',
      numeric: false,
      label: 'Duration',
      style: {
        width: 80,
      },
      title: (row) => row['duration_sentence'],
      format: (_durationInMinutes, row) => {
        return (
          <div style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
            {row['duration_sentence']}
          </div>
        )
      }
    },
  ]

  const handleRequestSort = ({ property, direction }) => {
    setOrderBy(property)
    setOrderDirection(direction)
  }

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(value)
    localStorage.setItem('rowsPerPage', value)
  }

  const handleRowClick = (row) => {
    eventsDispatch({ type: 'SET_SELECTED_EVENT', payload: row })
  }

  return (
    <div className={`EventTable ${className}`}>
      <TableSortAndSelection
        isGrouped={isGroupBySummaryChecked}
        headCells={headCells}
        rows={events}
        onChangePage={handleSetPage}
        page={page}
        totalPages={meta?.total_pages}
        totalRows={meta?.total_count}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        onRequestSort={handleRequestSort}
        orderDirection={orderDirection}
        orderBy={orderBy}
        disableSelection
        onRowClick={handleRowClick}
      />
    </div>
  )
}

export default EventTable