import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect, useCallback } from 'react'
import { syncMe } from '../services/user.service'
import { useNavigate } from 'react-router-dom'
import { Loader } from '../components/Loader'
import { useGlobalDispatch } from '../stores/global'
import { fetchCalendars } from '../services/calendar.service'
import { PageLayout } from '../layouts/page-layout'
import { openErrorToast } from '../utils/toast.utils'
import { debounce } from 'lodash'

export default function SyncMePage () {
  const { user, getAccessTokenSilently } = useAuth0()
  const navigate = useNavigate()
  const globalDispatch = useGlobalDispatch()

  const callSyncMe = useCallback(debounce(async ({ onSuccess, onError }) => {
    const syncMeStatus = await localStorage.getItem('syncMeStatus')
    if (syncMeStatus === 'in_progress') {
      return
    }

    const accessToken = await getAccessTokenSilently()
    console.log({ accessToken })

    await localStorage.setItem('accessToken', accessToken)
    await localStorage.setItem('syncMeStatus', 'in_progress')

    const resp = await syncMe(accessToken, user)
    await localStorage.setItem('syncMeStatus', 'done')
    if (resp.error) {
      openErrorToast({
        title: 'Error',
        description: `There was an error trying to login. Our team has been notified and we'll fix this ASAP. ${typeof resp.error.message === 'string' ? resp.error.message : JSON.stringify(resp.error)}`,
      })
      onError()
    } else {
      globalDispatch({ type: 'SET_USER', user: resp.data.user })
      onSuccess()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, 300), [getAccessTokenSilently, user, globalDispatch])

  const syncMeAndSetAccessToken = useCallback(async () => {
    const success = await callSyncMe({
      onSuccess: async () => {
        const resp = await fetchCalendars()
        if (!resp.data.meta.total_count) {
          navigate('/my/calendars')
        } else {
          navigate('/my/dashboard')
        }
      },
      onError: () => {
        navigate('/')
      },
    })
    if (!success) {
      return navigate('/')
    }
  }, [callSyncMe, navigate])

  useEffect(() => {
    syncMeAndSetAccessToken()
  }, [syncMeAndSetAccessToken])

  return (
    <PageLayout>
      <Loader/>
    </PageLayout>
  )
}
