import React from 'react'
import { Aside } from '../components/Aside'
import Header from '../components/Header'

export const CTLayout = ({ children }) => {
  return (
    <div className="calendar-trends-page-layout">
      <Header links={[]}/>
      <Aside />
      {children}
    </div>
  )
}