import React from 'react';
import { useParams } from 'react-router-dom';
import { EventsProvider } from '../../stores/events';

const withEventsProvider = (Component) => (props) => {
  const { personId, calendarId } = useParams();
  let filters = [];

  if (personId) {
    filters = [{ field: 'personId', value: personId }];
  } else if (calendarId) {
    filters = [{ field: 'calendarId', value: calendarId }];
  }

  return (
    <EventsProvider extendInitialState={{ filters }}>
      <Component {...props} />
    </EventsProvider>
  );
};

export default withEventsProvider;
