import React, { useEffect, useState } from 'react'
import logoSvg from "../assets/calendartrends-icon.svg";

/**
 * A fun loader for any page that needs to load data.
 *
 * Example usage for small spaces:
 * <div className="relative w-full h-36 scale-75">
 *   <Loader />
 * </div>
 *
 * Example usage for full page:
 * <Loader />
 */

export const Loader = ({ className = '' }) => {
  const funnyLoadingMessages = [
    "Reticulating splines...",
    "Counting backwards from Infinity...",
    "Making things appear out of thin air...",
    "Summoning digital elves...",
    "Putting the hamster back in the wheel...",
    "Loading... and sipping coffee...",
    "Searching for the meaning of life...",
    "Creating more loadings...",
    "Polishing the loading bar...",
    "Charging the flux capacitor...",
    "Asking the server nicely...",
    "Convincing the bits to align...",
    "Unicorns are being assembled...",
    "Spinning up the warp drive...",
    "Training our AI hamsters...",
  ];

  const getRandomLoadingMessage = () => {
    return funnyLoadingMessages[Math.floor(Math.random() * funnyLoadingMessages.length)];
  }
  const [currentLoadingMessage, setCurrentLoadingMessage] = useState(getRandomLoadingMessage());

  useEffect(() => {
    const randomIntervalBetween500And2000 = Math.floor(Math.random() * (1000 - 500 + 1) + 500);

    const interval = setInterval(() => {
      setCurrentLoadingMessage(getRandomLoadingMessage());
    }, randomIntervalBetween500And2000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // old classname: absolute top-0 left-0 w-full h-full flex
  return (
    <div className={className || "flex w-full h-full justify-center items-center"}>
      <div className="m-auto w-auto text-center flex flex-col items-center animate-pulse">
        <img src={logoSvg} alt="Loading..." width="100px" height="100px" className="animate-bounce scale-75" />
        <div className="shadow-sm w-full h-1 mb-2"></div>
        <div className="text-gray-400">
          {currentLoadingMessage}
        </div>
      </div>
    </div>
  );
};
