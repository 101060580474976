import { callExternalApi } from './external-api.service'
import { generateApiParams } from '../utils/services.utils'

const DEFAULT_META = { page: 1, perPage: 10, orderBy: 'id', orderDirection: 'asc', filters: [] }

export const fetchEvents = async ({ meta = DEFAULT_META, view = 'list' } = { meta: DEFAULT_META, view: 'list' }) => {
  let { accessToken, filtersForUrl, baseUrl } = generateApiParams(meta)

  const config = {
    url: `${baseUrl}/events?page=${meta.page}&per_page=${meta.perPage}&order_by=${meta.orderBy}&order_direction=${meta.orderDirection}&${filtersForUrl}&view=${view}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }

  const { data, error } = await callExternalApi({ config }, false)

  return {
    data: data || null,
    error,
  }
}

export const fetchEventsStats = async ({ meta = DEFAULT_META } = { meta: DEFAULT_META }) => {
  let { accessToken, filtersForUrl, baseUrl } = generateApiParams(meta)

  const config = {
    url: `${baseUrl}/events/stats?${filtersForUrl}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }

  return callExternalApi({ config }, false)
}

export const submitFeedback = async (eventId, { id, key, content }) => {
  const accessToken = localStorage.getItem('accessToken')
  const config = {
    url: `${process.env.REACT_APP_API_SERVER_URL}/api/events/${eventId}/feedbacks`,
    method: id ? 'PATCH' : 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      id,
      key,
      feedbackable_id: eventId,
      feedbackable_type: 'Event',
      content,
    },
  }

  return callExternalApi({ config }, false)
}