import { useMutation, useQueryClient } from '@tanstack/react-query'
import { cancelStripeSubscriptionAtStripe, reactivateStripeSubscriptionAtStripe } from '../../services/stripe.service'

const noop = () => {}

export const useSubscriptionMutation = ({ callback } = { callback: noop }) => {
  const queryClient = useQueryClient()
  const queryKey = ['userDetails'] // `userDetails` because the subscription is tightly coupled with the user's details

  const callSubscriptionMethod = async ({ payload, action }) => {
    if (action.toUpperCase() === 'CREATE') {
      throw new Error('Not implemented')

      // TODO: This was the original implementation, but was replaced with the Stripe Button
      // to create the subscription directly at Stripe.
      // const { data, error } = await createStripeSubscription(payload.priceId)
      // if (error) {
      //   console.error('Error creating subscription:', error)
      //   throw new Error('Failed to create subscription')
      // }
      // return data
    } else if (action.toUpperCase() === 'CANCEL') {
      // the ...atStripe is to differentiate between our local implementation of "Subscription"
      // and the one at Stripe. We're using the Stripe Button to create the subscription directly
      // at Stripe, and not the one in our backend.
      const { data, error } = await cancelStripeSubscriptionAtStripe(payload.subscriptionId)
      
      if (callback) {
        await callback({ data, error})
      }

      if (error) {
        console.error('Error cancelling subscription:', error)
        throw new Error('Failed to cancel subscription')
      }
      return data
    } else if (action.toUpperCase() === 'REACTIVATE') {
      const { data, error } = await reactivateStripeSubscriptionAtStripe(payload.subscriptionId)
      if (callback) {
        await callback({ data, error})
      }

      if (error) {
        console.error('Error reactivating subscription:', error)
        throw new Error('Failed to reactivate subscription')
      }
      
      return data
    }
    throw new Error('Action not supported')
  }
  const { mutate, isLoading, isError, data, error } = useMutation({
    queryKey,
    mutationFn: callSubscriptionMethod,
    onMutate: () => {
      queryClient.cancelQueries(queryKey)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey)
    }
  })

  return {
    mutate,
    isLoading,
    isError,
    data,
    error
  }
}
