import ProductScreenshotCalendar from '../../../assets/product-screenshot-calendar.jpg';
import ProductScreenshotStats from '../../../assets/product-screenshot-stats.jpg';
import ProductScreenshotFocus from '../../../assets/product-screenshot-focus.jpg';
import ProductScreenshotEvents from '../../../assets/product-screenshot-events.jpg';
import { useState } from 'react';
import classNames from 'classnames';

export default function SectionFeaturesTop() {
  const [activeSection, setActiveSection] = useState(0);

  const handleTabChange = (tabIndex) => {
    setActiveSection(tabIndex);
  };

  const header = 'What is this?';
  const subheader = (
    <>
      A tool to analyze events in your calendar so you can:
    </>
  );
  const imageContainerWidth = 884;

  const sections = [
    {
      id: 'events',
      title: 'Events',
      subtitle:
        'Feel Empowered and In Control',
      description:
        'Identify which activities provide the most value and focus on them, leading to better outcomes and more meaningful progress.',
      image: ProductScreenshotEvents,
      icon: (
        <svg aria-hidden="true" className="h-9 w-9" fill="none">
          <path
            opacity=".5"
            d="M8 17a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z"
            fill="#fff"
          ></path>
          <path
            opacity=".3"
            d="M8 24a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z"
            fill="#fff"
          ></path>
          <path
            d="M8 10a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z"
            fill="#fff"
          ></path>
        </svg>
      ),
    },
    {
      id: 'stats',
      title: 'Stats',
      subtitle:
        'Become Exceptionally Organized',
      description:
        'Compare weekly schedules to refine your time management skills and become a highly organized individual.',
      image: ProductScreenshotStats,
      icon: (
        <svg
          version="1.1"
          id="Capa_1"
          x="0px"
          y="0px"
          viewBox="0 0 489.2 489.2"
          fill="#fff"
          width="100%"
          height="100%"
          style={{ padding: '6px'}}
        >
          <g>
            <g>
              <path
                d="M489.2,65.25c0-35.7-28.5-65.2-64.2-65.2s-64.2,29.6-64.2,65.2c0,28.1,17.7,52.3,42.6,61.4l-28.2,232.3
			c-6.6,0.5-12.9,2-18.7,4.4l-97.1-99.6c1.6-5.8,2.5-11.8,2.5-18.1c0-35.7-28.5-65.2-64.2-65.2s-64.2,29.6-64.2,65.2
			c0,15.6,5.4,29.9,14.5,41.2l-62.3,75.7c-6.7-2.4-14-3.8-21.5-3.8c-35.7,0-64.2,29.6-64.2,65.2c0,35.7,28.5,65.2,64.2,65.2
			c36.7,0,64.2-29.6,64.2-65.2c0-13.3-4-25.8-10.9-36.2l64.8-78.8c4.9,1.2,10,1.9,15.3,1.9c14.5,0,27.8-4.9,38.5-13.1l89.4,91.8
			c-6.2,10-9.7,21.8-9.7,34.4c0,35.7,28.5,65.2,64.2,65.2s64.2-29.6,64.2-65.2c0-23.1-12-43.6-30.1-55.2l29.5-241
			C470.1,119.55,489.2,94.45,489.2,65.25z M425,40.85c12.2,0,23.4,11.2,23.4,24.5s-11.2,24.5-23.4,24.5s-23.4-11.2-23.4-24.5
			S411.7,40.85,425,40.85z M64.2,448.45c-12.2,0-23.4-11.2-23.4-24.5c0-13.2,10.2-24.5,23.4-24.5s23.4,11.2,23.4,24.5
			C87.7,437.25,76.4,448.45,64.2,448.45z M197.7,270.15c-12.2,0-23.4-11.2-23.4-24.5c0-13.2,10.2-24.5,23.4-24.5
			s23.4,11.2,23.4,24.5C221.2,258.95,209.9,270.15,197.7,270.15z M380.1,448.45c-12.2,0-23.4-11.2-23.4-24.5
			c0-13.2,10.2-24.5,23.4-24.5c13.2,0,23.4,11.2,23.4,24.5C403.6,437.25,392.4,448.45,380.1,448.45z"
              />
            </g>
          </g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </svg>
      ),
    },
    {
      id: 'focus',
      title: 'Focus',
      subtitle:
        'Achieve More, Stress Less',
      description:
        <>
          Prioritize high-impact tasks, ensuring you dedicate your time and energy
          to activities that move the needle and drive your success.
        </>,
      image: ProductScreenshotFocus,
      icon: (
        <svg aria-hidden="true" className="h-9 w-9" fill="none">
          <path
            opacity=".5"
            d="M25.778 25.778c.39.39 1.027.393 1.384-.028A11.952 11.952 0 0 0 30 18c0-6.627-5.373-12-12-12S6 11.373 6 18c0 2.954 1.067 5.659 2.838 7.75.357.421.993.419 1.384.028.39-.39.386-1.02.036-1.448A9.959 9.959 0 0 1 8 18c0-5.523 4.477-10 10-10s10 4.477 10 10a9.959 9.959 0 0 1-2.258 6.33c-.35.427-.354 1.058.036 1.448Z"
            fill="#fff"
          ></path>
          <path
            d="M12 28.395V28a6 6 0 0 1 12 0v.395A11.945 11.945 0 0 1 18 30c-2.186 0-4.235-.584-6-1.605ZM21 16.5c0-1.933-.5-3.5-3-3.5s-3 1.567-3 3.5 1.343 3.5 3 3.5 3-1.567 3-3.5Z"
            fill="#fff"
          ></path>
        </svg>
      ),
    },
  ];

  return (
    <section
      id="secondary-features"
      aria-label={header}
      className="pb-14 pt-20 sm:pb-20 sm:pt-32 lg:pb-32 border-t border-slate-200 bg-slate-50 bg-grid-slate-100 shadow-md shadow-inner"
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl md:text-center">
          <h2 className="font-display text-3xl tracking-tight text-neutral sm:text-4xl md:text-5xl">
            {header}
          </h2>
          <p className="mt-4 text-lg tracking-tight text-neutral">
            {subheader}
          </p>
        </div>
        {/* Mobile */}
        <div className="-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
          {sections.map((section, index) => (
            <div key={section.id} onClick={() => handleTabChange(index)}>
              <div className="mx-auto max-w-2xl">
                <div className="w-9 rounded-lg bg-blue-600">{section.icon}</div>
                <h3 className="mt-6 text-sm font-medium text-primary">
                  {section.title}
                </h3>
                <p className="mt-2 font-display text-xl text-neutral">
                  {section.subtitle}
                </p>
                <p className="mt-4 text-sm text-neutral">
                  {section.description}
                </p>
              </div>
              <div className="relative mt-10 pb-10">
                <div className="absolute -inset-x-4 bottom-0 top-8 bg-slate-200 sm:-inset-x-6"></div>
                <div className="relative mx-auto w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
                  <img
                    alt=""
                    loading="lazy"
                    width="1688"
                    height="856"
                    decoding="async"
                    className="w-full"
                    style={{ color: 'transparent' }}
                    sizes="52.75rem"
                    src={section.image}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Desktop */}
        <div className="hidden lg:mt-20 lg:block">
          <div
            className="grid grid-cols-3 gap-x-8"
            role="tablist"
            aria-orientation="horizontal"
          >
            {sections.map((section, index) => (
              <div
                key={section.id}
                className={classNames('relative opacity-75 hover:opacity-100', {
                  'opacity-100': index === activeSection,
                })}
                onClick={() => handleTabChange(index)}
              >
                <div
                  className={classNames('w-9 rounded-lg', {
                    'bg-slate-500': index !== activeSection,
                    'bg-blue-600': index === activeSection,
                  })}
                >
                  {section.icon}
                </div>
                <h3
                  className={classNames('mt-6 text-sm font-medium', {
                    'text-neutral': index !== activeSection,
                    'text-primary': index === activeSection,
                  })}
                >
                  <button
                    className="ui-not-focus-visible:outline-none"
                    id="headlessui-tabs-tab-:R2bdqfja:"
                    role="tab"
                    type="button"
                    aria-selected="false"
                    tabIndex="-1"
                    data-headlessui-state=""
                    aria-controls="headlessui-tabs-panel-:R1ddqfja:"
                  >
                    <span className="absolute inset-0"></span>
                    {section.title}
                  </button>
                </h3>
                <p
                  className={classNames('mt-2 font-display text-xl', {
                    'text-neutral': index !== activeSection,
                    'text-neutral': index === activeSection,
                  })}
                >
                  {section.subtitle}
                </p>
                <p
                  className={classNames('mt-4 text-sm', {
                    'text-neutral': index !== activeSection,
                    'text-neutral': index === activeSection,
                  })}
                >
                  {section.description}
                </p>
              </div>
            ))}
          </div>
          <div className="relative mt-20 overflow-hidden rounded-4xl bg-slate-200 px-14 py-16 xl:px-16">
            <div
              className="-mx-5 flex transition duration-500 ease-in-out"
              style={{
                transform: `translateX(-${
                  imageContainerWidth * activeSection
                }px)`,
              }}
            >
              {sections.map((section, index) => (
                <div
                  key={section.id}
                  className={classNames(
                    'px-5 transition duration-500 ease-in-out ui-not-focus-visible:outline-none',
                    {
                      'opacity-100': index === activeSection,
                      'opacity-60': index !== activeSection,
                    }
                  )}
                  aria-hidden="true"
                  id={`headlessui-tabs-panel-:R1ddqfja:${section.id}`}
                  role="tabpanel"
                  tabIndex="-1"
                  data-headlessui-state=""
                  aria-labelledby={`headlessui-tabs-tab-:R2bdqfja:${section.id}`}
                  onClick={() => handleTabChange(index)}
                >
                  <div className="w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
                    <img
                      alt=""
                      loading="lazy"
                      width="1688"
                      height="856"
                      decoding="async"
                      data-nimg="1"
                      className="w-full"
                      style={{ color: 'transparent' }}
                      sizes="52.75rem"
                      src={section.image}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="pointer-events-none absolute inset-0 rounded-4xl ring-1 ring-inset ring-slate-900/10"></div>
          </div>
        </div>
      </div>
    </section>
  );
}
