import * as React from 'react'
import Box from '@mui/joy/Box'
import Tooltip from '@mui/joy/Tooltip'
import Typography from '@mui/joy/Typography'
import Avatar from '@mui/joy/Avatar'
import classNames from 'classnames'

export default function EventParticipantsTooltip ({ event, children }) {
  return (
    <Tooltip
      placement="bottom-end"
      variant="outlined"
      arrow
      title={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 320,
            justifyContent: 'center',
            p: 1,
          }}
        >
          <Typography
            fontSize="sm"
            textColor="grey"
            className="border-b-2 shadow-sm pb-2 text-center"
          >
            {event.people.length} participants
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, width: '100%', mt: 1 }} className="max-h-[200px] overflow-auto">
            <ul>
              {event.people.map((person) => (
                <li key={person.email} className="flex gap-4 items-center my-2">
                  <Avatar title={person.email}>{(person.email[0] || 'U').toUpperCase()}</Avatar>

                  <Tooltip
                    title={(person.partstat || 'NEEDS-ACTION').toLowerCase().replace(/(^|\s)\S/g, L => L.toUpperCase())}
                    arrow placement="left">
                    <span className={classNames({
                      'line-through': person.partstat === 'DECLINED',
                      'opacity-50': person.partstat === 'NEEDS-ACTION',
                      'text-green-500': person.partstat === 'ACCEPTED',
                    })}>{person.email}</span>
                  </Tooltip>
                </li>
              ))}
            </ul>

            {/*<AdjustIcon color="success"/>*/}
            {/*<div>*/}
            {/*  <Typography fontWeight="lg" fontSize="sm">*/}
            {/*    [system] grey is no more recognized as color with the sx prop*/}
            {/*  </Typography>*/}
            {/*  <Typography textColor="text.secondary" fontSize="sm" sx={{ mb: 1 }}>*/}
            {/*    Duplicates I have searched the existing issues Latest version I have*/}
            {/*    tested the …*/}
            {/*  </Typography>*/}
            {/*  <Chip size="sm" color="danger" sx={{ fontWeight: 'lg' }}>*/}
            {/*    bug 🐛*/}
            {/*  </Chip>*/}
            {/*  <Chip size="sm" color="primary" sx={{ ml: 1, fontWeight: 'lg' }}>*/}
            {/*    package: system*/}
            {/*  </Chip>*/}
            {/*</div>*/}
          </Box>
        </Box>
      }
    >
      {children}
    </Tooltip>
  )
}