import { PageLayout } from '../layouts/page-layout'
import EarlyAdoptersPlan from '../components/EarlyAdoptersPlan'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import ButtonLink from '../components/atoms/ButtonLink'
// import useMixpanel, { EVENTS } from '../components/hooks/useMixpanel'

export default function ThankYouPage () {
  // const { track } = useMixpanel()

  return (
    <PageLayout>
      <div
        className="container px-4 pt-24 mx-auto md:pt-32 lg:px-0 dark:bg-gray-900 flex flex-col justify-items-center">
        <div className="text-center">
          <h1
            className="mb-3 text-3xl font-bold text-gray-900 sm:text-4xl sm:leading-none sm:tracking-tight dark:text-white">
            Thank you!
          </h1>
          <p className="mb-6 text-lg font-normal text-gray-500 sm:text-xl dark:text-gray-400">
            We received your payment and you can start using the app right away. <br/>
          </p>

          <ButtonLink to="/my/dashboard">
            Go to dashboard
          </ButtonLink>
        </div>
      </div>
    </PageLayout>
  )
}