import React from 'react';
import Checkbox from '@mui/joy/Checkbox';
import EventViewToggle from '../organisms/EventViewToggle';
import CollapsableCard from './CollapsableCard';

const SearchAndViewSection = ({
  className,
  handleSearchChange,
  isGroupBySummaryChecked,
  handleGroupByChangeClick,
  handleEventToggle,
  eventToggleOptions,
  selectedView
}) => {
  return (
    <CollapsableCard title="Search and View Options" initiallyExpanded={true} className={className}>
      <div className="items-center justify-between block sm:flex">
        <div className="flex items-center mb-4 sm:mb-0">
          <form className="sm:pr-3" action="#" method="GET">
            <label htmlFor="events-search" className="sr-only">
              Search
            </label>
            <div className="relative w-48 mt-1 sm:w-64 xl:w-96 flex gap-2">
              <input
                type="text"
                name="email"
                id="events-search"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Search..."
                onChange={handleSearchChange}
              />
            </div>
            <Checkbox
              className="mt-2"
              label="Group by summary and year"
              checked={!!isGroupBySummaryChecked}
              onClick={handleGroupByChangeClick}
            />
          </form>
        </div>
        <div className="w-screen flex justify-end">
          <EventViewToggle
            onChange={handleEventToggle}
            options={eventToggleOptions}
            activeValue={selectedView}
          />
        </div>
      </div>
    </CollapsableCard>
  );
};

export default SearchAndViewSection;
