import React from 'react'
import TasksContainer from './organisms/TasksContainer'
import { TasksProvider } from '../stores/tasks'
import { EventsProvider } from '../stores/events'
import { useCalendarsState } from '../stores/calendars'
import { MyEvents } from './organisms/MyEvents'
import { Grid } from '@mui/joy'
import { fetchPreferredCalendarsFromLocalStorage } from '../utils/calendars.utils'

export const Focus = ({ children }) => {
  const selectedCalendarsFromStorage = fetchPreferredCalendarsFromLocalStorage()
  const { selectedCalendars } = useCalendarsState()
  let eventsFilters = [
    { field: 'personId', operator: 'eq', value: 'my' },
  ]

  if (selectedCalendars.length > 0) {
    eventsFilters.push({
      field: 'calendar_id',
      operator: 'eq',
      value: selectedCalendars
    })
  } else if (selectedCalendarsFromStorage.length > 0) {
    eventsFilters.push({
      field: 'calendar_id',
      operator: 'eq',
      value: selectedCalendarsFromStorage
    })
  }

  return (
    <div className="h-full px-4 overflow-hidden">
      {/*<div className="grid gap-4 xl:grid-cols-2 2xl:grid-cols-3">*/}
      {/*  <div*/}
      {/*    className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">*/}
      {/*    <div className="flex items-center justify-between mb-4">*/}
      {/*      <div className="flex-shrink-0">*/}
      {/*        <span className="text-xl font-bold leading-none text-gray-900 sm:text-2xl dark:text-white">$45,385</span>*/}
      {/*        <h3 className="text-base font-light text-gray-500 dark:text-gray-400">Sales this week</h3>*/}
      {/*      </div>*/}
      {/*      <div*/}
      {/*        className="flex items-center justify-end flex-1 text-base font-medium text-green-500 dark:text-green-400">*/}
      {/*        12.5%*/}
      {/*        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*/}
      {/*          <path fillRule="evenodd"*/}
      {/*                d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"*/}
      {/*                clipRule="evenodd"></path>*/}
      {/*        </svg>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <EventsProvider extendInitialState={{ filters: eventsFilters }}>
        <TasksProvider>
          <Grid container sx={{ flexGrow: 1 }} className="h-full py-4 overflow-auto md:overflow-hidden">
            <Grid xs={12} md={4} className="TasksContainerGrid flex w-full h-full overflow-hidden mb-4 md:mb-0">
              <TasksContainer/>
            </Grid>
            <Grid xs={12} md={8} className="MyEventsGrid flex w-full h-full overflow-hidden pl-2">
              <MyEvents/>
            </Grid>
          </Grid>
        </TasksProvider>
      </EventsProvider>
    </div>
  )
}