import React, { createContext, useContext, useReducer } from 'react'
import { getUniqueFilters } from '../utils/store.utils'
import _ from 'lodash'

const initialState = {
  filters: [],
  page: 1,
}

function reducer (state, action) {
  switch (action.type) {
    case 'ADD_FILTER':
      return { ...state, filters: getUniqueFilters(state.filters, action.payload), page: 1 }
    case 'REMOVE_FILTER':
      return {
        ...state,
        filters: state.filters.filter(filter => filter.field !== action.payload.field && filter.field.operator !== action.payload.operator),
        page: 1
      }
    case 'SET_DATE_RANGE':
      const newStateWithStartsAt = getUniqueFilters(state.filters, {
        field: 'starts_at',
        operator: 'gte',
        value: action.payload.startsAt
      })
      const newStateWithStartsAtAndEndsAt = getUniqueFilters(newStateWithStartsAt, {
        field: 'ends_at',
        operator: 'lte',
        value: action.payload.endsAt
      })
      return { ...state, filters: newStateWithStartsAtAndEndsAt, page: 1 }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const StatsStateContext = createContext()
const StatsDispatchContext = createContext()

function StatsProvider ({ children, extendInitialState }) {
  const [state, dispatch] = useReducer(reducer, _.merge({}, initialState, extendInitialState))

  return (
    <StatsStateContext.Provider value={state}>
      <StatsDispatchContext.Provider value={dispatch}>
        {children}
      </StatsDispatchContext.Provider>
    </StatsStateContext.Provider>
  )
}

function useStatsState () {
  const context = useContext(StatsStateContext)
  if (context === undefined) {
    throw new Error('useStatsState must be used within a StatsProvider')
  }
  return context
}

function useStatsDispatch () {
  const context = useContext(StatsDispatchContext)
  if (context === undefined) {
    throw new Error('useStatsDispatch must be used within a StatsProvider')
  }
  return context
}

export { StatsProvider, useStatsState, useStatsDispatch }
