import Link from '@mui/joy/Link'
import { Link as RouterLink } from 'react-router-dom'

const ButtonLink = ({ children, to, ...props }) => (
  <Link
    component={RouterLink}
    to={to}
    underline="none"
    variant="solid"
    color="primary"
    sx={{ '--Link-gap': '0.5rem', px: 2, py: 0.5, borderRadius: 'md' }}
    {...props}
  >
    {children}
  </Link>
)

export default ButtonLink
