import { callExternalApi } from './external-api.service'

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
const DEFAULT_META = { page: 1, perPage: 10, orderBy: 'id', orderDirection: 'asc', filters: [] }

function generateApiParams (meta) {
  const accessToken = localStorage.getItem('accessToken')
  const ignoredFilters = []
  const filtersForUrl = meta.filters
    .filter((f) => !ignoredFilters.includes(f.field))
    .map((f) => `${f.field}__${f.operator}=${f.value}`)
    .join('&')
  let baseUrl = ''
  baseUrl = `${apiServerUrl}/api`

  return { accessToken, filtersForUrl, baseUrl }
}

export const fetchTasks = async ({ meta = DEFAULT_META } = { meta: DEFAULT_META}) => {
  let { accessToken, filtersForUrl, baseUrl } = generateApiParams(meta)

  const config = {
    url: `${baseUrl}/tasks?page=${meta.page}&per_page=${meta.perPage}&order_by=${meta.orderBy}&order_direction=${meta.orderDirection}&${filtersForUrl}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config }, false);

  return {
    data: data || null,
    error,
  };
};

export const createTask = async (task) => {
  const accessToken = localStorage.getItem('accessToken')
  const config = {
    url: `${apiServerUrl}/api/tasks`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: task
  };

  const { data, error } = await callExternalApi({ config }, false);

  return {
    data: data || null,
    error,
  };
}

export const updateTask = async (task) => {
  const accessToken = localStorage.getItem('accessToken')
  const config = {
    url: `${apiServerUrl}/api/tasks/${task.id}`,
    method: "PUT",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: task
  };

  const { data, error } = await callExternalApi({ config }, false);

  return {
    data: data || null,
    error,
  };
}

export const deleteTask = async (id) => {
  const accessToken = localStorage.getItem('accessToken')
  const config = {
    url: `${apiServerUrl}/api/tasks/${id}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }
  };

  const { data, error } = await callExternalApi({ config }, false);

  return {
    data: data || null,
    error,
  };
}