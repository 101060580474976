import React from 'react'
import PriceCard from '../components/PriceCard'
import StripeBuyButton from '../components/molecules/StripeBuyButton'
import { Chip } from '@mui/joy'
import { useUserQuery } from '../components/hooks/userUserQuery'
import { PageLoader } from '../components/PageLoader'
import { DateTime } from 'luxon'
import CancelSubscriptionButton from '../components/molecules/CancelSubscriptionButton'
import ReactivateSubscriptionButton from '../components/molecules/ReactivateSubscriptionButton'
import { personalPlanPrice, supportedFeatures } from '../config'
import FreeTrialCard from '../components/molecules/FreeTrialCard'

export const SubscriptionPage = () => {
  const { data: user, isLoading } = useUserQuery({ withSubscriptions: true })
  const activeSubscription = user?.subscriptions?.find(subscription => subscription.plan.active)
  const scheduledForCancellationAt = activeSubscription?.cancel_at
  const canceledAt = activeSubscription?.canceled_at

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <main className="pb-4">
      <div className="px-4 pt-6 dark:bg-gray-900">
        <div className="mb-4 col-span-full xl:mb-2">
          <nav className="flex mb-5" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 text-sm font-medium md:space-x-2">
              <li className="inline-flex items-center">
                <a href="#"
                   className="inline-flex items-center text-gray-700 hover:text-primary-600 dark:text-gray-300 dark:hover:text-white">
                  <svg className="w-5 h-5 mr-2.5" fill="currentColor" viewBox="0 0 20 20"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                  </svg>
                  Home
                </a>
              </li>
              <li>
                <div className="flex items-center">
                  <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"></path>
                  </svg>
                  <a href="#"
                     className="ml-1 text-gray-700 hover:text-primary-600 md:ml-2 dark:text-gray-300 dark:hover:text-white">Users</a>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"></path>
                  </svg>
                  <span className="ml-1 text-gray-400 md:ml-2 dark:text-gray-500"
                        aria-current="page">Settings</span>
                </div>
              </li>
            </ol>
          </nav>
        </div>

        <div className="col-span-full xl:col-auto">
          <div
            className="container px-4 mx-auto lg:px-0 dark:bg-gray-900 flex flex-col justify-items-center">
            <div className="text-center">
              <h1
                className="mb-3 text-3xl font-bold text-gray-900 sm:text-4xl sm:leading-none sm:tracking-tight dark:text-white">
                My Subscription
              </h1>
            </div>

            <section className="grid grid-cols-1 space-y-12 md:space-y-0 md:gap-x-6 md:gap-6 pt-9 justify-center">
              <FreeTrialCard hasActiveSubscription={!!activeSubscription} trialEndsAt={user.free_trial_ends_at} />

              <PriceCard
                name={<>
                  Personal plan&nbsp;
                  <Chip color={activeSubscription ? 'success' : 'neutral'}>
                    {activeSubscription ? 'active' : 'inactive'}
                  </Chip>&nbsp;
                  {scheduledForCancellationAt ? <Chip color='warning'>Scheduled for cancellation</Chip> : null}
                </>}
                description={<>
                  Ideal for solopreneurs, busy individuals,
                  and forward-thinkers who want to take control of both their personal
                  and professional schedules.
                </>}
                actionButton={!activeSubscription ? <StripeBuyButton/> : null}
                price={personalPlanPrice}
                frequency="month"
                features={supportedFeatures}
              >
                <div className='border-t-2 border-gray-200 border-dashed pt-4'>
                  {activeSubscription && !scheduledForCancellationAt ? <CancelSubscriptionButton activeSubscription={activeSubscription} /> : null}
                  {scheduledForCancellationAt ? (
                    <div>
                      <div className='text-gray-500 mb-2'>
                        You cancelled the subscription to this plan on {DateTime.fromMillis(canceledAt * 1000).toFormat('MM/dd/yyyy hh:mm a')}.
                        It will stay active until {DateTime.fromMillis(scheduledForCancellationAt * 1000).plus({ days: 30 }).toFormat('MM/dd/yyyy hh:mm a')}.
                        After that, you won't be charged but you won't be able to sync your calendars or events. After 30 days, your data will be deleted.
                      </div>
                      <div className='flex justify-center'>
                        <ReactivateSubscriptionButton activeSubscription={activeSubscription} />
                      </div>
                    </div>) 
                  : null}
                </div>
              </PriceCard>
            </section>
          </div>
        </div>
      </div>
    </main>
  )
}
