import { useSubscriptionMutation } from '../hooks/useSubscriptionMutation'
import Button from '../atoms/Button'
import { openSuccessToast } from '../../utils/toast.utils'

const ReactivateSubscriptionButton = ({ activeSubscription }) => {
  const { mutate: reactivateSubscriptionAtStripe, isLoading: isReactivatingSubscription } = useSubscriptionMutation({
    callback: async ({ data }) => {
      // we have to put this in a callback becuase this component will be unmounted when the mutation is done
      // and the toast will not open
      if (data?.status === 'success') {
        await openSuccessToast({ title: 'Subscription reactivated successfully!', description: 'You can now use all the features in your plan again.' })
      }
    }
  })

  const handleReactivateSubscription  = () => {
    reactivateSubscriptionAtStripe({ payload: { subscriptionId: activeSubscription.id }, action: 'REACTIVATE' })
  }

  return (
    <Button variant="solid" color="primary" onClick={handleReactivateSubscription} loading={isReactivatingSubscription}>
      Reactivate
    </Button>
  )
}

export default ReactivateSubscriptionButton

