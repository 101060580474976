import { useEffect, useState } from 'react'
import { useGlobalDispatch, useGlobalState } from '../../stores/global'
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff'
import Tooltip from '@mui/joy/Tooltip'
import classNames from 'classnames'

const Clock = () => {
  const [time, setTime] = useState(new Date().toLocaleTimeString())
  const globalDispatch = useGlobalDispatch()
  const { isClockVisible } = useGlobalState()

  const handleToggleClock = () => {
    globalDispatch({ type: 'TOGGLE_CLOCK' })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date().toLocaleTimeString())
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Tooltip title="Click to show or hide clock" arrow placement="bottom">
      <div
        className={classNames('clock hidden md:block relative cursor-pointer hover:bg-gray-100 rounded', {
          'text-md md:text-2xl px-2 m-0': isClockVisible,
        })}
        onClick={handleToggleClock}>
        {isClockVisible ? <span>{time}</span> : <HistoryToggleOffIcon/>}
      </div>
    </Tooltip>
  )
}

export default Clock