import React, { useEffect, useRef, useState } from 'react'
import logo from '../assets/calendartrends-icon.svg'
import { Link, useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { SignupButton } from '../auth0-components/buttons/signup-button'
import { LoginButton } from '../auth0-components/buttons/login-button'
import UserMenu from './UserMenu'
import Clock from './molecules/Clock'
import SyncAllCalendars from './organisms/SyncAllCalendars'
import ImageWithDefault from './atoms/ImageWithDefault'
import defaultProfilePicture from '../assets/default-pfp-19.jpg'
import classNames from 'classnames'

const Header = ({links}) => {  
  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false)
  const [isMainMenuVisible, setIsMainMenuVisible] = useState(false)
  const { isAuthenticated, user } = useAuth0()
  const userMenuRef = useRef(null)
  const mainMenuRef = useRef(null)
  const location = useLocation()
  const isHomePage = location.pathname === '/'

  const handleClickOutsideUserMenu = (event) => {
    if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
      setTimeout(() => {
        setIsUserMenuVisible(false)
      }, 0) // need the timeout otherwise the page refreshes
    }
  }

  const handleClickOutsideMainMenu = (event) => {
    if (mainMenuRef.current && !mainMenuRef.current.contains(event.target)) {
      setIsMainMenuVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideUserMenu, true)
    document.addEventListener('click', handleClickOutsideMainMenu, true)
    return () => {
      document.removeEventListener('click', handleClickOutsideUserMenu, true)
      document.removeEventListener('click', handleClickOutsideMainMenu, true)
    }
  }, [])

  const isPublicSite = links.map((link) => link.href).includes(window.location.pathname)

  return (
    <nav className={classNames(
      "fixed w-full z-50",
      {
        "bg-black text-white": isHomePage,
        "bg-white text-gray-900 border-gray-200 border-b": !isHomePage
      }
    )}>
      <div className="flex flex-wrap items-center justify-between mx-auto p-4">
        <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={logo} className="h-8" alt="CalendarTrends Logo" />
          <span className={`self-center text-2xl font-semibold whitespace-nowrap ${isHomePage ? 'text-white' : 'text-gray-900'}`}>CalendarTrends</span>
        </Link>
        <div className="relative flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
          {isAuthenticated ? (
            <>
              {!isPublicSite && (
                <>
                  <Clock/>
                  <SyncAllCalendars/>
                </>
              )}
              
              <button 
                type="button" 
                className="flex text-sm bg-gray-800 rounded-full md:me-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" 
                id="user-menu-button" 
                aria-expanded="false" 
                data-dropdown-toggle="user-dropdown" 
                data-dropdown-placement="bottom"
                onClick={() => setIsUserMenuVisible(!isUserMenuVisible)}
                ref={userMenuRef}
              >
                <span className="sr-only">Open user menu</span>

                <ImageWithDefault
                  imageUrl={user.picture}
                  defaultUrl={defaultProfilePicture}
                  alt="Profile"
                  className="h-8 w-8 rounded-full"
                />
              </button>
              {isUserMenuVisible && <UserMenu user={user} onDismiss={() => setIsUserMenuVisible(false)} />}
            </>
          ) : (
            <div className="flex justify-between items-center lg:order-2 space-x-4 text-sm font-medium min-w-fit">
              <SignupButton/>
              <LoginButton/>
            </div>
          )}

          {isPublicSite && (
            <button 
              data-collapse-toggle="navbar-user"
              type="button"
              className={classNames(
                'inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg md:hidden focus:outline-none focus:ring-2',
                {
                  'text-white hover:bg-gray-700 focus:ring-gray-600': isHomePage,
                  'text-gray-500 hover:bg-gray-100 focus:ring-gray-200': !isHomePage
                }
              )}
              aria-controls="navbar-user"
              aria-expanded="false"
              onClick={() => setIsMainMenuVisible(!isMainMenuVisible)}
              ref={mainMenuRef}
            >
              <span className="sr-only">Open main menu</span>
              <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
              </svg>
            </button>
          )}
        </div>
        <div 
          className={classNames('items-center justify-between w-full md:flex md:w-auto md:order-1 md:-ml-[170px]', {
            'hidden': !isMainMenuVisible
          })}
          id="navbar-user"
          ref={mainMenuRef}
        >
          <ul className={classNames(
            'flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0',
          )}>
            {links.map((link, index) => {
              const isHrefTheActiveRoute = window.location.pathname === link.href
              return (
                <li key={index} className={classNames(
                  'py-2 px-3 rounded',
                  {
                    'text-white md:bg-transparent md:text-primary md:p-0': isHrefTheActiveRoute,
                    'md:text-blue-500': isHrefTheActiveRoute && isHomePage,
                    'md:text-blue-700': isHrefTheActiveRoute && !isHomePage,
                    'text-gray-300 hover:bg-gray-700 md:hover:text-blue-500': !isHrefTheActiveRoute && isHomePage,
                    'text-gray-900 hover:bg-gray-100 md:hover:text-blue-700': !isHrefTheActiveRoute && !isHomePage,
                    'md:hover:bg-transparent md:p-0': !isHrefTheActiveRoute
                  }
                )}>
                  <Link
                    to={link.href}
                    className="block"
                  >
                    {link.label}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Header