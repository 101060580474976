import React from 'react'
import { useStripeConfigQuery } from '../hooks/useStripeConfigQuery'

const StripeBuyButton = () => {
  const { data: stripeConfigData, isLoading: isLoadingStripeConfig } = useStripeConfigQuery()

  if (isLoadingStripeConfig) return <div>Loading...</div>

  return (
    <stripe-buy-button
      buy-button-id={process.env.REACT_APP_STRIPE_BUTTON_ID}
      publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
      customer-session-client-secret={stripeConfigData.customerSessionClientSecret}
    >
    </stripe-buy-button>
  )
}

export default StripeBuyButton