import { callExternalApi } from './external-api.service';

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL

export const getVersion = async () => {
  const config = {
    url: `${apiServerUrl}/api/version`,
    method: 'GET',
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    console.error('Error fetching version:', error);
    return null;
  }

  return data;
};
