import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createTask, deleteTask, updateTask } from '../../services/task.service'

export const useTaskMutation = () => {
  const queryClient = useQueryClient()
  const queryKey = ['tasks']

  const callMutationMethod = ({ payload, action }) => {
    if (action.toUpperCase() === 'DELETE'){
      return deleteTask(payload.id)
    } else if (action.toUpperCase() === 'CREATE') {
      return createTask(payload)
    } else if (action.toUpperCase() === 'UPDATE') {
      return updateTask(payload)
    } else if (action.toUpperCase() === 'REFRESH') {
      return queryClient.invalidateQueries({ queryKey })
    }

    throw new Error('Method not supported')
  }

  const { isLoading, isSuccess, isError, data, error, mutateAsync: mutate } = useMutation({
    queryKey,
    mutationFn: callMutationMethod,
    onMutate: () => {
      queryClient.cancelQueries(queryKey)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey })
    }
  })

  return {
    mutate,
    isLoading,
    isError,
    isSuccess,
    data: data?.data,
    meta: data?.meta,
    error,
  }
}