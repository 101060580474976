const apiServerUrl = process.env.REACT_APP_API_SERVER_URL

const DEFAULT_OPTIONS = { rewriteBaseUrl: true }

export function generateApiParams (meta, options) {
  const { rewriteBaseUrl } = options || DEFAULT_OPTIONS
  const accessToken = localStorage.getItem('accessToken')
  const ignoredFilters = ['personId', 'calendarId']
  const filtersForUrl = meta.filters
    .filter((f) => !ignoredFilters.includes(f.field))
    .map((f) => `${f.field}__${f.operator}=${f.value}`)
    .join('&')

  let baseUrl = `${apiServerUrl}/api`
  if (rewriteBaseUrl) {
    const personIdFilter = meta.filters.find((f) => f.field === 'personId')
    const calendarIdFilter = meta.filters.find((f) => f.field === 'calendarId')
    const isPersonPage = personIdFilter && !calendarIdFilter
 
    if (isPersonPage) {
      baseUrl = `${apiServerUrl}/api/people/${personIdFilter.value}`
    } else if (calendarIdFilter) {
      baseUrl = `${apiServerUrl}/api/calendars/${calendarIdFilter.value}`
    }
  }
  return { accessToken, filtersForUrl, baseUrl }
}