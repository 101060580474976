import { callExternalApi } from './external-api.service'

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
const DEFAULT_META = { page: 1, perPage: 10, orderBy: 'id', orderDirection: 'asc', filters: [] }

export const fetchPeople = async ({ meta = DEFAULT_META } = { meta: DEFAULT_META}) => {
  const accessToken = localStorage.getItem("accessToken");
  const filtersForUrl = meta.filters.map((f) => `${f.field}__${f.operator}=${f.value}`).join('&')

  const config = {
    url: `${apiServerUrl}/api/people?page=${meta.page}&per_page=${meta.perPage}&order_by=${meta.orderBy}&order_direction=${meta.orderDirection}&${filtersForUrl}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config }, false);

  return {
    data: data || null,
    error,
  };
};
