import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'

const LoginPage = () => {
  const { loginWithRedirect } = useAuth0()

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: '/sync_me',
      },
      authorizationParams: {
        prompt: 'login',
      },
    })
  }

  useEffect(() => {
    handleLogin()
  }, [])

  return null
}

export default LoginPage