import { useState } from 'react'
import { DateTime } from 'luxon'
import TableSortAndSelection from '../atoms/TableSortAndSelection'
import { usePeopleQuery } from '../hooks/usePeopleQuery'
import { Loader } from '../Loader'
import { usePeopleState, usePeopleDispatch } from '../../stores/people'
import { Link, useNavigate } from 'react-router-dom'

window.DateTime = DateTime

const PersonTable = ({ className = '' }) => {
  // useNavigate is used for imperatively navigating to another route
  const navigate = useNavigate()

  const with_events_count_filter = { field: 'with_events_count', operator: 'eq', value: true }
  const { page, filters } = usePeopleState()
  const peopleDispatch = usePeopleDispatch()
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(localStorage.getItem('rowsPerPage') || 10))
  const [orderBy, setOrderBy] = useState('id')
  const [orderDirection, setOrderDirection] = useState('asc')
  const { isLoading, isError, data: peopleResp } = usePeopleQuery({
    meta: {
      page,
      perPage: rowsPerPage,
      orderBy,
      orderDirection,
      filters: filters.concat(with_events_count_filter)
    }
  })
  const handleSetPage = (newPage) => {
    peopleDispatch({ type: 'SET_PAGE', payload: newPage })
  }

  if (isLoading) {
    return <Loader/>
  }

  if (isError) {
    return <div className="p-4 text-center">Something went wrong. Please refresh the page and try again.</div>
  }

  const { data: people, meta } = peopleResp || {}

  if (people.length === 0) {
    return (
      <div className="p-4 text-center">
        No people found. <Link className="underline" to="/my/calendars">Upload a calendar to get started</Link>.
      </div>
    )
  }

  const headCells = [
    {
      id: 'email',
      numeric: false,
      label: 'Email',
      style: { width: 200 },
      format: (value) => value || <i className="text-gray-400">Unknown</i>
    },
    {
      id: 'full_name',
      numeric: false,
      label: 'Full name',
      style: { width: 200 },
      format: (value) => value || <i className="text-gray-400">Unknown</i>
    },
    {
      id: 'events_count',
      numeric: false,
      label: 'Events',
      style: { width: 80 },
    },
    {
      id: 'created_at',
      numeric: false,
      label: 'Created At',
      styles: { width: 200 },
      format: (value) => DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED)
    },
    {
      id: 'updated_at',
      numeric: false,
      label: 'Updated At',
      styles: { width: 200 },
      format: (value) => DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED)
    },
  ]

  const handleRequestSort = ({ property, direction }) => {
    setOrderBy(property)
    setOrderDirection(direction)
  }

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(value)
    localStorage.setItem('rowsPerPage', value)
  }

  const handleRowClick = (row) => {
    // navigate to /my/people/:id/events
    navigate(`/my/people/${row.id}/events`)
  }

  return (
    <div className={`PersonTable ${className}`}>
      <TableSortAndSelection
        headCells={headCells}
        rows={people}
        onChangePage={handleSetPage}
        page={page}
        totalPages={meta?.total_pages}
        totalRows={meta?.total_count}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        onRequestSort={handleRequestSort}
        orderDirection={orderDirection}
        orderBy={orderBy}
        disableSelection
        onRowClick={handleRowClick}
      />
    </div>
  )
}

export default PersonTable