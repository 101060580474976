import { useCallback, useState } from 'react';
import debounce from 'lodash/debounce';
import Breadcrumbs from '../atoms/Breadcrumbs';
import EventTable from './EventTable';
import { useEventsDispatch, useEventsState } from '../../stores/events';
import { useLocation, useParams } from 'react-router-dom';
import EventsStats from './EventsStats';
import EventScheduler from './EventScheduler';
import EventViewToggle from './EventViewToggle';
import Event from '../molecules/Event';
import moment from 'moment';
import CalendarStats from './CalendarStats';
import SearchAndViewSection from '../molecules/SearchAndViewSection';
import CalendarStatsWithEventFilterActions from './CalendarStatsWithEventFilterActions';

const EventsIndex = () => {
  const { calendarId } = useParams();
  const { filters } = useEventsState();
  const location = useLocation();
  const eventsDispatch = useEventsDispatch();
  const { selectedEvent, selectedView } = useEventsState();
  const eventToggleOptions = [
    { label: 'List View', value: 'list' },
    { label: 'Calendar View', value: 'calendar' },
  ];
  const [range, setRange] = useState(null);

  const handleEventToggle = (option) => {
    eventsDispatch({
      type: 'SET_VIEW',
      payload: option.value,
    });
  };

  const createBreadcrumbs = (location) => {
    const baseCrumb = { label: 'Home', url: '/my/dashboard' };
    const isPersonPage = location.pathname.startsWith('/my/people/');
    if (isPersonPage) {
      return [
        baseCrumb,
        { label: 'People', url: `/my/people` },
        { label: 'Events' },
      ];
    }
    return [baseCrumb, { label: 'Events' }];
  };

  const handleSearchChange = useCallback(
    debounce((e) => {
      eventsDispatch({
        type: 'ADD_FILTER',
        payload: { field: 'summary', operator: 'like', value: e.target.value },
      });
    }, 250),
    []
  );

  const handleGroupByChangeClick = (e) => {
    eventsDispatch({
      type: 'ADD_FILTER',
      payload: { field: 'summary', operator: 'group', value: e.target.checked },
    });
  };

  const handleCloseEventDetails = () => {
    eventsDispatch({ type: 'SET_SELECTED_EVENT', payload: null });
  };

  const handleRangeChange = (range) => {
    let start = range.start || range[0];
    let end = range.end || range[range.length - 1];
    end = moment(end).endOf('day').toDate(); // always make `end` the end of the day

    let startsAt = moment
      .utc(start)
      .startOf('day')
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    let endsAt = moment
      .utc(end)
      .endOf('day')
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    setRange({ startsAt, endsAt });
  };

  const isGroupBySummaryChecked = filters.find(
    (filter) => filter.field === 'summary' && filter.operator === 'group'
  )?.value;

  return (
    <div className="EventsIndex">
      {selectedEvent && (
        <Event event={selectedEvent} onClose={handleCloseEventDetails} />
      )}

      <div className="page-header p-4">
        <Breadcrumbs breadcrumbs={createBreadcrumbs(location)} />
        <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
          Events
        </h1>
      </div>

      {calendarId && (
        <CalendarStatsWithEventFilterActions calendarId={calendarId} className="mx-4 mb-2" />
      )}
      <EventsStats className="p-4" />

      <SearchAndViewSection
        className="mx-4 mb-2"
        handleSearchChange={handleSearchChange}
        isGroupBySummaryChecked={isGroupBySummaryChecked}
        handleGroupByChangeClick={handleGroupByChangeClick}
        handleEventToggle={handleEventToggle}
        eventToggleOptions={eventToggleOptions}
        selectedView={selectedView}
      />

      {selectedView === 'calendar' ? (
        <EventScheduler
          className="p-4"
          calendarProps={{ onRangeChange: handleRangeChange }}
          customRange={range}
        />
      ) : (
        <EventTable className="p-4" />
      )}
    </div>
  );
};

export default EventsIndex;
