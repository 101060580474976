import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import classNames from 'classnames'

export const SignupButton = ({children, className, disabled, label, type = 'link' }) => {
  const { loginWithRedirect } = useAuth0();
  const buttonClassNames = 'inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900'

  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/sync_me",
      },
      authorizationParams: {
        prompt: "login",
        screen_hint: "signup",
      },
    });
  };

  return (
    <button className={classNames(className, { [buttonClassNames]: type === 'button'})} onClick={handleSignUp} disabled={disabled}>
      {(label || children) ?? 'Sign Up'}
    </button>
  );
};
