import EventScheduler from './EventScheduler'

import React from 'react'
import { useEventsDispatch, useEventsState } from '../../stores/events'
import CalendarSelect from './CalendarSelect'
import Event from '../molecules/Event'
import { Card, CardContent } from '@mui/joy'

export const MyEvents = () => {
  const eventsDispatch = useEventsDispatch()
  const { selectedEvent } = useEventsState()

  const handleCloseEventPanel = () => {
    eventsDispatch({ type: 'SET_SELECTED_EVENT', payload: null })
  }

  return (
    <Card variant="outlined" className="EventsPage bg-white flex flex-col h-full w-full ml-0 md:ml-2">
      <CardContent className="overflow-hidden">
        {selectedEvent && <Event event={selectedEvent} onClose={handleCloseEventPanel}/>}

        <div className="flex justify-end pb-4">
          <CalendarSelect/>
        </div>
        <EventScheduler
          className="flex-1"
          calendarProps={{
            views: ['week', 'day', 'agenda'],
            defaultView: 'day'
          }}
        />
      </CardContent>
    </Card>
  )
}