import React from 'react'
import { Link } from 'react-router-dom'
import Version from './molecules/Version'

export const PageFooter = () => {
  const year = new Date().getFullYear()
  return (
    <footer className="md:flex md:items-center md:justify-between px-4 py-3 border-t">
      <p className="text-sm text-center text-gray-500 mb-4 md:mb-0">
        &copy; {year} <Link to="/" className="hover:underline">CalendarTrends.com</Link>. All rights reserved. <Version />
      </p>
      <ul className="flex flex-wrap items-center justify-center">
        <li><Link to="/terms"
                  className="mr-4 text-sm font-normal text-gray-500 hover:underline md:mr-6 dark:text-gray-400">Terms</Link>
        </li>
        <li><Link to="/privacy"
                  className="mr-4 text-sm font-normal text-gray-500 hover:underline md:mr-6 dark:text-gray-400">Privacy</Link>
        </li>
        <li><Link to="/contact"
                  className="text-sm font-normal text-gray-500 hover:underline dark:text-gray-400">Contact</Link></li>
      </ul>
    </footer>
  )
}
