import CachedIcon from '@mui/icons-material/Cached'
import Button from '@mui/joy/Button'
import { useCalendarMutation } from '../hooks/useCalendarMutation'
import classNames from 'classnames'
import Tooltip from '@mui/joy/Tooltip'
import { openErrorToast, openSuccessToast } from '../../utils/toast.utils'

const SyncAllCalendars = () => {
  const { mutate: mutateCalendar, isLoading: isMutationLoading, isError: isMutationError } = useCalendarMutation()

  const handleSyncCalendar = async () => {
    const resp = await mutateCalendar({ action: 'SYNC_ALL' })

    if (resp.data && !resp.error) {
      openSuccessToast({ title: 'All calendars refreshed', description: resp.data.message })
    } else {
      openErrorToast({ title: 'Error', description: resp?.data?.message || 'Something went wrong' })
    }
  }

  return (
    <>
      <Tooltip title="Refresh all calendars" arrow placement="bottom">
        <Button
          onClick={handleSyncCalendar}
          className={classNames('!ml-0 !md:ml-1', { 'opacity-50': isMutationLoading })}
          disabled={isMutationLoading}
          variant="contained"
        >
          <CachedIcon/>
        </Button>
      </Tooltip>
    </>
  )
}

export default SyncAllCalendars