import { fetchEventsStats } from '../../services/event.service'
import { useQuery } from '@tanstack/react-query'
import { useEventsState } from '../../stores/events.js'

const defaultProps = {
  meta: { page: 1, perPage: 10, orderBy: 'id', orderDirection: 'asc', filters: [] },
}

export const useEventsStatsQuery = ({ meta } = defaultProps) => {
  const { filters } = useEventsState()
  const metaWithFilters = { ...meta, filters }
  const flattenFilters = filters.map((f) => `${f.field}__${f.operator}=${f.value}`)
  const queryKey = ['eventsStats', meta, flattenFilters.join('&')]

  const { isLoading, isError, data, error } = useQuery({
    queryKey,
    queryFn: () => fetchEventsStats({ meta: metaWithFilters }),
  })

  return {
    isLoading,
    isError,
    data: data?.data,
    meta: data?.meta,
    error,
  }
}