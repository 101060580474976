import { Link, useLocation } from 'react-router-dom'
import Button from '@mui/joy/Button'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import * as React from 'react'
import { useEffect } from 'react'
import classNames from 'classnames'
import Tooltip from '@mui/joy/Tooltip'
import { useGlobalDispatch, useGlobalState } from '../stores/global'
import Version from './molecules/Version'

export const Aside = () => {
  const globalDispatch = useGlobalDispatch()
  const { isSidebarOpen } = useGlobalState()
  const location = useLocation()

  const handleToggleSidebarVisibility = () => {
    globalDispatch({ type: 'TOGGLE_SIDEBAR' })
  }

  const registerEscKeyTogglesSidebar = (e) => {
    if (e.key === '[' && e.target.tagName !== 'INPUT' && e.target.tagName !== 'TEXTAREA') {
      handleToggleSidebarVisibility()
    }
  }

  const hideAsideOnMobile = () => {
    if (window.innerWidth <= 768) {
      globalDispatch({ type: 'CLOSE_SIDEBAR' })
    }
  }

  const handleLinkClick = () => {
    hideAsideOnMobile()
  }

  useEffect(() => {
    hideAsideOnMobile()
    window.addEventListener('keydown', registerEscKeyTogglesSidebar)
    window.addEventListener('resize', hideAsideOnMobile)

    return () => {
      window.removeEventListener('keydown', registerEscKeyTogglesSidebar)
      window.removeEventListener('resize', hideAsideOnMobile)
    }
  }, [])

  const mainLinks = [
    {
      label: 'Dashboard',
      href: 'dashboard',
      icon: ({ className = '' }) => (
        <svg
          className={`w-6 h-6 transition duration-75 ${className}`}
          fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
          <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
        </svg>
      )
    },
    {
      label: 'Focus',
      href: 'focus',
      icon: ({ className = '' }) => (
        <svg
          className={`flex-shrink-0 w-6 h-6 transition duration-75 ${className}`}
          aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
          fill="none" viewBox="0 0 22 21">
          <path stroke="currentColor" strokeLinecap="round" strokeWidth="2"
                d="M7.24 7.194a24.16 24.16 0 0 1 3.72-3.062m0 0c3.443-2.277 6.732-2.969 8.24-1.46 2.054 2.053.03 7.407-4.522 11.959-4.552 4.551-9.906 6.576-11.96 4.522C1.223 17.658 1.89 14.412 4.121 11m6.838-6.868c-3.443-2.277-6.732-2.969-8.24-1.46-2.054 2.053-.03 7.407 4.522 11.959m3.718-10.499a24.16 24.16 0 0 1 3.719 3.062M17.798 11c2.23 3.412 2.898 6.658 1.402 8.153-1.502 1.503-4.771.822-8.2-1.433m1-6.808a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"></path>
        </svg>
      )
    },
    {
      label: 'Calendars',
      href: 'calendars',
      icon: ({ className = '' }) => (
        <svg
          className={`flex-shrink-0 w-6 h-6 transition duration-75 ${className}`}
          fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z"></path>
        </svg>
      )
    },
    {
      label: 'People',
      href: 'people',
      icon: ({ className }) => (
        <svg
          className={`flex-shrink-0 w-6 h-6 transition duration-75 ${className}`}
          fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
        </svg>
      )
    },
    {
      label: 'Reports',
      href: 'reports',
      icon: ({ className = '' }) => (
        <svg
          className={`flex-shrink-0 w-6 h-6 transition duration-75 ${className}`}
          fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd"
                d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm2 10a1 1 0 10-2 0v3a1 1 0 102 0v-3zm2-3a1 1 0 011 1v5a1 1 0 11-2 0v-5a1 1 0 011-1zm4-1a1 1 0 10-2 0v7a1 1 0 102 0V8z"
                clipRule="evenodd"></path>
        </svg>
      )
    },
    {
      label: 'Integrations',
      href: null,
      icon: ({ className = '' }) => (
        <svg
          className={`flex-shrink-0 w-6 h-6 transition duration-75 ${className}`}
          fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                clipRule="evenodd"></path>
        </svg>
      )
    },
    {
      label: 'AI',
      href: null,
      icon: ({ className = '' }) => (
        <svg
          className={`flex-shrink-0 w-6 h-6 transition duration-75 ${className}`}
          fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path clipRule="evenodd" fillRule="evenodd"
                d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"></path>
        </svg>
      )
    }
  ]

  const secondaryLinks = [
    {
      label: 'Support',
      href: '/contact',
      external: true,
      icon: ({ className = '' }) => (
        <svg
          className={`flex-shrink-0 w-6 h-6 transition duration-75 ${className}`}
          fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-2 0c0 .993-.241 1.929-.668 2.754l-1.524-1.525a3.997 3.997 0 00.078-2.183l1.562-1.562C15.802 8.249 16 9.1 16 10zm-5.165 3.913l1.58 1.58A5.98 5.98 0 0110 16a5.976 5.976 0 01-2.516-.552l1.562-1.562a4.006 4.006 0 001.789.027zm-4.677-2.796a4.002 4.002 0 01-.041-2.08l-.08.08-1.53-1.533A5.98 5.98 0 004 10c0 .954.223 1.856.619 2.657l1.54-1.54zm1.088-6.45A5.974 5.974 0 0110 4c.954 0 1.856.223 2.657.619l-1.54 1.54a4.002 4.002 0 00-2.346.033L7.246 4.668zM12 10a2 2 0 11-4 0 2 2 0 014 0z"
                clipRule="evenodd"></path>
        </svg>
      )
    }
  ]

  const renderLink = ({ label, href, icon, external }) => {
    const isActive = location.pathname.includes(href);
    return (
      <li key={label} className={href ? '' : 'opacity-50'}>
        {href !== null ? (
          <Link to={href}
                target={external ? '_blank' : '_self'}
                onClick={handleLinkClick}
                className={`flex items-center p-2 text-base rounded-lg hover:bg-gray-100 group dark:hover:bg-gray-700 ${isActive ? 'bg-gray-100 dark:bg-gray-700 text-primary dark:text-blue-500' : 'text-gray-900 dark:text-gray-200'}`}>
            {icon({ className: isActive ? 'text-primary-700 dark:text-primary-500' : 'text-gray-900 dark:text-gray-200' })}
            <span className="ml-3">{label}</span>
          </Link>) : (
          <button type="button"
                  className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-700"
                  aria-controls="dropdown-auth" data-collapse-toggle="dropdown-auth"
                  onClick={handleLinkClick}>
            {icon({ className: isActive ? 'text-primary-700 dark:text-primary-500' : 'text-gray-900 dark:text-gray-200' })}
            <span className="flex-1 ml-3 text-left whitespace-nowrap">{label}</span>
          </button>
        )}
      </li>
    )
  }

  const bottomLinks = (
    <>
      <a href="#"
         className="inline-flex justify-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"></path>
        </svg>
      </a>
      <a href="https://flowbite-admin-dashboard.vercel.app/settings/" data-tooltip-target="tooltip-settings"
         className="inline-flex justify-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd"
                d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                clipRule="evenodd"></path>
        </svg>
      </a>
      <div id="tooltip-settings" role="tooltip"
           className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
           style={{
             'position': 'absolute',
             inset: 'auto auto 0px 0px',
             margin: 0,
             transform: 'translate3d(71px, -64px, 0px)'
           }} data-popper-placement="top">
        Settings page
        <div className="tooltip-arrow" data-popper-arrow=""
             style={{ position: 'absolute', left: 0, transform: 'translate3d(54.5px, 0px, 0px)' }}></div>
      </div>
    </>
  )

  return (
    <aside
      id="sidebar"
      className={classNames('Aside fixed top-0 left-0 z-20 flex flex-col flex-shrink-0 h-full pt-16 font-normal lg:flex transition-width duration-75', {
        'w-64': isSidebarOpen,
        'w-4': !isSidebarOpen
      })}
      aria-label="Sidebar"
    >
      <Tooltip title={`Press [ to open or close this sidebar`} arrow placement="right">
        <Button
          variant="soft"
          size="sm"
          onClick={handleToggleSidebarVisibility}
          className="!absolute top-20 -right-4 !-mt-1 !rounded-3xl z-10 !p-1 scale-75 transition ease-in-out hover:scale-100"
          title="Close"
        >
          {isSidebarOpen ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
        </Button>
      </Tooltip>
      <div
        className="relative flex flex-col flex-1 min-h-0 pt-0 bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="flex flex-col flex-1 pt-5 pb-4 overflow-y-auto">
          <div
            className="flex-1 px-3 space-y-1 bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
            <ul className="pb-2 space-y-2">
              {mainLinks.map(renderLink)}
            </ul>
            <ul className="pt-2 space-y-2">
              {secondaryLinks.map(renderLink)}
            </ul>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 justify-center hidden w-full p-4 space-x-4 bg-white lg:flex dark:bg-gray-800">
          {false && bottomLinks}

          <span className="text-xs text-gray-400"><Version /></span>
        </div>
      </div>
    </aside>
  )
}