import { PageLayout } from '../layouts/page-layout'
import { Link } from 'react-router-dom'

export default function PrivacyPage () {
  return (
    <PageLayout>
      <section className="Terms bg-white dark:bg-gray-900 py-8">
        <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
          <h2
            className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
            Privacy Policy
          </h2>
          <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
            All the legal stuff you need to know about how we handle your privacy in our website and services.
          </p>
          <div className="space-y-8">
            <div className="prose w-full break-words dark:prose-invert light">
              <p><strong>Personal Identification Information</strong></p>
              <p>Users may be asked for, as appropriate, a valid email address to sign up to the Service. We will
                collect
                personal identification information from Users only if they voluntarily submit such information to us.
                This
                is not required to make use of the Service.
                Users can always refuse to supply personally identification information, except that it may prevent them
                from engaging in certain Service-related activities.</p>
              <p><strong>Non-personal Identification Information</strong></p>
              <p>We may collect non-personal identification information about Users whenever
                they interact with our Service. Non-personal identification information may include the browser name,
                the type of computer, and technical information about Users' means of connection to our Service, such as
                the operating system and the Internet service providers utilized and other similar information.</p>
              <p><strong>3rd party services</strong></p>
              <p>We may use non-personal identification information about Users to communicate with 3rd party services,
                like AI providers (ie. OpenAI) with the purpose of providing better services to our Users. We'll never
                share personal identifiable information with these providers. In the case of AI providers, we prioritize
                using services that do not use data to train their own models.</p>
              <p><strong>Web Browser Cookies</strong></p>
              <p>Our Service may use session "cookies" to enhance User experience. We'll never use cookies for ads
                purposes or share them with third parties.
              </p></div>
          </div>
        </div>
      </section>
    </PageLayout>
  )
}