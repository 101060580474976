import { useQuery } from '@tanstack/react-query'
import { getVersion } from '../../services/version.service.js'

export const useVersionQuery = () => {
  const queryKey = ['version']

  const { isLoading, isError, data, error } = useQuery({
    queryKey,
    queryFn: getVersion,
  })

  return {
    queryKey,
    isLoading,
    isError,
    data,
    error,
  }
}
