import React, { createContext, useContext, useReducer } from 'react';
import _ from 'lodash';

const initialState = {
  filters: [],
  page: 1,
};

function reducer(state, action) {
  switch (action.type) {
    case 'ADD_FILTER':
      const uniqueFilters = [...state.filters.filter(filter => filter.field !== action.payload.field), action.payload];
      return { ...state, filters: uniqueFilters, page: 1 };
    case 'REMOVE_FILTER':
      return { ...state, filters: state.filters.filter(filter => filter.field !== action.payload.field), page: 1 };
    case 'SET_PAGE':
      return { ...state, page: action.payload };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

const TasksStateContext = createContext();
const TasksDispatchContext = createContext();

function TasksProvider({ children, extendInitialState }) {
  const [state, dispatch] = useReducer(reducer, _.merge({}, initialState, extendInitialState));

  return (
    <TasksStateContext.Provider value={state}>
      <TasksDispatchContext.Provider value={dispatch}>
        {children}
      </TasksDispatchContext.Provider>
    </TasksStateContext.Provider>
  );
}

function useTasksState() {
  const context = useContext(TasksStateContext);
  if (context === undefined) {
    throw new Error('useTasksState must be used within a TasksProvider');
  }
  return context;
}

function useTasksDispatch() {
  const context = useContext(TasksDispatchContext);
  if (context === undefined) {
    throw new Error('useTasksDispatch must be used within a TasksProvider');
  }
  return context;
}

export { TasksProvider, useTasksState, useTasksDispatch };
