import { useState } from 'react'
import { DateTime } from 'luxon'
import { useCalendarQuery } from '../hooks/useCalendarQuery'
import { useCalendarMutation } from '../hooks/useCalendarMutation'
import { useCalendarsState, useCalendarsDispatch } from '../../stores/calendars'
import { Button } from '@mui/joy'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import Tooltip from '@mui/joy/Tooltip'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CalendarBlock from '../molecules/CalendarBlock'
import { openErrorToast, openSuccessToast } from '../../utils/toast.utils'

window.DateTime = DateTime

const CalendarTable = ({ className = '', onOpenLinkCalendarModal }) => {
  const { mutate: mutateCalendar, isLoading: isMutationLoading, isError: isMutationError } = useCalendarMutation()
  const { page, filters } = useCalendarsState()
  const calendarDispatch = useCalendarsDispatch()
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(localStorage.getItem('rowsPerPage') || 10))
  const [orderBy, setOrderBy] = useState('id')
  const [orderDirection, setOrderDirection] = useState('asc')
  const { isLoading, isError, data: calendarsResp, error, queryKey: calendarQueryKey } = useCalendarQuery({
    meta: { page, perPage: rowsPerPage, orderBy, orderDirection, filters },
  })
  const navigate = useNavigate()

  const handleSetPage = (newPage) => {
    calendarDispatch({ type: 'SET_PAGE', payload: newPage })
  }

  const handleSyncCalendar = async (row, e) => {
    e.stopPropagation()
    const resp = await mutateCalendar({ id: row.id, action: 'SYNC' })

    if (resp.data && !resp.error) {
      openSuccessToast({ title: 'Info', description: resp.data.message })
    } else {
      openErrorToast({ title: 'Error', description: resp?.data?.message || 'Something went wrong' })
    }
  }

  if (isError || isMutationError) {
    return <div className="p-4 text-center">Something went wrong. Please refresh the page and try again.</div>
  }

  const { data: calendars = [], meta } = calendarsResp || {}

  const headCells = [
    {
      id: 'name',
      numeric: false,
      label: 'Name',
      style: { width: 200 },
      format: (value) => value || <i className="text-gray-400">Unknown</i>
    },
    {
      id: 'events_count',
      numeric: false,
      label: 'Events',
      style: { width: 80 },
    },
    {
      id: 'description',
      numeric: false,
      label: 'Description',
      style: { width: 400 },
      format: (value) => value || <i className="text-gray-400">Unknown</i>
    },
    {
      id: 'time_zone',
      numeric: false,
      label: 'Timezone',
      style: { width: 200 },
      format: (value) => value || <i className="text-gray-400">Unknown</i>
    },
    {
      id: 'last_synced_at',
      numeric: false,
      label: 'Refreshed At',
      style: { width: 200 },
      format: (value) => value ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED) : 'Refreshing...'
    },
    {
      id: 'created_at',
      numeric: false,
      label: 'Created At',
      style: { width: 200 },
      format: (value) => DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED)
    },
  ]

  const customCells = [
    {
      id: 'delete',
      label: '',
      title: '',
      style: { width: 200 },
      format: (row) => (
        <div key={row.id}>
          {row.scheduled_for_deletion_at && (
            <Button variant="primary" color="danger" className="opacity-50" disabled>
              Deleting...
            </Button>
          )}
          {!row.scheduled_for_deletion_at && !row.last_synced_at && (
            <Button variant="primary" color="danger" className="opacity-50" disabled>
              Refreshing...
            </Button>
          )}
          {!row.scheduled_for_deletion_at && (
            <>
              {row.last_synced_at && (
                <>
                  {row.calendar_url ? (
                    <Button
                      onClick={(e) => handleSyncCalendar(row, e)}
                      variant="primary"
                    >
                      {row.last_sync_error_message && (
                        <Tooltip title={row.last_sync_error_message} arrow placement="bottom">
                          <ErrorOutlineIcon className="scale-[60%] absolute -right-2 top-[6px]"/>
                        </Tooltip>
                      )}
                      Refresh
                    </Button>
                  ) : (
                    <Tooltip
                      title="Refreshing disabled because the source of this calendar is an uploaded file. It does not have an URL to refresh from."
                      arrow placement="left">
                      <Button
                        disabled
                        variant="primary"
                      >
                        Refresh
                      </Button>
                    </Tooltip>
                  )}

                  <Button variant="primary" color="danger" onClick={(e) => handleDeleteRow(row, e)}>
                    Delete
                  </Button>
                </>
              )}
            </>
          )}
        </div>
      )
    }
  ]

  const handleRequestSort = ({ property, direction }) => {
    setOrderBy(property)
    setOrderDirection(direction)
  }

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(value)
    localStorage.setItem('rowsPerPage', value)
  }

  const handleRowClick = (row) => {
    if (row.scheduled_for_deletion_at) return
    navigate(`/my/calendars/${row.id}/events`)
  }

  const handleDeleteRow = async (row, e) => {
    e.stopPropagation()

    // display a confirmation dialog
    if (window.confirm('Are you sure you want to delete this calendar and all associated events?')) {
      try {
        const resp = await mutateCalendar({ id: row.id, action: 'DELETE' })

        if (resp.data && !resp.error) {
          openSuccessToast({ title: 'Calendar deleted', description: resp.data.message })
        } else {
          openErrorToast({ title: 'Error', description: resp?.data?.message || 'Something went wrong' })
        }
      } catch (error) {
        openErrorToast({ title: 'Error', description: 'Something went wrong' })
      }
    }
  }

  const preProcessData = (data) => {
    return data.map((row) => {
      return {
        ...row,
        className: classNames({
          'opacity-50': row.scheduled_for_deletion_at !== null
        })
      }
    })
  }
  return (
    <div className="w-full h-full px-4 mb-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
        <div className="px-1 flex justify-center relative min-h-60" style={{ zIndex: 1 }}>
          <button
            className="w-full relative flex flex-col mt-6 bg-white bg-clip-border rounded-md outline-dashed justify-center items-center text-center font-sans text-xl antialiased font-semibold leading-snug tracking-normal bg-blue-50 text-blue-500 outline-blue-200 hover:bg-blue-70 hover:text-primary hover:outline-blue-400 transition-all"
            onClick={onOpenLinkCalendarModal}
          >
            Add a new calendar
          </button>
        </div>
        {calendars.length > 0 && preProcessData(calendars).map((calendar) => {
          return (
            <CalendarBlock
              key={calendar.id}
              calendar={calendar}
              onClick={(_e) => handleRowClick(calendar)}
              customCells={customCells}
              callbackfn={(cell) => {
                return (
                  cell.format(calendar)
                )
              }}/>
          )
        })}
      </div>
    </div>
  )
}

export default CalendarTable