import React, { createContext, useContext, useReducer } from 'react'
import _ from 'lodash'

const initialState = {
  isSidebarOpen: true,
  isClockVisible: true,
}

function reducer (state, action) {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.user }
    case 'REMOVE_USER':
      return { ...state, user: null }
    case 'TOGGLE_SIDEBAR':
      return { ...state, isSidebarOpen: !state.isSidebarOpen }
    case 'CLOSE_SIDEBAR':
      return { ...state, isSidebarOpen: false }
    case 'OPEN_SIDEBAR':
      return { ...state, isSidebarOpen: true }
    case 'TOGGLE_CLOCK':
      return { ...state, isClockVisible: !state.isClockVisible }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const GlobalStateContext = createContext()
const GlobalDispatchContext = createContext()

function GlobalProvider ({ children, extendInitialState }) {
  const [state, dispatch] = useReducer(reducer, _.merge({}, initialState, extendInitialState))

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

function useGlobalState () {
  const context = useContext(GlobalStateContext)
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalProvider')
  }
  return context
}

function useGlobalDispatch () {
  const context = useContext(GlobalDispatchContext)
  if (context === undefined) {
    throw new Error('useGlobalDispatch must be used within a GlobalProvider')
  }
  return context
}

export { GlobalProvider, useGlobalState, useGlobalDispatch }
