import { useQuery } from '@tanstack/react-query'
import { fetchTasks } from '../../services/task.service.js'
import { useTasksState } from '../../stores/tasks.js'

const defaultProps = {
  meta: { page: 1, perPage: 5000, orderBy: 'updated_at', orderDirection: 'asc', filters: [] },
}

export const useTasksQuery = ({ meta } = defaultProps) => {
  const { filters } = useTasksState()
  const metaWithFilters = { ...meta, filters }
  const flattenFilters = filters.map((f) => `${f.field}__${f.operator}=${f.value}`)
  const queryKey = ['tasks', meta, flattenFilters.join('&')]

  const { isLoading, isError, data, error } = useQuery({
    queryKey,
    queryFn: () => fetchTasks({ meta: metaWithFilters }),
  })

  return {
    queryKey,
    isLoading,
    isError,
    data: data?.data,
    meta: data?.meta,
    error,
  }
}