import withEventsProvider from '../components/hooks/withEventsProvider.jsx';
import withStatsProvider from '../components/hooks/withStatsProvider.jsx';
import EventsIndex from '../components/organisms/EventsIndex.jsx';

const EventsPageComponent = () => {
  return (
    <div className="EventsPage h-full overflow-y-auto">
      <EventsIndex />
    </div>
  );
};
export const EventsPage = withEventsProvider(withStatsProvider(EventsPageComponent));
