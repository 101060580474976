import React, { createContext, useContext, useReducer } from 'react'
import { getUniqueFilters } from '../utils/store.utils'
import _ from 'lodash'

const initialState = {
  filters: [],
  page: 1,
  selectedEvent: null,
  selectedView: 'list'
}

function reducer (state, action) {
  switch (action.type) {
    case 'ADD_FILTER':
      return { ...state, filters: getUniqueFilters(state.filters, action.payload), page: 1 }
    case 'REMOVE_FILTER':
      return {
        ...state,
        filters: state.filters.filter(filter => filter.field !== action.payload.field && filter.field.operator !== action.payload.operator),
        page: 1
      }
    case 'SET_DATE_RANGE':
      const newStateWithStartsAt = getUniqueFilters(state.filters, {
        field: 'starts_at',
        operator: 'gte',
        value: action.payload.startsAt
      })
      const newStateWithStartsAtAndEndsAt = getUniqueFilters(newStateWithStartsAt, {
        field: 'starts_at',
        operator: 'lte',
        value: action.payload.endsAt
      })
      return { ...state, filters: newStateWithStartsAtAndEndsAt, page: 1 }
    case 'SET_PAGE':
      return { ...state, page: action.payload }
    case 'SET_VIEW':
      return { ...state, selectedView: action.payload }
    case 'SET_SELECTED_EVENT':
      return { ...state, selectedEvent: action.payload }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const EventsStateContext = createContext()
const EventsDispatchContext = createContext()

function EventsProvider ({ children, extendInitialState }) {
  const [state, dispatch] = useReducer(reducer, _.merge({}, initialState, extendInitialState))

  return (
    <EventsStateContext.Provider value={state}>
      <EventsDispatchContext.Provider value={dispatch}>
        {children}
      </EventsDispatchContext.Provider>
    </EventsStateContext.Provider>
  )
}

function useEventsState () {
  const context = useContext(EventsStateContext)
  if (context === undefined) {
    throw new Error('useEventsState must be used within a EventsProvider')
  }
  return context
}

function useEventsDispatch () {
  const context = useContext(EventsDispatchContext)
  if (context === undefined) {
    throw new Error('useEventsDispatch must be used within a EventsProvider')
  }
  return context
}

export { EventsProvider, useEventsState, useEventsDispatch }
