import { useQuery } from '@tanstack/react-query'
import { fetchPeople } from '../../services/person.service.js'
import { usePeopleState } from '../../stores/people.js'
import { DEFAULT_META, generateQueryKey } from '../../utils/store.utils'

const defaultProps = {
  meta: DEFAULT_META,
}

export const usePeopleQuery = ({ meta } = defaultProps) => {
  const { filters: filtersInState } = usePeopleState()
  const { metaWithFilters, queryKey } = generateQueryKey({ topKey: 'people', meta, filtersInState })

  const { isLoading, isError, data, error } = useQuery({
    queryKey,
    queryFn: () => fetchPeople({ meta: metaWithFilters }),
  })

  return {
    isLoading,
    isError,
    data: data?.data,
    meta: data?.meta,
    error,
    queryKey,
  }
}

