import { DateTime } from 'luxon'
import { format, formatDistance, formatRelative, subDays } from 'date-fns'

export const fetchPreferredCalendarsFromLocalStorage = () => {
  try {
    const preferredCalendarsRaw = localStorage.getItem('preferredCalendars')
    const preferredCalendars = JSON.parse(preferredCalendarsRaw) || []
    return preferredCalendars
  } catch (e) {
    return []
  }
}

export const savePreferredCalendarsToLocalStorage = (calendars) => {
  localStorage.setItem('preferredCalendars', JSON.stringify(calendars))
}

// https://moment.github.io/luxon/#/formatting
export const formatDate = (dateString, format = { ...DateTime.DATETIME_MED_WITH_WEEKDAY, timeZoneName: 'short' }) => {
  // DateTime.fromISO(event.starts_at).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
  // return DateTime.fromMillis(Date.parse(dateString), { zone: tzid }).toFormat('MM/dd/yy hh:mma')
  return DateTime.fromMillis(Date.parse(dateString)).toLocaleString(format)
}
export const formatDateRelative = (dateString) => formatDistance(Date.parse(dateString), new Date(), { addSuffix: true })

