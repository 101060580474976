import { useEffect } from "react"
import { openSuccessToast } from "../../utils/toast.utils";

const useReloadCheck = () => {
  const checkIfNeedsReloadOnInterval = () => {
    const isReloadNeeded = window.localStorage.getItem('isReloadNeeded')
    if (isReloadNeeded == 'yes') {
      console.log("Update needed! Refreshing...")
      window.localStorage.setItem('isReloadNeeded', 'no')

      openSuccessToast({title: 'Update available', description: 'A new version is available. Refreshing the page in 5 seconds...', options: {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
      }});

      setTimeout(() => {
        window.location.reload()
      }, 5000)
    }
  }

  useEffect(() => {
    const interval = setInterval(checkIfNeedsReloadOnInterval, 1000)
    return () => clearInterval(interval)
  }, [])

  return null
}

export default useReloadCheck