import { useQuery } from '@tanstack/react-query'
import { fetchStripeConfig } from '../../services/stripe.service'
export const useStripeConfigQuery = () => {
  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['stripeConfig'],
    queryFn: fetchStripeConfig,
  }) // default cache time is 5 minutes. We have 30mins for the customer session to expire

  return {
    isLoading,
    isError,
    data: data?.data,
    error,
  }
}
