import { PageLayout } from '../layouts/page-layout'
import { sendContactMessage } from '../services/contact.service'
import { toast } from 'react-hot-toast'
import { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

export default function ContactPage () {
  const { user } = useAuth0()

  const [email, setEmail] = useState(user?.email || '')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [captcha, setCaptcha] = useState('')
  const [expectedCaptcha, setExpectedCaptcha] = useState([Math.round(Math.random() * 10), Math.round(Math.random() * 10)])

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (parseInt(captcha) !== (expectedCaptcha[0] + expectedCaptcha[1])) {
      toast.error('You did not solve the captcha correctly. Please try again.')
      setExpectedCaptcha([Math.round(Math.random() * 10), Math.round(Math.random() * 10)])
      return
    }

    const resp = await sendContactMessage(email, subject, message)

    if (resp.error) {
      toast.error('An error occurred while sending the message')
      return
    }

    toast.success('Message sent successfully')

    setEmail('')
    setSubject('')
    setMessage('')
    setCaptcha('')
    setExpectedCaptcha([Math.round(Math.random() * 10), Math.round(Math.random() * 10)])
  }

  return (
    <PageLayout>
      <div
        className="container px-4 pt-32 pb-12 mx-auto md:pt-32 lg:px-0 dark:bg-gray-900 flex flex-col justify-items-center">
        <div className="text-center">
          <h1
            className="mb-3 text-3xl font-bold text-gray-900 sm:text-4xl sm:leading-none sm:tracking-tight dark:text-white">
            Contact Us
          </h1>
          <p className="mb-6 text-lg font-normal text-gray-500 sm:text-xl dark:text-gray-400">
            Get in touch with us to discuss your needs. <br/>
            We're here to help you.
          </p>
        </div>
      </div>
      <section className="space-y-12 md:space-y-0 bg-white">
        <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
          <form action="#" className="space-y-8" onSubmit={handleSubmit}>
            <div>
              <label form="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your
                email</label>
              <input type="email" id="email"
                     className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                     placeholder="name@calendartrends.com" required
                     value={email}
                     onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label form="subject"
                     className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Subject</label>
              <input type="text" id="subject"
                     className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                     placeholder="Let us know how we can help you" required value={subject}
                     onChange={(e) => setSubject(e.target.value)}/>
            </div>
            <div className="sm:col-span-2">
              <label form="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Your
                message</label>
              <textarea id="message" rows="6"
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Leave a comment..." value={message} onChange={e => setMessage(e.target.value)}/>
            </div>


            <div>
              <label
                form="subject"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Verify you are human: Add {expectedCaptcha[0]} + {expectedCaptcha[1]}</label>
              <input type="text" id="subject"
                     className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                     placeholder="Please solve the challenge" required value={captcha}
                     onChange={(e) => setCaptcha(e.target.value)}/>
            </div>

            <button type="submit"
                    className="inline-flex justify-center rounded-lg py-2 px-3 text-sm font-semibold outline-2 outline-offset-2 transition-colors bg-gray-800 text-white hover:bg-gray-900 active:bg-gray-800 active:text-white/80">
              Send message
            </button>
          </form>
        </div>
      </section>
    </PageLayout>
  )
}
