import { useQuery } from '@tanstack/react-query'
import { me } from '../../services/user.service'

export const useUserQuery = (options) => {
  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['userDetails'],
    queryFn: () => me(options),
  })

  return {
    isLoading,
    isError,
    data: data?.data,
    error,
  }
}
