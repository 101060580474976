import axios from 'axios'

export const callExternalApi = async (options, catchErrors = true) => {
  try {
    const response = await axios(options.config)
    const { data, status } = response

    setGitCommitFromHeader(response)

    if (!catchErrors && status === 401) {
      window.location.href = '/logout'
      return
    }

    return { data, error: null }
  } catch (error) {
    if (!catchErrors) {
      throw error
    }

    return handleApiError(error)
  }
}

const handleApiError = (error) => {
  if (axios.isAxiosError(error)) {
    const { response } = error
    const message = getErrorMessage(error, response)

    return {
      data: null,
      error: {
        message,
        status: response?.status || 500,
      },
    }
  }

  return {
    data: null,
    error: {
      message: error.message,
      status: 500,
    },
  }
}

const getErrorMessage = (error, response) => {
  if (response?.data?.message) return response.data.message
  if (error.message) return error.message
  if (response?.statusText) return response.statusText
  return 'http request failed'
}

const setGitCommitFromHeader = (response) => {
  const gitCommit = response.headers['x-git-commit']

  if (gitCommit) {
    const currentGitCommit = window.localStorage.getItem('gitCommit')
    if (!!currentGitCommit?.length && currentGitCommit !== gitCommit) {
      window.localStorage.setItem('isReloadNeeded', 'yes')
    }
    window.localStorage.setItem('gitCommit', gitCommit)
  }
}
