import { callExternalApi } from './external-api.service'
import { generateApiParams } from '../utils/services.utils'

export const fetchStripeConfig = async () => {
  let { accessToken, baseUrl } = generateApiParams({ filters: [] })

  const config = {
    url: `${baseUrl}/stripe/main-config`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }

  const { data, error } = await callExternalApi({ config }, false)

  return {
    data: data || null,
    error,
  }
}

export const fetchStripePrices = async () => {
  let { accessToken, baseUrl } = generateApiParams({ filters: [] })

  const config = {
    url: `${baseUrl}/stripe/prices`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }

  const { data, error } = await callExternalApi({ config }, false)

  return {
    data: data || null,
    error,
  }
}

export const createStripeSubscription = async (priceId) => {
  const { accessToken, baseUrl } = generateApiParams({ filters: [] })
  const config = {
    url: `${baseUrl}/stripe/create-subscription`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: { priceId },
  }

  const { data, error } = await callExternalApi({ config }, false)
  
  return {
    data: data || null,
    error,
  }
}

export const cancelStripeSubscriptionAtStripe = async (subscriptionId) => {
  const { accessToken, baseUrl } = generateApiParams({ filters: [] })
  const config = {
    url: `${baseUrl}/stripe/cancel-subscription-at-stripe`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: { subscription_id: subscriptionId },
  }

  const { data, error } = await callExternalApi({ config }, false)

  return {
    data: data || null,
    error,
  }
}

export const reactivateStripeSubscriptionAtStripe = async (subscriptionId) => {
  const { accessToken, baseUrl } = generateApiParams({ filters: [] })
  const config = {
    url: `${baseUrl}/stripe/reactivate-subscription-at-stripe`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: { subscription_id: subscriptionId },
  }

  const { data, error } = await callExternalApi({ config }, false)

  return {
    data: data || null,
    error,
  }
}
