import React, { useState } from 'react'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import Modal from '@mui/joy/Modal'
import ModalDialog from '@mui/joy/ModalDialog'
import Typography from '@mui/joy/Typography'
import Link from '@mui/joy/Link'
import ModalShowMeHowToLinkCalendar from '../molecules/ModalShowMeHowToLinkCalendar'
import { openToast } from '../../utils/toast.utils'

const ModalLinkCalendar = ({ open, onClose, onLink }) => {
  const [url, setUrl] = useState('')
  const [isShowMeHowOpen, setIsShowMeHowOpen] = useState(false)

  const handleClose = () => {
    setUrl('')
    onClose()
  }

  const handleLink = () => {
    if (!url) {
      return openToast({
        title: 'Error',
        description: 'Please enter a valid calendar link.',
      })
    }

    handleClose()
    onLink(url)
  }

  const handleShowMeHow = () => {
    setIsShowMeHowOpen(true)
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalDialog
        aria-labelledby="nested-modal-title"
        aria-describedby="nested-modal-description"
        sx={(theme) => ({
          [theme.breakpoints.only('xs')]: {
            top: 'unset',
            bottom: 0,
            left: 0,
            right: 0,
            borderRadius: 0,
            transform: 'none',
            maxWidth: 'unset',
          },
        })}
      >
        <Typography id="nested-modal-title" level="h2">
          Enter the calendar link in the input below.
        </Typography>
        <Typography id="nested-modal-description" textColor="text.tertiary" className="!flex !flex-col pt-2">
          <span className="pr-1">
            You can find this link in your calendar settings > Integrate Calendar > Secret address in iCal format.&nbsp;
            <Link component="button" className="underline" onClick={handleShowMeHow}>Show me how</Link>
          </span>
          <br/>
          <input type="text"
                 placeholder="https://example.com/calendar.ics"
                 onChange={(e) => setUrl(e.target.value)}
                 value={url}
                 className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent"
          />
        </Typography>
        <Box
          sx={{
            mt: 1,
            display: 'flex',
            gap: 1,
            flexDirection: { xs: 'column', sm: 'row-reverse' },
          }}
        >
          <Button variant="solid" color="primary" onClick={handleLink}>
            Save
          </Button>
          <Button
            variant="outlined"
            color="neutral"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
        <ModalShowMeHowToLinkCalendar open={isShowMeHowOpen} onClose={() => setIsShowMeHowOpen(false)}/>
      </ModalDialog>
    </Modal>
  )
}

export default ModalLinkCalendar
