import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { useEventsDispatch, useEventsState } from '../../stores/events'
import { useEventsQuery } from '../hooks/useEventsQuery'
import { useEffect, useState } from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import classNames from 'classnames'
import { getColorFromId, getDateFromEvents, scrollToCurrentTime, transformEvents } from '../../utils/events.utils'
import { getUniqueFilters } from '../../utils/store.utils'

const localizer = momentLocalizer(moment) // or globalizeLocalizer

const EventScheduler = ({ className, calendarProps, customRange }) => {
  const eventsDispatch = useEventsDispatch()
  const { page, filters } = useEventsState()
  const filtersWithCustomRange = customRange ? [
    ...getUniqueFilters(filters, { field: 'starts_at', operator: 'gte', value: customRange.startsAt }),
    ...getUniqueFilters(filters, { field: 'starts_at', operator: 'lte', value: customRange.endsAt }),
  ] : filters
  const { isError, data: eventsResp, isLoading } = useEventsQuery({
    meta: {
      page,
      perPage: 500,
      orderBy: 'starts_at',
      orderDirection: 'desc',
      filters: filtersWithCustomRange
    },
  })
  const [currentDate, setCurrentDate] = useState(!isLoading && eventsResp ? getDateFromEvents(eventsResp.data) : new Date())

  const eventPropGetter = (event, start, end, isSelected) => {
    let newStyle = {
      background: getColorFromId(event.originalEvent.calendar_id),
      color: 'black',
      borderRadius: '4px',
      border: 'none',
      boxShadow: 'rgba(255, 255, 255, 1) 0px 0px 0px 1px'
      // boxShadow: '0px 1px 2px 0px rgba(60,64,67,0.3),0px 1px 3px 1px rgba(60,64,67,0.15)' // google's shadow-1
      // boxShadow: '0px 4px 4px 0px rgba(60,64,67,0.3),0px 8px 12px 6px rgba(60,64,67,0.15)' // google's shadow-5
    }

    if (event.originalEvent.calendar_user_status === 'DECLINED') {
      newStyle.textDecoration = 'line-through'
    } else if (event.originalEvent.calendar_user_status === 'NEEDS-ACTION') {
      newStyle = {
        ...newStyle,
        background: 'rgba(255, 255, 255, 0.5)',
        border: `1px solid ${getColorFromId(event.originalEvent.calendar_id)}`,
        boxShadow: `0 0 0 1px ${getColorFromId(event.originalEvent.calendar_id)}`
      }
    }

    return {
      className: '',
      style: newStyle
    }
  }

  const handleSelectEvent = (event) => {
    eventsDispatch({ type: 'SET_SELECTED_EVENT', payload: event.originalEvent })
  }

  const handleNavigate = (date) => {
    setCurrentDate(date)
  }

  const handleRangeChange = (range) => {
    let start = range.start || range[0]
    let end = range.end || range[range.length - 1]
    end = moment(end).endOf('day').toDate() // always make `end` the end of the day

    let startsAt = moment.utc(start).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    let endsAt = moment.utc(end).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')

    eventsDispatch({ type: 'SET_DATE_RANGE', payload: { startsAt, endsAt } })

    if (calendarProps?.defaultView === 'day') {
      setTimeout(() => scrollToCurrentTime(), 200)
    }
  }

  useEffect(() => {
    if (eventsResp) {
      const transformedEvents = transformEvents(eventsResp.data)
      setCurrentDate(getDateFromEvents(transformedEvents))

      if (calendarProps?.defaultView === 'day') {
        setTimeout(() => scrollToCurrentTime(), 200)
      }
    }
  }, [])

  if (isError) {
    return <div className={`p-4 text-center ${className}`}>Something went wrong. Please refresh the page and try
      again.</div>
  }

  const events = !isLoading && eventsResp?.data ? transformEvents(eventsResp?.data) : []

  return (
    <div
      className={classNames('EventScheduler', className, { 'opacity-50': isLoading })}
      style={styles.eventsScheduler}
    >
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        date={currentDate}
        style={styles.calendar}
        eventPropGetter={eventPropGetter}
        defaultView="week"
        onNavigate={handleNavigate}
        onSelectEvent={handleSelectEvent}
        onRangeChange={handleRangeChange}
        {...calendarProps}
      />
    </div>
  )
}

const styles = {
  eventsScheduler: {
    minHeight: '400px',
    height: '100%',
  },
  calendar: {
    minHeight: '400px',
    height: '100%',
  }
}

export default EventScheduler