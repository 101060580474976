import DashboardBlock from "../molecules/DashboardBlock"
import CalendarHeatmap from "./CalendarHeatmap"
import React from "react"
import CollapsableCard from "../molecules/CollapsableCard"
import { useStatsQuery } from "../hooks/useStatsQuery"
import Grid from "@mui/material/Grid"
import AngledTopRightLabel from "../atoms/AngledTopRightLabel"

const CalendarStats = ({ calendarId, className, onCalendarHeatmapClick }) => {
  const { data: statsData, isLoading: statsLoading } = useStatsQuery()

  if (statsLoading) return <div>Loading...</div>

  return (
    <CollapsableCard
      title="Stats"
      tooltip="Click on a day on the graph to filter"
      className={className}
      initiallyExpanded={true}
    >
      <div className="flex flex-col">
        <CalendarHeatmap calendarId={calendarId} borderless onClick={onCalendarHeatmapClick}/>
        <Grid container sx={{ flexGrow: 1 }} className="py-4 gap-4 border-t border-gray-200">
          <DashboardBlock
            title={<span className="flex items-center gap-2">Events last 30 days</span>}
            subtitle="vs. next 30 days"
            value={statsData.data.overall.events_last_30d.data}
            percentage={statsData.data.overall.events_last_30d.percentage}
          />
          <DashboardBlock
            title={<span className="flex items-center gap-2">Events next 30 days</span>}
            subtitle="vs. last 30 days"
            value={statsData.data.overall.events_next_30d.data}
            percentage={statsData.data.overall.events_next_30d.percentage}
          />
          <DashboardBlock
            title={<span className="flex items-center gap-2">Events last week</span>}
            subtitle="vs. this week"
            value={Object.values(statsData?.data?.last_week?.events?.data || {}).reduce((sum, value) => sum + value, 0)}
            percentage={statsData?.data?.last_week?.events?.percentage}
          />
          <DashboardBlock
            title={<span className="flex items-center gap-2">Events this week</span>}
            subtitle="vs. last week"
            value={Object.values(statsData?.data?.this_week?.events?.data || {}).reduce((sum, value) => sum + value, 0)}
            percentage={statsData?.data?.this_week?.events?.percentage}
          />
          <DashboardBlock
            title={<span className="flex items-center gap-2">Events next week</span>}
            subtitle="vs. this week"
            value={Object.values(statsData?.data?.next_week?.events?.data || {}).reduce((sum, value) => sum + value, 0)}
            percentage={statsData?.data?.next_week?.events?.percentage}
          />
          {/* <DashboardBlock
            title={
              <span className="flex items-center gap-2">
                PTO this year
                <AngledTopRightLabel>Coming Soon</AngledTopRightLabel>
              </span>
            }
            subtitle="vs. last year"
            value={0}
            percentage={0}
          /> */}
          <DashboardBlock
            title={
              <span className="flex items-center gap-2">
                Cost this week
                <AngledTopRightLabel>Coming Soon</AngledTopRightLabel>
              </span>
            }
            subtitle="vs. last week"
            value={0}
            percentage={0}
          />
        </Grid>
      </div>
    </CollapsableCard>
  )
}

export default React.memo(CalendarStats)