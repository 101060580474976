import Checkbox from '@mui/joy/Checkbox'
import Typography from '@mui/joy/Typography'
import Link from '@mui/joy/Link'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import Box from '@mui/joy/Box'
import { visuallyHidden } from '@mui/utils'
import PropTypes from 'prop-types'
import * as React from 'react'

function EnhancedTableHead (props) {
  const {
    onSelectAllClick,
    orderDirection,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    disableSelection,
    customCells
  } =
    props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <thead>
    <tr>
      {!disableSelection && (<th>
        <Checkbox
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={rowCount > 0 && numSelected === rowCount}
          onChange={onSelectAllClick}
          slotProps={{
            input: {
              'aria-label': 'select all desserts',
            },
          }}
          sx={{ verticalAlign: 'sub' }}
        />
      </th>)}
      {headCells.map((headCell) => {
        const active = orderBy === headCell.id
        return (
          <th
            key={headCell.id}
            aria-sort={
              active ? { asc: 'ascending', desc: 'descending' }[orderDirection] : undefined
            }
            style={headCell.style || {}}
            className={headCell.className || null}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            {headCell.disableSorting ? (
              <Typography
                sx={{ fontWeight: 'lg' }}
                component="div"
              >
                {headCell.label}
              </Typography>
            ) : (
              <Link
                underline="none"
                color="neutral"
                textColor={active ? 'primary.plainColor' : undefined}
                component="button"
                onClick={createSortHandler(headCell.id)}
                fontWeight="lg"
                startDecorator={
                  headCell.numeric ? (
                    <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }}/>
                  ) : null
                }
                endDecorator={
                  !headCell.numeric ? (
                    <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }}/>
                  ) : null
                }
                sx={{
                  '& svg': {
                    transition: '0.2s',
                    transform:
                      active && orderDirection === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                  },
                  '&:hover': { '& svg': { opacity: 1 } },
                }}
              >
                {headCell.label}
                {active ? (
                  <Box component="span" sx={visuallyHidden}>
                    {orderDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Link>
            )}
          </th>
        )
      })}

      {customCells.map((headCell) => {
        return (
          <th
            key={headCell.id}
            style={headCell.style}
            className={headCell.className || null}
          >
            <Typography sx={{ fontWeight: 'lg' }} component="div">
              {headCell.label}
            </Typography>
          </th>
        )
      })}
    </tr>
    </thead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  orderDirection: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

export default EnhancedTableHead
