import { useTasksQuery } from '../hooks/useTasksQuery'
import { Loader } from '../Loader'
import Checkbox from '@mui/joy/Checkbox'
import classNames from 'classnames'

const TasksTable = ({ onSave, onDelete }) => {
  const { isLoading, isError, data: tasksResp } = useTasksQuery()

  if (isLoading) {
    return <Loader/>
  }

  if (isError) {
    return <div className="p-4 text-center">Something went wrong. Please refresh the page and try again.</div>
  }

  const { data: tasks } = tasksResp || {}

  const handleCheckboxChange = (task, e) => {
    onSave({ ...task, completed: e.target.checked })
  }

  return (
    <div className="TasksTable pb-2 h-full overflow-auto bg-white flex flex-col">
      <div className="flex-1 gap-2 flex flex-col">
        {tasks.length === 0 && (
          <div className="text-center py-2 flex items-center flex-col">
            <p className="font-bold mb-2">You don't have anything to do!</p>
            <p className="text-gray-400 text-sm" style={{ maxWidth: '60%' }}>If you only did one thing today, what
              would
              it be?</p>
          </div>
        )}

        {tasks.map((task) => (
          <div key={task.id} className="flex items-center" title={task.description}>
            <div className="flex w-full">
              <Checkbox
                label={task.name}
                variant="outlined"
                checked={!!task.completed}
                onChange={(e) => handleCheckboxChange(task, e)}
                className={classNames('w-full', { 'line-through': task.completed })}
              />
            </div>
            <div>
              <button type="button" onClick={() => onDelete(task)} className="font-bold text-xl px-4">
                &times;
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TasksTable