import { useSubscriptionMutation } from '../hooks/useSubscriptionMutation'
import Button from '../atoms/Button'

const CancelSubscriptionButton = ({ activeSubscription }) => {
  const { mutate: cancelSubscriptionAtStripe, isLoading: isCancellingSubscription } = useSubscriptionMutation()

  const handleCancelSubscription = () => {
    if (window.confirm('Are you sure you want to cancel your subscription? You will lose access to all features and your data will be deleted within 30 days.')) {
      cancelSubscriptionAtStripe({ payload: { subscriptionId: activeSubscription.id }, action: 'CANCEL' })
    }
  }

  return (
    <div className="flex flex-col items-center">
    <Button 
      variant="plain" 
      color="danger" 
      onClick={handleCancelSubscription}
      loading={isCancellingSubscription}
    >
      Cancel Subscription
    </Button>
    <div className="text-sm text-gray-500 py-2 mt-2">
      <div className="font-bold">What happens when you cancel?</div>
      <div className="py-2">
        You will still have access to the features you have paid for until the end of the current billing period,
        but your data will be deleted within 30 days, and you will no longer be able to sync your calendars or events.
        You will no longer be able to use the predictive insights feature, and you will no longer be able to use the trends feature.
        You won't be charged for the next billing period.
      </div>
    </div>
    </div>
  )
}

export default CancelSubscriptionButton
