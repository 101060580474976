import * as React from 'react';
import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import EnhancedTableHead from './EnhancedTableHead';

function labelDisplayedRows({ from, to, count }) {
  return `${from} – ${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

export default function TableSortAndSelection({
  isGrouped = false,
  customCells = [],
  headCells,
  rows,
  disableSelection,
  totalPages = 1,
  onChangePage,
  page = 1,
  onChangeRowsPerPage = () => {},
  rowsPerPage = 10,
  totalRows,
  onRequestSort = () => {},
  orderDirection = 'asc',
  orderBy = 'id',
  onRowClick = null,
  tableStyle = {},
}) {
  const [selected, setSelected] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    onRequestSort({ property, direction: isAsc ? 'desc' : 'asc' });
  };

  const handleSelectAllClick = (event) => {
    if (disableSelection) return;

    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (row) => {
    const { id } = row;
    onRowClick && onRowClick(row);

    if (disableSelection) return;

    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;

    onChangePage(newPage);
  };

  const handleChangeRowsPerPage = (event, newValue) => {
    onChangeRowsPerPage(parseInt(newValue.toString(), 10));
    onChangePage(1);
  };

  const getLabelDisplayedRowsTo = () => {
    return Math.min(page * rowsPerPage, totalRows);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page === totalPages ? Math.max(0, rowsPerPage - rows.length) : 0;

  let combinedTableStyle = {
    '--TableCell-height': '40px',
    '--Table-firstColumnWidth': '80px',
    '--Table-lastColumnWidth': '144px',
    '--TableCell-headBackground': 'white',
    '--TableCell-selectedBackground': (theme) =>
      theme.vars.palette.success.softBg,
    '& thead th:nth-of-type(0)': { width: '40px' },
    '& tr': { textAlign: 'left' },
    '& tr > *:first-of-type': {
      position: 'sticky',
      left: 0,
      boxShadow: '1px 0 var(--TableCell-borderColor)',
      bgcolor: 'background.surface',
      zIndex: 1,
    },
    ...tableStyle,
  };
  headCells.forEach((headCell, index) => {
    combinedTableStyle[`& thead th:nth-of-type(${index + 1})`] = {
      ...headCell.styles,
      width: headCell.styles?.width || 'auto',
    };
  });

  return (
    <Sheet
      variant="outlined"
      sx={{
        width: '100%',
        // boxShadow: 'sm',
        borderRadius: 'sm',
        '--TableCell-height': '40px',
        '--Table-firstColumnWidth': '200px',
        '--Table-lastColumnWidth': '0px',
        '--TableHeader-height': 'calc(1 * var(--TableCell-height))',
        // background needs to have transparency to show the scrolling shadows
        '--TableRow-stripeBackground': 'rgba(0 0 0 / 0.04)',
        '--TableRow-hoverBackground': 'rgba(0 0 0 / 0.08)',
        overflow: 'auto',
        background: (theme) =>
          `linear-gradient(to right, ${theme.vars.palette.background.surface} 30%, rgba(255, 255, 255, 0)),
          linear-gradient(to right, rgba(255, 255, 255, 0), ${theme.vars.palette.background.surface} 70%) 0 100%,
          radial-gradient(
            farthest-side at 0 50%,
            rgba(0, 0, 0, 0.12),
            rgba(0, 0, 0, 0)
          ),
          radial-gradient(
              farthest-side at 100% 50%,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0)
            )
            0 100%`,
        backgroundSize:
          '40px calc(100% - var(--TableCell-height)), 40px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height))',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'local, local, scroll, scroll',
        backgroundPosition:
          'var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height), var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height)',
        backgroundColor: 'background.surface',
      }}
    >
      {/*<EnhancedTableToolbar numSelected={selected.length} />*/}
      <Table aria-labelledby="tableTitle" hoverRow sx={combinedTableStyle}>
        <EnhancedTableHead
          disableSelection={disableSelection}
          numSelected={selected.length}
          orderDirection={orderDirection}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
          headCells={headCells}
          customCells={customCells}
        />
        <tbody>
          {rows.map((row, index) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            const trStyles = isItemSelected
              ? {
                  '--TableCell-dataBackground':
                    'var(--TableCell-selectedBackground)',
                  '--TableCell-headBackground':
                    'var(--TableCell-selectedBackground)',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }
              : {};

            return (
              <tr
                onClick={(event) => handleClick(row)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                // selected={isItemSelected}
                style={{
                  ...row.styles,
                  ...trStyles,
                }}
                className={row.className}
              >
                {disableSelection ? null : (
                  <th scope="row">
                    <Checkbox
                      checked={isItemSelected}
                      slotProps={{
                        input: {
                          'aria-labelledby': labelId,
                        },
                      }}
                      sx={{ verticalAlign: 'top' }}
                    />
                  </th>
                )}
                {headCells.map((headCell) => {
                  const format = headCell.format || ((value) => value);
                  return (
                    <td
                      key={headCell.id}
                      style={{
                        ...headCell.styles,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                      className={headCell.className || null}
                      // title={typeof headCell.title !== 'undefined' ? headCell.title : format(row[headCell.id], row)}
                    >
                      {format(row[headCell.id], row)}
                    </td>
                  );
                })}
                {customCells?.map((cell) => {
                  return (
                    <td
                      key={cell.id}
                      style={{
                        ...cell.styles,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                      className={cell.className}
                      // title={typeof cell.title !== 'undefined' ? cell.title : cell.format(row)}
                    >
                      {cell.format(row)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          {emptyRows > 0 && (
            <tr
              style={{
                height: `calc(${emptyRows} * 40px)`,
                '--TableRow-hoverBackground': 'transparent',
              }}
            >
              <td colSpan={headCells.length + (disableSelection ? 0 : 1)} />
            </tr>
          )}
          {!rows.length && (
            <tr
              style={{
                height: `calc(1 * 40px)`,
                '--TableRow-hoverBackground': 'transparent',
              }}
            >
              <td colSpan={headCells.length + (disableSelection ? 0 : 1)}>
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                  component="div"
                >
                  There's no data to display here yet
                </Typography>
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td
              colSpan={
                customCells.length +
                headCells.length +
                (disableSelection ? 0 : 1)
              }
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  justifyContent: 'flex-end',
                }}
              >
                {isGrouped ? <Typography textAlign="center" sx={{ minWidth: 80 }}>Showing all grouped results</Typography> : (
                  <>
                    <FormControl orientation="horizontal" size="sm">
                      <FormLabel>Rows per page:</FormLabel>
                      <Select
                        onChange={handleChangeRowsPerPage}
                        value={rowsPerPage}
                      >
                        <Option value={5}>5</Option>
                        <Option value={10}>10</Option>
                        <Option value={25}>25</Option>
                      </Select>
                    </FormControl>
                    <Typography textAlign="center" sx={{ minWidth: 80 }}>
                      {labelDisplayedRows({
                        from:
                          rows.length === 0 ? 0 : (page - 1) * rowsPerPage + 1,
                        to: getLabelDisplayedRowsTo(),
                        count: rows.length < 1 ? 0 : totalRows,
                      })}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={page <= 1}
                        onClick={() => handleChangePage(page - 1)}
                        sx={{ bgcolor: 'background.surface' }}
                      >
                        <KeyboardArrowLeftIcon />
                      </IconButton>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={rows.length === 0 || page === totalPages}
                        onClick={() => handleChangePage(page + 1)}
                        sx={{ bgcolor: 'background.surface' }}
                      >
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    </Box>
                  </>
                )}
              </Box>
            </td>
          </tr>
        </tfoot>
      </Table>
    </Sheet>
  );
}
