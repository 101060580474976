import React, { createContext, useContext, useReducer } from 'react';

const initialState = {
  filters: [],
  page: 1,
};

function reducer(state, action) {
  switch (action.type) {
    case 'ADD_FILTER':
      const uniqueFilters = [...state.filters.filter(filter => filter.field !== action.payload.field), action.payload];
      return { ...state, filters: uniqueFilters, page: 1 };
    case 'REMOVE_FILTER':
      return { ...state, filters: state.filters.filter(filter => filter.field !== action.payload.field), page: 1 };
    case 'SET_PAGE':
      return { ...state, page: action.payload };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

const PeopleStateContext = createContext();
const PeopleDispatchContext = createContext();

function PeopleProvider({ children, initialStateOverride }) {
  const [state, dispatch] = useReducer(reducer, initialStateOverride || initialState);

  return (
    <PeopleStateContext.Provider value={state}>
      <PeopleDispatchContext.Provider value={dispatch}>
        {children}
      </PeopleDispatchContext.Provider>
    </PeopleStateContext.Provider>
  );
}

function usePeopleState() {
  const context = useContext(PeopleStateContext);
  if (context === undefined) {
    throw new Error('usePeopleState must be used within a PeopleProvider');
  }
  return context;
}

function usePeopleDispatch() {
  const context = useContext(PeopleDispatchContext);
  if (context === undefined) {
    throw new Error('usePeopleDispatch must be used within a PeopleProvider');
  }
  return context;
}

export { PeopleProvider, usePeopleState, usePeopleDispatch };
