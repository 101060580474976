import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import React, { useEffect } from 'react'
import { PageLoader } from '../components/PageLoader'
import { CableProvider } from '../cable'

export const AuthenticationGuard = ({ component }) => {
  const { getAccessTokenSilently } = useAuth0()

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="page-layout">
        <PageLoader/>
      </div>
    ),
  })

  const setAccessToken = async () => {
    const accessToken = await getAccessTokenSilently()
    localStorage.setItem('accessToken', accessToken)
  }

  useEffect(() => {
    setAccessToken()
  }, [])

  return (
    <CableProvider>
      <Component/>
    </CableProvider>
  )
}
