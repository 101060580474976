import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { PageLayout } from '../layouts/page-layout'
import { Loader } from '../components/Loader'

export const CallbackPage = () => {
  const { error } = useAuth0()

  if (error) {
    return (
      <PageLayout>
        <div className="content-layout">
          <h1 id="page-title" className="content__title">
            Error
          </h1>
          <div className="content__body">
            <p id="page-description">
              <span>{error.message}</span>
            </p>
          </div>
        </div>
      </PageLayout>
    )
  }

  // the redirect is handled by auth0-provider-with-navigate#onRedirectCallback
  return (
    <PageLayout>
      <Loader/>
    </PageLayout>
  )
}
