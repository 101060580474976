import TaskForm from '../molecules/TaskForm'
import Tooltip from '@mui/joy/Tooltip'
import { Card, CardContent, Divider } from '@mui/joy'
import QuestionIcon from '../atoms/QuestionIcon'
import { useTaskMutation } from '../hooks/useTaskMutation'
import TasksTable from '../molecules/TasksTable'
import Typography from '@mui/joy/Typography'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import classNames from 'classnames'
import TasksFilterDropdown from '../atoms/TasksFilterDropdown'
import { useTasksDispatch, useTasksState } from '../../stores/tasks'

const TasksContainer = ({ className }) => {
  const tasksDispatch = useTasksDispatch()
  const { filters: tasksFilters } = useTasksState()

  const { mutate, isLoading: isTaskLoading } = useTaskMutation()

  const handleFormSubmit = async (task) => {
    const action = task?.id ? 'update' : 'create'
    await mutate({ payload: task, action })
    action === 'create' && scrollToBottomOfTasksTable()
  }

  const handleDelete = async (task) => {
    await mutate({ payload: task, action: 'delete' })
  }

  const scrollToBottomOfTasksTable = () => {
    setTimeout(() => {
      const tasksTableDiv = document.querySelector('.TasksTable')
      tasksTableDiv.scrollTo({
        top: tasksTableDiv.scrollHeight,
        behavior: 'smooth'
      })
    }, 100)
  }

  const handleFilterChange = ({ action }) => {
    if (action === 'toggleCompletedTasksDisplay') {
      // the default value for completed is false. This is the default in the backend as well
      const completedFilter = tasksFilters.find(filter => filter.field === 'completed') || { value: false }

      const filterPayload = {
        field: 'completed',
        operator: 'eq',
        value: !completedFilter.value
      }
      tasksDispatch({
        type: 'ADD_FILTER',
        payload: filterPayload
      })
    }
  }

  return (
    <Card variant="outlined"
          className={classNames(`TasksContainer flex flex-col h-full w-full mr-0 md:mr-2`, className)}>
      <div className="flex">
        <Typography level="title-lg" startDecorator={<TaskAltIcon/>} className="w-full">
          To Do
          <Tooltip title="Focus on today. What are the most important things you want to accomplish today?" arrow
                   placement="right">
            <span><QuestionIcon className="ml-4"/></span>
          </Tooltip>
        </Typography>
        <TasksFilterDropdown onClick={handleFilterChange} filters={tasksFilters}/>
      </div>
      <Divider inset="none"/>
      <CardContent className="overflow-auto">
        <TasksTable onSave={handleFormSubmit} onDelete={handleDelete}/>
        <TaskForm onSave={handleFormSubmit} disabled={isTaskLoading} isLoading={isTaskLoading}/>
      </CardContent>
    </Card>
  )
}

export default TasksContainer