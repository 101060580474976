import { PageLayout } from '../layouts/page-layout'
import { Link } from 'react-router-dom'

export default function TermsPage () {

  const TermsV1 = () => {
    return (
      <div className="container mx-auto px-4 py-8">
        <section id="tos" className="mb-8">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">Terms of Service</h1>
          <p className="mb-4">By accessing or using CalendarTrends, you acknowledge that you have read,
            understood, and agree to be bound by these Terms of Service. If you do not agree to these Terms, you
            should not use the CalendarTrends service.</p>

          <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">1. Acceptance of Terms</h2>
          <p className="mb-4">By accessing CalendarTrends, you agree to these terms. If not, please do not use our
            service.</p>

          <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">2. Account Registration</h2>
          <p className="mb-4">To use CalendarTrends, you are required to create an account using a valid email
            address. No personally identifiable information is necessary for account creation. You are responsible
            for maintaining the confidentiality of your account and password and for restricting access to your
            computer, and you agree to accept responsibility for all activities that occur under your account or
            password.</p>

          <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">3. Service Description</h2>
          <p className="mb-4">CalendarTrends provides insights into scheduling patterns by analyzing calendar
            data. Our service is designed to help you make better decisions about your time management. The
            service is currently in beta and is offered free of charge, but we reserve the right to charge fees in
            the future.</p>

          <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">4. Data Anonymization and Privacy</h2>
          <p className="mb-4">You have the option to anonymize your data using a script provided by CalendarTrends
            (available <Link to="/anonymize">here</Link>). We are committed to ensuring the privacy and security of your
            data, which
            is encrypted both in-transit and at-rest. Your data is used solely for the purpose of providing
            insights within your account and is not used for any other purposes.</p>

          <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">5. User Data and Rights</h2>
          <p className="mb-4">You retain ownership of your data, whether anonymized or not. You have the right to
            delete your data from our service at any time.</p>
        </section>

        <section id="cookie-policy" className="mb-8">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">Cookie Policy</h1>
          <p className="mb-4">CalendarTrends uses cookies to ensure the best experience on our website. By using
            our site, you agree to our use of cookies.</p>

          <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">Use of Cookies</h2>
          <p className="mb-4">We use session cookies to manage your session. These cookies are essential for our
            service and are not used for ads or shared with third parties.</p>
        </section>
      </div>
    )
  }

  const TermsV2 = () => {
    return (
      <div>
        <div className="w-full break-words dark:prose-invert light">
          <p>Welcome to CalendarTrends! We are dedicated to helping you uncover hidden patterns in your calendar to make
            better decisions about managing your time.</p><p>By accessing or using our services, you agree to be bound
          by these Terms of Service ("Terms"). If you do not agree to these Terms, you must not use our services.</p>
          <p><strong>1. Agreement to Terms</strong>
            By using CalendarTrends, you confirm that you have read, understood, and agreed to these Terms. If you are
            using the Services on behalf of an organization or entity, you are agreeing to these Terms for that
            organization and promising that you have the authority to bind that organization to these Terms.</p><p>
          <strong>2. Changes to Terms</strong>
          We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new
          Terms on our site. By continuing to access or use our Services after those revisions become effective, you
          agree to be bound by the revised Terms.</p><p><strong>3. Account Registration</strong>
          To access certain features of our Service, you must register for an account by providing a valid email
          address and creating a password. You agree to maintain the security of your account and accept all risks of
          unauthorized access to your account.</p><p><strong>4. Privacy Policy</strong>
          Our Privacy Policy, which describes how we handle the information you provide to us when you use our
          Services, is available <Link to="/privacy">here</Link>. By using our Services, you agree that CalendarTrends
          can use such
          data in accordance with our privacy policies.</p><p><strong>5. Use of Services</strong>
          You are responsible for your use of the Services and for any use of the Services made using your account.
          You agree not to access, copy, or otherwise use the Services, including our intellectual property and
          trademarks, except as authorized by these Terms or as otherwise authorized in writing by CalendarTrends.</p>
          <p><strong>6. Content and Data</strong>
            You retain all rights to the calendar data you submit to our Service. By submitting data to CalendarTrends,
            you grant us a worldwide, non-exclusive, royalty-free license to use, copy, reproduce, process, and display
            that data solely to provide and improve the Services for you.</p><p><strong>7. Data Anonymization</strong>
          You have the option to anonymize your data using our provided tools. We encourage you to anonymize any
          sensitive information to protect your privacy. A tool to anonymize your calendar data is <Link
            to="/anonymize">available here</Link>, but feel free to anonymize it however you prefer, as long as it
          creates a valid <Link to="https://datatracker.ietf.org/doc/html/rfc5545" target="_blank">RFC 5545 ICS
            file</Link>.</p>
          <p><strong>8. Termination</strong>
            We may terminate or suspend your access to the Service immediately, without prior notice or liability, for
            any reason, including if you breach the Terms.</p><p><strong>9. Disclaimers</strong>
          The Services are provided on an "as is" and "as available" basis. CalendarTrends expressly disclaims any
          warranties of merchantability, fitness for a particular purpose, or non-infringement.</p><p><strong>10.
          Limitation of Liability</strong>
          In no event shall CalendarTrends, nor its directors, employees, partners, agents, suppliers, or affiliates,
          be liable for any indirect, incidental, special, consequential or punitive damages, including without
          limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to
          or use of or inability to access or use the Services.</p><p><strong>11. Governing Law</strong>
          These Terms shall be governed and construed in accordance with the laws of the State of Florida, United
          States, without regard to its conflict of law provisions.</p><p><strong>12. Changes to the Service</strong>
          We reserve the right to withdraw or amend our Service, and any service or material we provide via the
          Service, in our sole discretion without notice.</p><p><strong>13. Contact Us</strong>
          If you have any questions about these Terms, please <Link to="/contact">contact us</Link>.</p>
        </div>
      </div>
    )
  }

  return (
    <PageLayout>
      <section className="Terms bg-white dark:bg-gray-900 py-8">
        <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
          <h2
            className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">Terms</h2>
          <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
            All the legal stuff you need to know about using our website and services.
          </p>
          <div className="space-y-8">
            <TermsV2/>
          </div>
        </div>
      </section>
    </PageLayout>
  )
}
