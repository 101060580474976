import React, { useState, useEffect } from 'react';

function ErrorBoundary({ children, fallback = null }) {
  const [hasError, setHasError] = useState(false);
  const errorHandler = (error, info) => {
    console.error(error, info)
    // Update state to indicate that an error occurred
    setHasError(true);

    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    //   logErrorToMyService(error, info.componentStack);
    };

  useEffect(() => {
    // Add an error event listener to catch unhandled errors
    window.addEventListener('error', errorHandler);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  if (hasError) {
    // Render the provided fallback UI
    return fallback || <h1>Something went wrong.</h1>;
  }

  // Render the child components normally
  try {
    return children;
  } catch (error) {
    errorHandler(error);
    return null
  }
}

export default ErrorBoundary;