import CalHeatmap from 'cal-heatmap';
import Tooltip from 'cal-heatmap/plugins/Tooltip';
import Legend from 'cal-heatmap/plugins/Legend';
import CalendarLabel from 'cal-heatmap/plugins/CalendarLabel';
import 'cal-heatmap/cal-heatmap.css';
import React, { useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';
import html2canvas from 'html2canvas';
import { Grid } from '@mui/joy';
import Watermark from '../atoms/Watermark';
import classNames from 'classnames';
import { debounce } from 'lodash';

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

function CalendarHeatmap({ calendarId, className = '', borderless = false, onClick = () => {} }) {
  const accessToken = localStorage.getItem('accessToken');
  const isCalendarInitializedRef = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const calInstance = useRef(new CalHeatmap());
  const cal = calInstance.current;

  const handleDownloadAsPng = () => {
    html2canvas(document.querySelector('#calendar-heatmap-container')).then(
      (canvas) => {
        // Convert canvas to PNG
        let link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'calendartrends_heatmap.png';
        // Trigger the download
        link.click();
      }
    );
  };

  const fetchMaxHours = async () => {
    const startsAt = new Date();
    startsAt.setMonth(startsAt.getMonth() - 6);
    const formattedStartsAt = startsAt.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    const endsAt = new Date();
    endsAt.setMonth(endsAt.getMonth() + 6);
    const formattedEndsAt = endsAt.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    const response = await fetch(`${apiServerUrl}/api/stats/heatmap?fetch_max_hours=true&starts_at__gte=${formattedStartsAt}&ends_at__lte=${formattedEndsAt}&token=${accessToken}&calendar_id__eq=${calendarId || ''}`);
    const data = await response.json();

    const sortedHours = data.map(item => item.duration_in_hrs).sort((a, b) => a - b);
    const q1 = sortedHours[Math.floor(sortedHours.length * 0.25)];
    const q3 = sortedHours[Math.floor(sortedHours.length * 0.75)];
    const iqr = q3 - q1;
    const upperBound = q3 + 1.5 * iqr;
    const maxHours = Math.max(...sortedHours.filter(hour => hour <= upperBound));

    return maxHours;
  };

  const getCalHeatmapDataSourceUrl = () => {
    const calendarIdFilter = Array.isArray(calendarId) ? calendarId.join(',') : calendarId || '';
    return `${apiServerUrl}/api/stats/heatmap?starts_at__gte={{start=YYYY-MM-DD}}&ends_at__lte={{end=YYYY-MM-DD}}&token=${accessToken}&calendar_id__eq=${calendarIdFilter || ''}`;
  };

  const initCalendar = ({ maxHours }) => {
    if (isCalendarInitializedRef.current) return;

    isCalendarInitializedRef.current = true;

    cal.paint(
      {
        animationDuration: 500,
        domain: {
          type: 'month',
          padding: [0, 0, 0, 0],
          gutter: 0,
          label: {
            text: (timestampInt) => {
              const timestamp = new Date(timestampInt);
              return (
                timestamp.toLocaleString('default', { month: 'short' }) +
                " '" +
                timestamp.getFullYear().toString().slice(-2)
              );
            },
            position: 'top',
          },
        },
        subDomain: {
          type: 'ghDay', // `day` leaves a gap between months
          radius: 2,
          width: 16,
          height: 16,
          gutter: 1,
          label: (t, v) => v || '',
        },
        date: {
          start: new Date(),
          token: accessToken,
          highlight: [
            new Date(), // Highlight today
          ],
          locale: {
            weekStart: 1,
          },
        },
        data: {
          source: getCalHeatmapDataSourceUrl(),
          type: 'json',
          x: 'date',
          y: 'duration_in_hrs',
          groupY: 'sum',
          defaultValue: 0,
        },
        scale: {
          color: {
            scheme: 'greens',
            type: 'linear',
            domain: [0, Math.max(8, maxHours)], // anything over the max hours is considered "high"
          },
        },
      },
      [
        [
          Tooltip,
          {
            text: function (date, value, dayjsDate) {
              return (
                (value ? value + ' hour' + (value > 1 ? 's' : '') : 'No data') +
                ' on ' +
                dayjsDate.format('LL')
              );
            },
          },
        ],
        [
          CalendarLabel,
          {
            width: 30,
            textAlign: 'start',
            text: () => ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
            padding: [25, 0, 0, 0], // 25 is the top padding and it has to be 25 when showing the month
          },
        ],

        [
          Legend,
          {
            ticks: 4,
            width: 150,
            itemSelector: '#ex-year-legend',
            label: 'Event duration (hours)',
            marginRight: 10,
            marginLeft: 10,
          },
        ],
      ]
    );

    cal.on('fill', () => setIsLoading(false));
    cal.on('click', onClick);
  }
  const updateCalendarSource = useCallback(
    debounce(async () => {
      if (!isCalendarInitializedRef.current) return;

      setIsLoading(true);
      const maxHours = await fetchMaxHours();
      await cal.paint({
        data: {
          source: getCalHeatmapDataSourceUrl(),
          type: 'json',
          x: 'date',
          y: 'duration_in_hrs',
          groupY: 'sum',
          defaultValue: 0,
        },
        scale: {
          color: {
            scheme: 'greens',
            type: 'linear',
            domain: [0, Math.max(8, maxHours)], // anything over the max hours is considered "high"
          },
        },
      })
      setIsLoading(false);
    }, 300, { leading: false, trailing: true, maxWait: 300 }),
    [calendarId]
  );

  const initCalendarWithMaxHours = async () => {
    const maxHours = await fetchMaxHours();
    initCalendar({
      maxHours,
    });
  }

  useEffect(() => {
    initCalendarWithMaxHours();

    return () => {
      cal?.destroy();
    };
  }, []);

  useEffect(() => {
    updateCalendarSource();
  }, [calendarId]);

  return (
    <Grid container sx={{ flexGrow: 1 }} className="gap-4 w-full flex md:items-center md:justify-center">
      <div className={classNames(
        `relative p-4 ${className}`,
        {
          'border border-gray-200 rounded-lg bg-white': !borderless,
        },
      )}>
        {isLoading ? (
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="bg-white px-4 py-2 rounded-md shadow-md">
              Loading...
            </div>
          </div>
        ) : null}

        <div id="calendar-heatmap-container" className={classNames('relative', {'opacity-50': isLoading})}>
          <div
            id="cal-heatmap"
            style={{ minWidth: '316px', minHeight: '140px', width: '100%' }}
          />
          <div className="flex items-center justify-end w-full pt-2">
            <div id="ex-year-legend" />
          </div>

          {!isLoading && <Watermark />}
        </div>
        {!isLoading && <div className="flex absolute bottom-6 left-4" id="button-container">
          <a
            className="px-2 py-1 text-xs font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-2"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              cal.previous();
            }}
          >
            ← Previous
          </a>
          <a
            className="px-2 py-1 text-xs font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              cal.next();
            }}
          >
            Next →
          </a>

          <button
            className="px-2 py-1 text-xs font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ml-2"
            onClick={handleDownloadAsPng}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 inline-block mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
              />
            </svg>
            Save as PNG
          </button>
        </div>}
      </div>
    </Grid>
  );
}

export default React.memo(CalendarHeatmap);
