import mixpanel from 'mixpanel-browser'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import debounce from 'lodash/debounce'

export const EVENTS = {
  OPEN_LINK_CALENDAR_MODAL: 'Open link calendar modal',
  LINK_CALENDAR: 'Link Calendar',
  VIEW_PRICING_PAGE: 'View Pricing Page',
}

const useMixpanel = () => {
  const { user } = useAuth0()

  useEffect(() => {
    if (process.env.REACT_APP_MIXPANEL_ENABLED !== 'true') {
      return
    }

    if (user) {
      mixpanel.identify(user.email)
      mixpanel.people.set({
        '$email': user.email,
      })
    }
  }, [])

  const track = debounce((eventName, properties) => {
    if (process.env.REACT_APP_MIXPANEL_ENABLED !== 'true') {
      return
    }

    mixpanel.track(eventName, properties)
  }, 300)
  return { track }
}

export default useMixpanel