import { DateTime } from 'luxon'
import * as PropTypes from 'prop-types'
import CalendarUser from './CalendarUser'
import { PeopleProvider } from '../../stores/people'
import { formatDate, formatDateRelative } from '../../utils/calendars.utils'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Tooltip from '@mui/joy/Tooltip'
import * as React from 'react'

function CalendarBlock ({ calendar, onClick, customCells, callbackfn }) {
  const peopleInitialState = {
    page: 1,
    filters: [
      { field: 'calendar_id', operator: 'eq', value: calendar.id },
      { field: 'email', operator: 'like', value: calendar.user_email || '' }
    ]
  }

  return <div className="px-2 flex justify-center">
    <div
      className="relative flex flex-col mt-6 text-gray-700 bg-white shadow-md bg-clip-border rounded-md border w-full min-h-60">
      {/*<div*/}
      {/*  className="relative h-56 mx-4 -mt-6 overflow-hidden text-white shadow-lg bg-clip-border rounded-xl bg-blue-gray-500 shadow-blue-gray-500/40">*/}
      {/*  <img*/}
      {/*    src="https://images.unsplash.com/photo-1540553016722-983e48a2cd10?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=800&amp;q=80"*/}
      {/*    alt="card-image"/>*/}
      {/*</div>*/}
      <div className="p-6 flex flex-col flex-grow">
        <h5
          className="block mb-2 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
          {calendar.name || 'Unnamed'}
        </h5>
        <div
          className="block font-sans text-base antialiased font-light leading-relaxed text-inherit flex flex-col flex-grow">
          <div className="flex-grow">
            <div><b className="font-semibold">Events: </b>{calendar.events_count}</div>
            <div><b className="font-semibold">Refreshed: </b>
              {calendar.last_synced_at ? (
                <>
                  {formatDateRelative(calendar.last_synced_at)}
                  <Tooltip title={formatDate(calendar.last_synced_at, DateTime.DATETIME_MED_WITH_WEEKDAY)} arrow
                           placement="right">
                    <HelpOutlineIcon className="scale-75 relative"/>
                  </Tooltip>
                </>
              ) : 'Refreshing...'}
            </div>
            {calendar.last_sync_error_message &&
              <div className="font-semibold text-red-500 text-sm">{calendar.last_sync_error_message}</div>}
            <div>
              <PeopleProvider initialStateOverride={peopleInitialState}>
                <CalendarUser calendarId={calendar.id} calendarName={calendar.name} userEmail={calendar.user_email}/>
              </PeopleProvider>
            </div>
          </div>
        </div>
        <div className="flex gap-2">
          {/*TODO: replace with a ButtonLink */}
          <button
            onClick={onClick}
            className="relative align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-2 px-4 rounded-lg bg-gray-900 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
          >
            Details
          </button>
          {customCells.map(callbackfn)}
        </div>
      </div>
    </div>
  </div>
}

CalendarBlock.propTypes = {
  calendar: PropTypes.any,
  onClick: PropTypes.func,
  customCells: PropTypes.any,
  callbackfn: PropTypes.func
}

export default CalendarBlock