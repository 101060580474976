import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import ImageWithDefault from '../components/atoms/ImageWithDefault'
import defaultProfilePicture from '../assets/default-pfp-19.jpg'
import { featureNotAvailableToast } from '../utils/toast.utils'

export const ProfilePage = () => {
  const { user } = useAuth0()

  if (!user) {
    return null
  }

  const noop = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  // to render the user object in the html
  // console.log(JSON.stringify(user, null, 2))

  return (
    <main className="pb-4">
      <div className="grid grid-cols-1 px-4 pt-6 xl:grid-cols-3 xl:gap-4 dark:bg-gray-900">
        <div className="mb-4 col-span-full xl:mb-2">
          <nav className="flex mb-5" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 text-sm font-medium md:space-x-2">
              <li className="inline-flex items-center">
                <a href="#"
                   className="inline-flex items-center text-gray-700 hover:text-primary-600 dark:text-gray-300 dark:hover:text-white">
                  <svg className="w-5 h-5 mr-2.5" fill="currentColor" viewBox="0 0 20 20"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                  </svg>
                  Home
                </a>
              </li>
              <li>
                <div className="flex items-center">
                  <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"></path>
                  </svg>
                  <a href="#"
                     className="ml-1 text-gray-700 hover:text-primary-600 md:ml-2 dark:text-gray-300 dark:hover:text-white">Users</a>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"></path>
                  </svg>
                  <span className="ml-1 text-gray-400 md:ml-2 dark:text-gray-500"
                        aria-current="page">Settings</span>
                </div>
              </li>
            </ol>
          </nav>
          <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">User settings</h1>
        </div>

        <div className="col-span-full xl:col-auto">
          <div
            className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
            <div className="items-center sm:flex xl:block 2xl:flex sm:space-x-4 xl:space-x-0 2xl:space-x-4">
              <div
                className="h-20 w-20 rounded-lg overflow-hidden text-center inline-flex">
                <ImageWithDefault
                  imageUrl={user.picture}
                  defaultUrl={defaultProfilePicture}
                  alt="Profile"
                  className="mb-4 sm:mb-0 xl:mb-4 2xl:mb-0 h-full w-full object-cover"
                />
              </div>
              <div>
                <h3 className="mb-1 text-xl font-bold text-gray-900 dark:text-white">Profile picture</h3>
                <div className="mb-4 text-sm text-gray-500 dark:text-gray-400">
                  You look great!
                </div>
                <div className="flex items-center space-x-4">
                  <button type="button"
                          className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                          onClick={featureNotAvailableToast}
                  >
                    <svg className="w-4 h-4 mr-2 -ml-1" fill="currentColor" viewBox="0 0 20 20"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"></path>
                      <path d="M9 13h2v5a1 1 0 11-2 0v-5z"></path>
                    </svg>
                    Upload picture
                  </button>
                  <button type="button"
                          onClick={featureNotAvailableToast}
                          className="py-2 px-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
            <h3 className="mb-4 text-xl font-semibold dark:text-white">Language &amp; Time</h3>
            <div className="mb-4">
              <label htmlFor="settings-language"
                     className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Select language
              </label>
              <select id="settings-language" name="countries"
                      className="bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      value={user.locale}
              >
                <option value="en">English (US)</option>
                <option value="it">Italiano</option>
                <option value="fr">Français (France)</option>
                <option value="es">Español (España)</option>
                <option value="pt">Português (Brasil)</option>
              </select>
            </div>
            <div className="mb-6">
              <label htmlFor="settings-timezone"
                     className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Time Zone</label>
              <select id="settings-timezone" name="countries"
                      className="bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      value={user.timezone}
              >
                <option value="UTC">UTC (UTC 0:00)</option>
                <option value="Pacific/Honolulu">Hawaii Time (UTC-10:00)</option>
                <option value="America/Anchorage">Alaska Time (UTC-09:00)</option>
                <option value="America/Los_Angeles">Pacific Time (UTC-08:00)</option>
                <option value="America/Denver">Mountain Time (UTC-07:00)</option>
                <option value="America/Chicago">Central Time (UTC-06:00)</option>
                <option value="America/New_York">Eastern Time (UTC-05:00)</option>
              </select>
            </div>
            <div>
              <button
                className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                onClick={featureNotAvailableToast}>
                Save all
              </button>
            </div>
          </div>

        </div>
        <div className="col-span-2">
          <div
            className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
            <h3 className="mb-4 text-xl font-semibold dark:text-white">General information</h3>
            <form action="#" onSubmit={noop}>
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="first-name"
                         className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First
                    Name</label>
                  <input type="text" name="first-name" id="first-name"
                         className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                         value={user.given_name}
                         placeholder="" required=""/>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="last-name"
                         className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last
                    Name</label>
                  <input type="text" name="last-name" id="last-name"
                         className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                         value={user.family_name}
                         placeholder="" required=""/>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="email"
                         className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                  <input type="email" name="email" id="email"
                         className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                         placeholder="example@company.com" required="" value={user.email}/>
                  <div className="mt-2">
                    {user.email_verified ? (
                      <label htmlFor="" className="block text-sm font-medium text-green-500">
                        <CheckCircleOutlineOutlinedIcon/> Email verified
                      </label>
                    ) : (
                      <label htmlFor="" className="block text-sm font-medium text-red-500">
                        <HighlightOffOutlinedIcon/> Email not verified
                      </label>
                    )}
                  </div>
                </div>

                <div className="col-span-6 sm:col-full">
                  <button
                    className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                    type="submit"
                    onClick={featureNotAvailableToast}
                  >Save all
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div
            className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
            <h3 className="mb-4 text-xl font-semibold dark:text-white">Password information</h3>
            <form action="#" onSubmit={noop}>
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="current-password"
                         className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Current
                    password</label>
                  <input type="text" name="current-password" id="current-password"
                         className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                         placeholder="••••••••" required=""/>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="password"
                         className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">New
                    password</label>
                  <input data-popover-target="popover-password" data-popover-placement="bottom" type="password"
                         id="password"
                         className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                         placeholder="••••••••" required=""/>
                  <div data-popover="" id="popover-password" role="tooltip"
                       className="absolute z-10 invisible inline-block text-sm font-light text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400"
                       style={{
                         position: 'absolute',
                         inset: 'auto auto 0px 0px',
                         margin: '0px',
                         transform: 'translate3d(853.5px, -1768px, 0px)'
                       }}
                       data-popper-reference-hidden="" data-popper-escaped="" data-popper-placement="top">
                    <div className="p-3 space-y-2">
                      <h3 className="font-semibold text-gray-900 dark:text-white">Must have at least 6
                        characters</h3>
                      <div className="grid grid-cols-4 gap-2">
                        <div className="h-1 bg-orange-300 dark:bg-orange-400"></div>
                        <div className="h-1 bg-orange-300 dark:bg-orange-400"></div>
                        <div className="h-1 bg-gray-200 dark:bg-gray-600"></div>
                        <div className="h-1 bg-gray-200 dark:bg-gray-600"></div>
                      </div>
                      <p>It’s better to have:</p>
                      <ul>
                        <li className="flex items-center mb-1">
                          <svg className="w-4 h-4 mr-2 text-green-400 dark:text-green-500" aria-hidden="true"
                               fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"></path>
                          </svg>
                          Upper &amp; lower case letters
                        </li>
                        <li className="flex items-center mb-1">
                          <svg className="w-4 h-4 mr-2 text-gray-300 dark:text-gray-400" aria-hidden="true"
                               fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                  clipRule="evenodd"></path>
                          </svg>
                          A symbol (#$&amp;)
                        </li>
                        <li className="flex items-center">
                          <svg className="w-4 h-4 mr-2 text-gray-300 dark:text-gray-400" aria-hidden="true"
                               fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                  clipRule="evenodd"></path>
                          </svg>
                          A longer password (min. 12 chars.)
                        </li>
                      </ul>
                    </div>
                    <div data-popper-arrow=""
                         style={{
                           position: 'absolute',
                           left: 0,
                           transform: 'translate3d(139px, 0px, 0px)'
                         }}></div>
                  </div>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="confirm-password"
                         className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm
                    password</label>
                  <input type="text" name="confirm-password" id="confirm-password"
                         className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                         placeholder="••••••••" required=""/>
                </div>
                <div className="col-span-6 sm:col-full">
                  <button
                    className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                    type="submit" onClick={featureNotAvailableToast}>Save all
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

      </div>
      <div className="grid grid-cols-1 px-4 xl:grid-cols-2 xl:gap-4">
        <div
          className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800 xl:mb-0">
          <div className="flow-root">
            <h3 className="text-xl font-semibold dark:text-white">Alerts &amp; Notifications</h3>
            <p className="text-sm font-normal text-gray-500 dark:text-gray-400">You can set up your account to get
              notifications</p>
            <div className="divide-y divide-gray-200 dark:divide-gray-700">
              <div className="flex items-center justify-between py-4">
                <div className="flex flex-col flex-grow">
                  <div className="text-lg font-semibold text-gray-900 dark:text-white">Calendar update status
                  </div>
                  <div className="text-base font-normal text-gray-500 dark:text-gray-400">
                    Get a reminder when your calendar has not been updated in awhile
                  </div>
                </div>
                <label htmlFor="company-news" className="relative flex items-center cursor-pointer">
                  <input type="checkbox" id="company-news" className="sr-only" checked=""/>
                  <span
                    className="h-6 bg-gray-200 border border-gray-200 rounded-full w-11 toggle-bg dark:bg-gray-700 dark:border-gray-600"></span>
                </label>
              </div>
              <div className="flex items-center justify-between py-4">
                <div className="flex flex-col flex-grow">
                  <div className="text-lg font-semibold text-gray-900 dark:text-white">Check-in reminder</div>
                  <div className="text-base font-normal text-gray-500 dark:text-gray-400">
                    Get a reminder to check-in (aka. standup) so you plan for the day ahead
                  </div>
                </div>
                <label htmlFor="account-activity" className="relative flex items-center cursor-pointer">
                  <input type="checkbox" id="account-activity" className="sr-only" checked=""/>
                  <span
                    className="h-6 bg-gray-200 border border-gray-200 rounded-full w-11 toggle-bg dark:bg-gray-700 dark:border-gray-600"></span>
                </label>
              </div>
              <div className="flex items-center justify-between py-4">
                <div className="flex flex-col flex-grow">
                  <div className="text-lg font-semibold text-gray-900 dark:text-white">Check-out reminder</div>
                  <div className="text-base font-normal text-gray-500 dark:text-gray-400">
                    Get a reminder to check-out so you record your day's progress
                  </div>
                </div>
                <label htmlFor="meetups" className="relative flex items-center cursor-pointer">
                  <input type="checkbox" id="meetups" className="sr-only" checked=""/>
                  <span
                    className="h-6 bg-gray-200 border border-gray-200 rounded-full w-11 toggle-bg dark:bg-gray-700 dark:border-gray-600"></span>
                </label>
              </div>
              <div className="flex items-center justify-between pt-4">
                <div className="flex flex-col flex-grow">
                  <div className="text-lg font-semibold text-gray-900 dark:text-white">Meeting suggestions</div>
                  <div className="text-base font-normal text-gray-500 dark:text-gray-400">
                    Get suggestions on how to organize your meetings.
                  </div>
                </div>
                <label htmlFor="new-messages" className="relative flex items-center cursor-pointer">
                  <input type="checkbox" id="new-messages" className="sr-only" checked=""/>
                  <span
                    className="h-6 bg-gray-200 border border-gray-200 rounded-full w-11 toggle-bg dark:bg-gray-700 dark:border-gray-600"></span>
                </label>
              </div>
            </div>
            <div className="mt-6">
              <button
                className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                onClick={featureNotAvailableToast}
              >
                Save all
              </button>
            </div>
          </div>
        </div>
        <div
          className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800 xl:mb-0">
          <div className="flow-root">
            <h3 className="text-xl font-semibold dark:text-white">Email Notifications</h3>
            <p className="text-sm font-normal text-gray-500 dark:text-gray-400">You can set up your account to get
              email notifications </p>
            <div className="divide-y divide-gray-200 dark:divide-gray-700">
              <div className="flex items-center justify-between py-4">
                <div className="flex flex-col flex-grow">
                  <div className="text-lg font-semibold text-gray-900 dark:text-white">Week-ahead snapshot</div>
                  <div className="text-base font-normal text-gray-500 dark:text-gray-400">
                    Send an email on Sunday evening with an overview of the week ahead.
                  </div>
                </div>
                <label htmlFor="rating-reminders" className="relative flex items-center cursor-pointer">
                  <input type="checkbox" id="rating-reminders" className="sr-only"/>
                  <span
                    className="h-6 bg-gray-200 border border-gray-200 rounded-full w-11 toggle-bg dark:bg-gray-700 dark:border-gray-600"></span>
                </label>
              </div>
              <div className="flex items-center justify-between py-4">
                <div className="flex flex-col flex-grow">
                  <div className="text-lg font-semibold text-gray-900 dark:text-white">Multiple high stress
                    meetings detected
                  </div>
                  <div className="text-base font-normal text-gray-500 dark:text-gray-400">
                    Send me an email when multiple high-stress meetings are detected within the next 72hrs.
                  </div>
                </div>
                <label htmlFor="item-update" className="relative flex items-center cursor-pointer">
                  <input type="checkbox" id="item-update" className="sr-only" checked=""/>
                  <span
                    className="h-6 bg-gray-200 border border-gray-200 rounded-full w-11 toggle-bg dark:bg-gray-700 dark:border-gray-600"></span>
                </label>
              </div>
            </div>
            <div className="mt-6">
              <button
                className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                onClick={featureNotAvailableToast}
              >
                Save all
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>

  )
}
