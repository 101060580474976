import ModalDialog from '@mui/joy/ModalDialog'
import Typography from '@mui/joy/Typography'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import Modal from '@mui/joy/Modal'
import React from 'react'
import { Card, CardContent, CardCover } from '@mui/joy'
import howToLinkCalendarPoster from '../../assets/how-to-link-calendar-poster.png'
import howToLinkCalendarVideo from '../../assets/how-to-link-calendar.mp4'

const ModalShowMeHowToLinkCalendar = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        aria-labelledby="nested-modal-title"
        aria-describedby="nested-modal-description"
        sx={(theme) => ({
          [theme.breakpoints.only('xs')]: {
            top: 'unset',
            bottom: 0,
            left: 0,
            right: 0,
            borderRadius: 0,
            transform: 'none',
            maxWidth: 'unset',
          },
        })}
      >
        <Card className="m-auto" sx={{
          maxWidth: '90%',
          maxHeight: '90%',
          width: 1024,
          height: 720,
          flexGrow: 1
        }}>
          <CardCover>
            <video
              autoPlay
              loop
              muted
              poster={howToLinkCalendarPoster}
            >
              <source
                src={howToLinkCalendarVideo}
                type="video/mp4"
              />
            </video>
          </CardCover>
          <CardContent>
            <Typography
              level="body-lg"
              fontWeight="lg"
              textColor="#fff"
              mt={{ xs: 12, sm: 18 }}
            >
            </Typography>
          </CardContent>
        </Card>
        <Box
          sx={{
            mt: 1,
            display: 'flex',
            gap: 1,
            flexDirection: { xs: 'column', sm: 'row-reverse' },
          }}
        >
          <Button
            variant="outlined"
            color="neutral"
            onClick={onClose}
          >
            Close
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  )
}

export default ModalShowMeHowToLinkCalendar