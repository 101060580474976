import classNames from 'classnames'

const DashboardBlock = ({
  title = null,
  value,
  subtitle = null,
  percentage = null,
  prefix = null,
  children = null,
  percentageColorDirection = 1,
  options = {}
}) => (
  <div
    className="items-start p-4 bg-white border border-gray-200 rounded-lg shadow-sm sm:flex dark:border-gray-700 sm:p-6 dark:bg-gray-800 relative overflow-hidden">
    <div className="w-full">
      <div className={classNames('flex', {
        'flex-row justify-between': options.percentageOnTopRight,
        'flex-col': !options.percentageOnTopRight
      })}>
        {typeof value !== 'undefined' && <span
          className="text-2xl font-bold leading-none text-gray-900 sm:text-4xl dark:text-white">{prefix}{value.toLocaleString()}</span>}

        <div className="text-base font-semibold text-gray-500 dark:text-gray-400">{title}</div>

        <p className={classNames('flex items-center text-base font-normal text-gray-500 dark:text-gray-400', {
          'flex-col justify-end text-right -mt-2': options.percentageOnTopRight
        })}>
          {percentage !== null && (<span className={classNames('flex items-center mr-1.5', {
            'text-sm': !options.percentageOnTopRight,
            'text-xl': options.percentageOnTopRight,
            'text-green-500': (percentage * percentageColorDirection) < 0,
            'text-red-500': (percentage * percentageColorDirection) > 0
          })}>
              {percentage !== 0 && (<svg className={classNames('w-4 h-4', {
                'transform rotate-180': percentage < 0,
                'transform rotate-0': percentage >= 0
              })} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                                         aria-hidden="true">
                <path clipRule="evenodd" fillRule="evenodd"
                      d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"></path>
              </svg>)}
            {percentage}%
            </span>)}
          {subtitle && <span className={classNames('font-normal text-gray-500 dark:text-gray-400', {
            'text-xs': options.percentageOnTopRight
          })}>{subtitle}</span>}
        </p>
      </div>
      {children}
    </div>
  </div>
)

export default DashboardBlock

