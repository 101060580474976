import { callExternalApi } from './external-api.service'

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL

export const sendContactMessage = async (email, subject, message) => {
  const accessToken = localStorage.getItem('accessToken')
  const config = {
    url: `${apiServerUrl}/api/messages`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      email,
      subject,
      message
    }
  }

  const { data, error } = await callExternalApi({ config })

  return {
    data: data || null,
    error,
  }
}