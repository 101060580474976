import React, { useState } from 'react';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import classNames from 'classnames';
import Tooltip from '@mui/joy/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const CollapsableCard = ({
  title = null,
  children,
  className = '',
  tooltip = null,
  initiallyExpanded = true,
  collapsable = true,
}) => {
  const [isExpanded, setIsExpanded] = useState(initiallyExpanded);

  const toggleExpand = () => collapsable ? setIsExpanded(!isExpanded) : null;

  return (
    <div
      className={`CollapsableCard border border-gray-300 p-2 bg-white rounded-md ${className}`}
    >
      {title ? (
        <h2
          className={classNames(
            'text-lg font-medium flex items-center',
            { 
              'border-b-2 pb-2 mb-4': isExpanded,
              'cursor-default': !collapsable,
              'cursor-pointer': collapsable
            }
          )}
          onClick={toggleExpand}
        >
          {collapsable ? (
            <>
              {isExpanded ? (
                <KeyboardArrowDownOutlinedIcon />
              ) : (
                <KeyboardArrowRightOutlinedIcon />
              )}
            </>
          ) : null}

          <span className="flex items-center">
            {title}
            {tooltip ? (
              <Tooltip title={tooltip} arrow placement="right">
                <HelpOutlineIcon className="scale-75 relative ml-2" />
              </Tooltip>
            ) : null}
          </span>
        </h2>
      ) : null}
      {isExpanded && (
        <div className="CollapsableCard-content overflow-auto max-h-[calc(100vh-200px)]">
          {children}
        </div>
      )}
    </div>
  );
};

export default CollapsableCard;
