import { useVersionQuery } from '../hooks/useVersionQuery';

const Version = () => {
  const { isLoading, isError, data, error } = useVersionQuery();

  if (isLoading) {
    return <span>Loading commit...</span>;
  }

  if (isError) {
    return <span>Error fetching commit: {error.message}</span>;
  }

  const shortCommit = data?.git_commit ? data.git_commit.slice(0, 8) : 'N/A';

  return <>Version {shortCommit}</>;
};

export default Version;