import BackgroundBlob from '../../../assets/background-blob.png';
import classNames from 'classnames';
import ProductScreenshotCalendar from '../../../assets/product-screenshot-calendar.jpg';
import ProductScreenshotStats from '../../../assets/product-screenshot-stats.jpg';
import ProductScreenshotFocus from '../../../assets/product-screenshot-focus.jpg';
import ProductScreenshotEvents from '../../../assets/product-screenshot-events.jpg';
import { useState } from 'react';

export default function SectionHowItWorks() {
  const [visibleStep, setVisibleStep] = useState(1);

  const header = 'How it works';
  const subheader = (
    <>
      Connect your calendar, analyze your events, and discover insights to
      optimize your time effortlessly. <br /> Here's a simple guide to get you started.
    </>
  );

  const steps = [
    {
      id: 1,
      title: 'Add calendar(s)',
      description:
        <>
          Provide a link to an .ics file, which is a standard calendar format used by Google
          Calendar, Apple, Outlook and others. No need to manually import your
          events or use third-party service permissions.
        </>,
      image: ProductScreenshotCalendar,
    },
    {
      id: 2,
      title: 'Review Stats',
      description:
        <>
          Reveal hidden patterns, highlight productivity peaks, and uncover areas for
          improvement. Use these insights to make smarter decisions about how you
          spend your time.
        </>,
      image: ProductScreenshotStats,
    },
    {
      id: 3,
      title: 'Review events',
      description:
        <>
          Check a detailed table of your events, filter and sort for clarity. Group
          events and sum their durations. Evaluate stress levels and total
          participants to enhance efficiency.
        </>,
      image: ProductScreenshotEvents,
    },
    {
      id: 4,
      title: 'Focus More',
      description:
        <>
          Manage your day effectively with a side-by-side view of your to-do list
          and today's events. Stay on top of your tasks while keeping an eye on
          your schedule to ensure you focus on what matters most.
        </>,
      image: ProductScreenshotFocus,
    },
  ];

  return (
    <section
      id="features"
      aria-label="How it works"
      className="relative overflow-hidden bg-slate-800 pb-28 pt-20 sm:py-32"
    >
      <img
        alt=""
        loading="lazy"
        width="2245"
        height="1636"
        decoding="async"
        data-nimg="1"
        className="absolute left-1/2 top-1/2 max-w-none translate-x-[-44%] translate-y-[-42%] opacity-75"
        src={BackgroundBlob}
      />
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl">
            {header}
          </h2>
          <p className="mt-6 text-lg text-blue-100">{subheader}</p>
        </div>
        <div className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0">
          <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
            <div
              className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal"
              role="tablist"
              aria-orientation="vertical"
            >
              {steps.map((step) => (
                <div
                  key={step.id}
                  className={classNames(
                    'group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6',
                    {
                      'bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10':
                        visibleStep === step.id,
                      'bg-white/10 lg:bg-transparent text-neutral lg:text-white hover:bg-white/10 lg:hover:bg-white/5': visibleStep !== step.id,
                    }
                  )}
                >
                  <h3>
                    <button
                      className="font-display text-lg ui-not-focus-visible:outline-none text-neutral lg:text-white"
                      id="headlessui-tabs-tab-:R9d9afja:"
                      role="tab"
                      type="button"
                      aria-selected="true"
                      tabIndex="0"
                      data-headlessui-state="selected"
                      aria-controls="headlessui-tabs-panel-:R1l9afja:"
                      data-selected=""
                      onClick={() => setVisibleStep(step.id)}
                    >
                      <span className="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none"></span>
                      {step.id}. {step.title}
                    </button>
                  </h3>
                  <p className="mt-2 hidden text-sm lg:block text-white">
                    {step.description}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div className="lg:col-span-7">
            {steps.map((step) => (
              <div
                key={step.id}
                id="headlessui-tabs-panel-:R1l9afja:"
                role="tabpanel"
                tabIndex="0"
                data-headlessui-state="selected"
                aria-labelledby="headlessui-tabs-tab-:R9d9afja:"
                data-selected=""
                className={classNames({ hidden: visibleStep !== step.id })}
              >
                <div className="relative sm:px-6 lg:hidden">
                  <div className="absolute -inset-x-4 bottom-[-4.25rem] top-[-6.5rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl"></div>
                  <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">
                    {step.description}
                  </p>
                </div>
                <div className="mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]">
                  <img
                    alt=""
                    fetchpriority="high"
                    width="2174"
                    height="1464"
                    decoding="async"
                    data-nimg="1"
                    className="w-full"
                    sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                    src={step.image}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
