import PeopleIndex from '../components/organisms/PeopleIndex.jsx'
import { PeopleProvider } from '../stores/people'

export const PeoplePage = () => {
  return (
    <PeopleProvider>
      <div className="PeoplePage h-full overflow-y-auto bg-white">
        <PeopleIndex />
      </div>
    </PeopleProvider>
  );
}