import { callExternalApi } from './external-api.service'

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL
const DEFAULT_META = { page: 1, perPage: 10, orderBy: 'id', orderDirection: 'asc', filters: [] }

export const fetchCalendars = async ({ meta = DEFAULT_META } = { meta: DEFAULT_META }) => {
  const accessToken = localStorage.getItem('accessToken')
  const filtersForUrl = meta.filters.map((f) => `${f.field}__${f.operator}=${f.value}`).join('&')

  const config = {
    url: `${apiServerUrl}/api/calendars?page=${meta.page}&per_page=${meta.perPage}&order_by=${meta.orderBy}&order_direction=${meta.orderDirection}&${filtersForUrl}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }

  const { data, error } = await callExternalApi({ config }, false)

  return {
    data: data || null,
    error,
  }
}

export const deleteCalendar = async ({ id }) => {
  const accessToken = localStorage.getItem('accessToken')

  const config = {
    url: `${apiServerUrl}/api/calendars/${id}`,
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }

  const { data, error } = await callExternalApi({ config }, false)

  return {
    data: data || null,
    error,
  }
}

export const syncCalendar = async ({ id }) => {
  const accessToken = localStorage.getItem('accessToken')

  const config = {
    url: `${apiServerUrl}/api/calendars/${id}/sync`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }

  const { data, error } = await callExternalApi({ config }, false)

  return {
    data: data || null,
    error,
  }
}

export const syncAllCalendars = async () => {
  const accessToken = localStorage.getItem('accessToken')

  const config = {
    url: `${apiServerUrl}/api/calendars/sync_all`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }

  const { data, error } = await callExternalApi({ config }, false)

  return {
    data: data || null,
    error,
  }
}

export const createCalendar = async (payload) => {
  const accessToken = localStorage.getItem('accessToken')

  const config = {
    url: `${apiServerUrl}/api/calendars`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: payload,
  }

  const { data, error } = await callExternalApi({ config }, false)

  return {
    data: data || null,
    error,
  }
}

export const setMainPerson = async({ id, user_email }) => {
  const accessToken = localStorage.getItem('accessToken')

  const config = {
    url: `${apiServerUrl}/api/calendars/${id}/set_main_person`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: { user_email },
  }

  const { data, error } = await callExternalApi({ config }, false)

  return {
    data: data || null,
    error,
  }
}

