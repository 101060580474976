import React, { useCallback, useState } from 'react';
import debounce from 'lodash/debounce';
import FileUpload from './FileUpload';
import Breadcrumbs from '../atoms/Breadcrumbs';
import CalendarTable from './CalendarTable';
import { useCalendarsDispatch } from '../../stores/calendars';
import ErrorBoundary from '../atoms/ErrorBoundary';
import ModalLinkCalendar from './ModalLinkCalendar';
import { useCalendarMutation } from '../hooks/useCalendarMutation';
import useMixpanel, { EVENTS } from '../hooks/useMixpanel';
import { openErrorToast, openSuccessToast } from '../../utils/toast.utils';

const CalendarsIndex = () => {
  const calendarDispatch = useCalendarsDispatch();
  const [modalLinkCalendarOpen, setModalLinkCalendarOpen] = useState(false);
  const {
    mutate: mutateCalendar,
    isLoading: isMutationLoading,
    isError: isMutationError,
  } = useCalendarMutation();
  const { track } = useMixpanel();

  const handleSearchChange = useCallback(
    debounce((e) => {
      calendarDispatch({
        type: 'ADD_FILTER',
        payload: { field: 'name', operator: 'like', value: e.target.value },
      });
    }, 250),
    []
  );

  const handleOpenLinkCalendarModal = () => {
    track(EVENTS.OPEN_LINK_CALENDAR_MODAL);
    setModalLinkCalendarOpen(true);
  };

  const handleCloseLinkCalendarModal = () => {
    setModalLinkCalendarOpen(false);
  };

  const linkCalendar = async (url) => {
    const resp = await mutateCalendar({
      payload: { calendar_url: url },
      action: 'CREATE',
    });

    if (resp.data && !resp.error) {
      openSuccessToast({
        title: 'Calendar linked',
        description: resp.data.message,
      });
    } else {
      openErrorToast({
        title: 'Error',
        description: resp?.data?.message || 'Something went wrong',
      });
    }
    handleCloseLinkCalendarModal();
  };

  return (
    <div className="CalendarsIndex">
      <ModalLinkCalendar
        onLink={linkCalendar}
        onClose={handleCloseLinkCalendarModal}
        open={modalLinkCalendarOpen}
      />
      <div className="page-header p-4">
        <div className="mb-4">
          <Breadcrumbs
            breadcrumbs={[
              { label: 'Home', url: '/my/dashboard' },
              { label: 'Calendars' },
            ]}
          />
          <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
            Calendars
          </h1>
        </div>
        <div className="items-center justify-between block sm:flex md:divide-x md:divide-gray-100 dark:divide-gray-700">
          <div className="flex items-center mb-4 sm:mb-0">
            <form
              className="sm:pr-3"
              action="#"
              method="GET"
              onSubmit={(e) => e.preventDefault()}
            >
              <label htmlFor="calendars-search" className="sr-only">
                Search
              </label>
              <div className="relative w-48 mt-1 sm:w-64 xl:w-96">
                <input
                  type="text"
                  name="email"
                  id="calendars-search"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Search..."
                  onChange={handleSearchChange}
                />
              </div>
            </form>
          </div>

          <div className="flex gap-2">
            <FileUpload label="Upload calendar file" />
          </div>
        </div>
      </div>

      <ErrorBoundary>
        <CalendarTable
          className="p-4"
          onOpenLinkCalendarModal={handleOpenLinkCalendarModal}
        />
      </ErrorBoundary>
    </div>
  );
};

export default CalendarsIndex;
