import { Link } from 'react-router-dom'

export const personalPlanPrice = 20

export const supportedFeatures = [
  { name: 'Secure signup & login via Auth0',
    description: 'Bank-level encryption to protect your data using Auth0 to handle signup & login, and Stripe for payment.',
    supported: true
  },
  { name: 'Secure & anonymous import of unlimited calendars and events',
    description: 'We support .ics calendars which is the standard format used by Google Calendar, Apple Calendar, Outlook Calendar, and other calendar apps.',
    supported: true
  },
  { name: 'Daily Time-in-meetings graph', supported: true },
  { name: 'Daily Stress graph', supported: true },
  { name: 'Week-to-week comparison', supported: true },
  { name: 'Event Frequency by Day of the Week, Month, Year', description: 'This answers: When does a particular type of event happen in a particular period', supported: true },
  { name: 'List events by person', supported: true, description: 'This answers: who\'s the busiest person? Or simply check if two people meet together too often.' },
  { name: 'Event grouping and sum duration', supported: true, description: 'This answers: how much time do I spend on a particular kind of meeting?' },
  {name: '"Focus mode" to display events from all calendars in one place to avoid conflicts', supported: true },
  { name: 'Calendar (.ics) anonymizer',
    description: <>If you feel uncomfortable uploading a calendar file (.ics), we provide <Link to="/anonymize" className="underline">this script</Link> that you can run in your computer to anonymize the data before you upload it.</>,
    supported: true
  },
  { name: 'Meeting conflicts graph (in development)', supported: false },
  { name: 'Estimated meeting cost graph (in development)', supported: false },
  { name: 'PTO planner and analyzer (coming soon)', supported: false },
  { name: '3rd-party integrations (coming soon)', supported: false },
  { name: 'AI analysis (coming soon)', supported: false },
  { name: 'Custom reports (coming soon)', supported: false },
]