import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { backendLogout } from '../services/user.service'

const LogoutPage = () => {
  const { logout } = useAuth0()

  const processLogout = async () => {
    await backendLogout()

    localStorage.removeItem('accessToken')
    await logout({
      logoutParams: {
        returnTo: window.location.origin,
        clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      }
    })
  }

  useEffect(() => {
    processLogout()
  }, [])

  return null
}

export default LogoutPage