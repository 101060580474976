import { PageLayout } from '../layouts/page-layout'
import { CodeSnippet } from '../components/CodeSnippet'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export default function AnonymizePage () {
  const inlineCodeClasses = 'bg-gray-300 px-1 rounded text-gray-600'
  const [rawAnonymizerClass, setRawAnonymizerClass] = useState(null)
  const filePath = `${process.env.REACT_APP_API_SERVER_URL}/icalAnonymizerClass.js`

  useEffect(() => {
    fetch(filePath)
      .then(res => res.text())
      .then(setRawAnonymizerClass)
  }, [filePath])

  return (
    <PageLayout>
      <div className="container px-4 pt-24 mx-auto md:pt-32 lg:px-0 dark:bg-gray-900">
        <div className="text-center">
          <h1
            className="mb-3 text-3xl font-bold text-gray-900 sm:text-4xl sm:leading-none sm:tracking-tight dark:text-white">
            Anonymize your calendar
          </h1>
          <p className="mb-6 text-lg font-normal text-gray-500 sm:text-xl dark:text-gray-400">
            You can optionally use the script below to anonymize your calendar <br/> before uploading it to our server.
          </p>
        </div>

        <section
          className="p-6 grid grid-cols-1 space-y-12 md:space-y-0 md:gap-x-6 md:gap-6 pt-9 justify-center"
        >
          <h3 className="text-2xl border-b-2 pb-2">Instructions how to run the script:</h3>
          <ul>
            <li>1. The script below requires Node.js to be installed on your computer. You can download it from <a
              href="https://nodejs.org/en/download/" target="_blank" rel="noreferrer"
              className="underline">https://nodejs.org/en/download/</a>.
            </li>
            <li>2. Copy the script below (or <Link className="underline"
                                                   to={`${process.env.REACT_APP_API_SERVER_URL}/static/js/icalAnonymizerClass.js`}
                                                   target="_blank">open it here</Link>) and save it as a file
              named <code>icalAnonymizerClass.js</code> on your computer.
            </li>
            <li>3. Open a terminal window and navigate to the folder where you saved the script.(ie. <span
              className={inlineCodeClasses}>cd my_folder</span>)
            </li>
            <li>4. Run the script by typing <span className={inlineCodeClasses}>node icalAnonymizerClass.js path_to_your_calendar_file.ics</span> in
              the terminal window.
            </li>
            <li className="opacity-50">Tip: You can get the <span
              className={inlineCodeClasses}>path_to_your_calendar.ics</span>, by dragging and dropping the file into the
              terminal window to get the path.
            </li>
          </ul>

          <p>
            The script will create, in the same folder as the original calendar file: <br/>
            - a new file named <code className={inlineCodeClasses}>your_file_name.ics-anonymized.ics</code> with the
            anonymized data.<br/>
            - a new file named <code className={inlineCodeClasses}>your_file_name.ics-mapping.json</code> with the
            mapping between the original and anonymized data. This is for your reference only and is not needed for the
            upload.
          </p>

          <CodeSnippet path={filePath} code={rawAnonymizerClass} title="icalAnonymizerClass.js"/>
        </section>
      </div>
    </PageLayout>
  )
}