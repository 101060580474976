import { Button } from '@mui/joy';
import { sendContactMessage } from '../services/contact.service';
import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import CollapsableCard from '../components/molecules/CollapsableCard';
import Breadcrumbs from '../components/atoms/Breadcrumbs';

export const ReportsPage = () => {
  const { user } = useAuth0();
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const resp = await sendContactMessage(
      user.email,
      'Report Suggestion',
      message
    );

    if (resp.error) {
      toast.error('An error occurred while sending the message');
      return;
    }

    toast.success('Message sent successfully');
    setMessage('');
  };
  return (
    <div className="CalendarsPage h-full overflow-y-auto bg-white">
      <div className="page-header p-4">
        <div className="mb-4">
          <Breadcrumbs
            breadcrumbs={[
              { label: 'Home', url: '/my/dashboard' },
              { label: 'Reports' },
            ]}
          />
          <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
            Reports
          </h1>
        </div>
        <CollapsableCard
          title={<b>We're listening!</b>}
          className="bg-white p-4"
          initiallyExpanded={true}
          collapsable={false}
        >
          <p className="mb-4">
            We are building only the reports you care the most. If you have any
            ideas for potential reports, please let us know below:
          </p>
          <form
            action="#"
            method="POST"
            onSubmit={handleSubmit}
            className="w-full"
          >
            <div className="mb-6">
              <textarea
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                id="report-idea"
                type="text"
                rows="3"
                placeholder="Describe your report idea..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>

            <Button type="submit">Submit</Button>
          </form>
        </CollapsableCard>
      </div>
    </div>
  );
};
