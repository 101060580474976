import * as React from 'react'
import Dropdown from '@mui/joy/Dropdown'
import IconButton from '@mui/joy/IconButton'
import Menu from '@mui/joy/Menu'
import MenuButton from '@mui/joy/MenuButton'
import MenuItem from '@mui/joy/MenuItem'
import MoreVert from '@mui/icons-material/MoreVert'

export default function TasksFilterDropdown ({ onClick, filters }) {
  const completedField = filters.find(f => f.field == 'completed') || { value: false }

  return (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: 'plain', color: 'neutral' } }}
      >
        <MoreVert/>
      </MenuButton>
      <Menu>
        <MenuItem onClick={() => onClick({ action: 'toggleCompletedTasksDisplay' })}>
          {completedField.value ? 'Hide' : 'Show'} completed tasks
        </MenuItem>
      </Menu>
    </Dropdown>
  )
}
