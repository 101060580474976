import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  createCalendar,
  deleteCalendar,
  setMainPerson,
  syncAllCalendars,
  syncCalendar
} from '../../services/calendar.service'

export const useCalendarMutation = () => {
  const queryClient = useQueryClient()
  const queryKey = ['calendars']

  const invalidateQueries = async () => {
    await queryClient.invalidateQueries({ queryKey: ['calendars'] })
    await queryClient.invalidateQueries({ queryKey: ['people'] })
    await queryClient.invalidateQueries({ queryKey: ['events'] })
    await queryClient.invalidateQueries({ queryKey: ['eventsStats'] })
    await queryClient.invalidateQueries({ queryKey: ['stats'] })
  }

  const callMutationMethod = async ({ id, payload, action = 'DELETE' }) => {
    if (action === 'DELETE') {
      return deleteCalendar({ id })
    } else if (action === 'CREATE') {
      return createCalendar(payload)
    } else if (action === 'SET_MAIN_PERSON') {
      const resp = await setMainPerson(payload)
      await queryClient.invalidateQueries({ queryKey: ['events'] })
      await queryClient.invalidateQueries({ queryKey: ['stats'] })
      return resp
    } else if (action === 'SYNC') {
      const resp = await syncCalendar({ id })
      await invalidateQueries()
      return resp
    } else if (action === 'SYNC_ALL') {
      const resp = await syncAllCalendars()
      await invalidateQueries()
      return resp
    } else if (action === 'REFRESH') {
      await invalidateQueries()
      return
    }

    throw new Error('Method not supported')
  }

  const { isLoading, isSuccess, isError, data, error, mutateAsync: mutate } = useMutation({
    queryKey,
    mutationFn: callMutationMethod,
    onMutate: () => {
      queryClient.cancelQueries(queryKey)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['calendars'] })
    }
  })

  return {
    mutate,
    isLoading,
    isError,
    isSuccess,
    data: data?.data,
    meta: data?.meta,
    error,
  }
}