import { callExternalApi } from './external-api.service'

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL

export const uploadFile = async (formData) => {
  const accessToken = localStorage.getItem('accessToken')
  const config = {
    url: `${apiServerUrl}/api/users/files/upload`,
    method: 'POST',
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData
  }

  const { data, error } = await callExternalApi({ config })

  return {
    data: data || null,
    error,
  }
}