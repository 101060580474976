import React from "react";
import { HeroBanner } from "../components/HeroBanner";
import { PageLayout } from "../layouts/page-layout";
import HomePageContent from "../components/HomePageContent";


export const HomePage = () => (
  <PageLayout>
    {/* <HeroBanner /> */}
    <HomePageContent />
  </PageLayout>
);
