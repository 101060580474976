import React, { useState } from 'react'

export default function ImageWithDefault ({ imageUrl, defaultUrl, className = '', alt = '' }) {
  const [src, setSrc] = useState(imageUrl || defaultUrl)

  const handleError = () => {
    setSrc(defaultUrl)
  }
  
  return <img src={src} onError={handleError} alt={alt} className={className}/>
}