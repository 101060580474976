export const DEFAULT_META = { page: 1, perPage: 10, orderBy: 'id', orderDirection: 'asc', filters: [] }

export function generateQueryKey ({ topKey, meta, filtersInState }) {
  const filters = [...filtersInState, ...meta.filters]
  const metaWithFilters = { ...meta, filters }
  const flattenFilters = filters.map((f) => `${f.field}__${f.operator}=${f.value}`)
  const queryKey = [topKey, meta, flattenFilters.join('&')]
  return { metaWithFilters, queryKey }
}

export const getUniqueFilters = (filters, newFilter) => {
  return [...filters.filter(filter => !(filter.field === newFilter.field && filter.operator === newFilter.operator)), newFilter]
}