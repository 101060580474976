import { useCallback } from 'react'
import debounce from 'lodash/debounce'
import Breadcrumbs from '../atoms/Breadcrumbs'
import PersonTable from './PersonTable'
import { usePeopleDispatch } from '../../stores/people'

const PeopleIndex = () => {
  const peopleDispatch = usePeopleDispatch()

  const handleSearchChange = useCallback(debounce((e) => {
    peopleDispatch({ type: 'ADD_FILTER', payload: { field: 'name', operator: 'like', value: e.target.value } })
  }, 250), [])

  return (
    <div className="PeopleIndex">
      <div className="page-header p-4">
        <div className="mb-4">
          <Breadcrumbs breadcrumbs={[{ label: 'Home', url: '/my/dashboard' }, { label: 'People' }]}/>
          <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">People</h1>
        </div>
        <div className="items-center justify-between block sm:flex md:divide-x md:divide-gray-100 dark:divide-gray-700">
          <div className="flex items-center mb-4 sm:mb-0">
            <form className="sm:pr-3" action="#" method="GET" onSubmit={(e) => e.preventDefault()}>
              <label htmlFor="people-search" className="sr-only">Search</label>
              <div className="relative w-48 mt-1 sm:w-64 xl:w-96">
                <input
                  type="text"
                  name="email"
                  id="people-search"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Search..."
                  onChange={handleSearchChange}
                />
                <input type="submit" className="hidden"/>
              </div>
            </form>
          </div>
        </div>
      </div>

      <PersonTable className="p-4"/>
    </div>
  )
}

export default PeopleIndex