import { useQuery } from '@tanstack/react-query'
import { fetchStats } from '../../services/stats.service.js'
import { useStatsState } from '../../stores/stats.js'
import { DEFAULT_META, generateQueryKey } from '../../utils/store.utils.js'

const defaultProps = {
  meta: DEFAULT_META,
}

/**
 * Pull stats from the API for either all calendars or a specific calendar.
 */
export const useStatsQuery = ({ meta } = defaultProps, options) => {
  const { filters: filtersInState } = useStatsState()
  const { metaWithFilters, queryKey } = generateQueryKey({ topKey: 'stats', meta, filtersInState })

  const { isLoading, isError, data, error } = useQuery({
    queryKey,
    queryFn: () => fetchStats({ meta: metaWithFilters }, options),
  })

  return {
    isLoading,
    isError,
    data: data?.data,
    meta: data?.meta,
    error,
  }
}