import React from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// example: https://recharts.org/en-US/examples/SimpleBarChart
const VerticalChart = ({ data, onClick, minWidth, minHeight, height, width, options = {} }) => {
  if (!data) {
    return null
  }

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={minHeight || 100} minWidth={minWidth || 100}>
      <BarChart
        onClick={({ activeLabel }) => onClick({ label: activeLabel })}
        width={width || 100}
        height={height || 100}
        data={data}
        margin={{
          top: 5,
          // right: 30,
          // left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" />
        {!options.hideYAxis && <YAxis />}
        <Tooltip />
        {/*<Legend />*/}
        <Bar dataKey="total" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default VerticalChart