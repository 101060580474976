import { Button as JoyButton } from '@mui/joy'

const Button = ({ variant, color, onClick, loading, children }) => {
  return (
    <JoyButton variant={variant} color={color} onClick={onClick} loading={loading}>
      {children}
    </JoyButton>
  )
}

export default Button

