import { callExternalApi } from './external-api.service'
import { generateApiParams } from '../utils/services.utils'

const DEFAULT_META = { filters: [] }

export const fetchStats = async ({ meta = DEFAULT_META } = { meta: DEFAULT_META }, options) => {
  let { accessToken, filtersForUrl, baseUrl } = generateApiParams(meta, options)

  const config = {
    url: `${baseUrl}/stats?${filtersForUrl}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }

  const { data, error } = await callExternalApi({ config }, false)

  return {
    data: data || null,
    error,
  }
}
