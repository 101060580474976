import { useMutation, useQueryClient } from '@tanstack/react-query'
import { submitFeedback } from '../../services/event.service'
import { useEventsDispatch, useEventsState } from '../../stores/events'
import { generateQueryKey } from '../../utils/store.utils'

export const useEventMutation = () => {
  const { filters: filtersInState } = useEventsState()
  const eventsDispatch = useEventsDispatch()
  const queryClient = useQueryClient()
  const queryKey = ['events']

  const callMutationMethod = ({ id, payload, action }) => {
    if (action.toUpperCase() === 'CREATE_OR_UPDATE_FEEDBACK') {
      return submitFeedback(id, payload)
    } else if (action.toUpperCase() === 'REFRESH') {
      return queryClient.invalidateQueries({ queryKey })
    }

    throw new Error('Method not supported')
  }
  const { isLoading, isSuccess, isError, data, error, mutateAsync: mutate } = useMutation({
    queryKey,
    mutationFn: callMutationMethod,
    onMutate: async ({ id, payload }) => {
      queryClient.cancelQueries(queryKey)
      // Assuming `eventsDispatch` is accessible in this scope, otherwise, it needs to be passed as a parameter or context
      const { queryKey: eventQueryKey } = generateQueryKey({
        topKey: 'events', meta: {
          page: 1,
          perPage: 1,
          orderBy: 'id',
          orderDirection: 'asc',
          filters: [
            { field: 'event_id', operator: 'eq', value: id },
            { field: 'with_stress', operator: 'eq', value: true },
            { field: 'with_people', operator: 'eq', value: true },
          ]
        }, filtersInState
      })

      const queryData = queryClient.getQueryData(eventQueryKey)
      const previousEvent = queryData.data.data[0]
      if (previousEvent) {
        const updatedEvent = { ...previousEvent, feedback: payload }
        eventsDispatch({ type: 'SET_SELECTED_EVENT', payload: updatedEvent })
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey })
    }
  })

  return {
    mutate,
    isLoading,
    isError,
    isSuccess,
    data: data?.data,
    meta: data?.meta,
    error,
  }
}
