import { useQuery } from '@tanstack/react-query'
import { fetchEvents } from '../../services/event.service.js'
import { useEventsState } from '../../stores/events.js'
import { DEFAULT_META, generateQueryKey } from '../../utils/store.utils'

const defaultProps = {
  meta: DEFAULT_META,
}

export const useEventsQuery = ({ meta } = defaultProps) => {
  const { filters: filtersInState, selectedView } = useEventsState()
  const { metaWithFilters, queryKey } = generateQueryKey({ topKey: 'events', meta, filtersInState })

  const { isLoading, isError, data, error } = useQuery({
    queryKey,
    queryFn: () => fetchEvents({ meta: metaWithFilters, view: selectedView }),
  })

  return {
    isLoading,
    isError,
    data: data?.data,
    meta: data?.meta,
    error,
    queryKey,
  }
}