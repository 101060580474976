import { CTLayout } from '../layouts/ct-layout'
import React from 'react'
import { MainDashboard } from '../components/MainDashboard'
import { Route, Routes } from 'react-router-dom'
import { CalendarsPage } from './calendars-page'
import { ReportsPage } from './reports-page'
import { PeoplePage } from './people-page'
import { EventsPage } from './events-page'
import { useGlobalState } from '../stores/global'
import classNames from 'classnames'
import { ProfilePage } from './profile-page'
import { Focus } from '../components/Focus'
import { StatsProvider } from '../stores/stats'
import { Elements } from '@stripe/react-stripe-js'
import { SubscriptionPage } from './subscription-page'
import { loadStripe } from '@stripe/stripe-js'
import { useStripeConfigQuery } from '../components/hooks/useStripeConfigQuery'
import { PageLoader } from '../components/PageLoader'
import { useQueryClient } from '@tanstack/react-query'
import { useCable } from '../cable'
import { openSuccessToast } from '../utils/toast.utils'
import _ from 'lodash'
import withEventsProvider from '../components/hooks/withEventsProvider'
import withStatsProvider from '../components/hooks/withStatsProvider'

const DashboardPageComponent = ({ Layout = CTLayout }) => {
  const { isSidebarOpen } = useGlobalState()
  const { data, isLoading: isLoadingStripeConfig } = useStripeConfigQuery()
  const queryClient = useQueryClient()

  // we need to hook up to the cable after the user is authenticated
  // to refetch the queries with a token
  useCable({
    subscription: { channel: 'FileUploadChannel' },
    onReceived: (data) => {
      if (data.status === 'success' || data.status === 'error') {
        openSuccessToast({ title: _.startCase(_.toLower(data.status)), description: data.message })
      }

      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['calendars'] })
        queryClient.invalidateQueries({ queryKey: ['events'] })
        queryClient.invalidateQueries({ queryKey: ['eventsStats'] })
        queryClient.invalidateQueries({ queryKey: ['people'] })
        queryClient.invalidateQueries({ queryKey: ['stats'] })
      }, 1000)
    },
  })

  return (
    <Layout>
      <div className="flex pt-16 overflow-hidden bg-gray-50 dark:bg-gray-900 h-screen">
        <div className="fixed inset-0 z-10 hidden bg-gray-900/50 dark:bg-gray-900/90" id="sidebarBackdrop"></div>
        <div id="main-content"
             className={classNames('relative flex flex-col w-full h-full overflow-y-auto bg-gray-50 dark:bg-gray-900 transition-width duration-75', {
               'lg:ml-64': isSidebarOpen,
               'ml-6': !isSidebarOpen
             })}>
          <Routes>
            <Route
              path="/profile"
              element={<ProfilePage />}
            />
            <Route
              path="/subscription"
              element={
                isLoadingStripeConfig
                  ? <PageLoader/>
                  : (
                    <Elements stripe={loadStripe(data?.publishableKey)} options={{
                      appearance: {
                        theme: 'stripe',
                      },
                    }}>
                      <SubscriptionPage/>
                    </Elements>
                  )}
            />
            <Route path="/dashboard" element={<MainDashboard/>}/>
            <Route path="/focus" element={<Focus/>}/>
            <Route path="/calendars" element={<CalendarsPage />}/>
            <Route path="/people" element={<PeoplePage />}/>
            <Route path="/people/:personId/events" element={<EventsPage />}/>
            <Route path="/calendars/:calendarId/events" element={<EventsPage />}/>
            <Route path="/reports" element={<ReportsPage />}/>
          </Routes>
        </div>
      </div>
    </Layout>
  )
}

export const DashboardPage = withEventsProvider(withStatsProvider(DashboardPageComponent));