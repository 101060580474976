import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { App } from './app'
import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate'
import '@fontsource/inter'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Toaster } from 'react-hot-toast'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import './styles/styles.css'
import { GlobalProvider } from './stores/global'
import { CalendarsProvider } from './stores/calendars'
import { CssVarsProvider, extendTheme } from '@mui/joy/styles'
import * as Sentry from '@sentry/react'
import mixpanel from 'mixpanel-browser'

if (process.env.REACT_APP_MIXPANEL_ENABLED === 'true') {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    debug: true,
    track_pageview: true,
    persistence: 'localStorage'
  })
}

if (process.env.REACT_APP_SENTRY_ENABLED === 'true') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.replayIntegration(),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const container = document.getElementById('root')
const root = createRoot(container)
const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 60 * 1000 } } // 1 minute
})

const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        background: {
          surface: '#FFF'
        },
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <GlobalProvider>
        <Auth0ProviderWithNavigate>
          <QueryClientProvider client={queryClient}>
            <CalendarsProvider>
              <CssVarsProvider theme={theme}>
                <App/>
              </CssVarsProvider>
            </CalendarsProvider>
            <Toaster/>
            <ReactQueryDevtools initialIsOpen={false}/>
          </QueryClientProvider>
        </Auth0ProviderWithNavigate>
      </GlobalProvider>
    </BrowserRouter>
  </React.StrictMode>
)
