import { PageLayout } from '../layouts/page-layout';
import EarlyAdoptersPlan from '../components/EarlyAdoptersPlan';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import PriceCard from '../components/PriceCard';
// import useMixpanel, { EVENTS } from '../components/hooks/useMixpanel'
import { SignupButton } from '../auth0-components/buttons/signup-button';
import { personalPlanPrice, supportedFeatures } from '../config';
import SectionFaq from '../components/molecules/HomePageSections/SectionFaq';

export default function PricingPage() {
  // const { track } = useMixpanel()

  useEffect(() => {
    // example of how to track an event. In this case, tracking page view is unnecessary because Mixpanel does it automatically.
    // track(EVENTS.VIEW_PRICING_PAGE)
  }, []);
  const faqData = [
    {
      title: 'How safe is my data?',
      description:
        'We use bank-level encryption to protect your data. We also use Auth0 to handle signup & login, and Stripe for payment. Your data is transmited using AES-256 encryption and also encrypted at-rest.',
    },
    {
      title: 'How does support work?',
      description:
        "We're aware of the importance of well-qualified support. Feel free to contact us, and we'll help you out right away.",
    },
    {
      title: 'How can I request new features?',
      description:
        "We're always open to feedback and feature requests. Feel free to contact us, and we'll add your request to our roadmap.",
    },
    {
      title: 'Can I cancel my subscription at any time?',
      description:
        'Yes, you can cancel your subscription at any time through your account settings. There are no long-term commitments. No questions asked.',
    },
    {
      title: 'Is there a free trial available?',
      description:
        'Yes, we offer a 14-day free trial so you can explore CalendarTrends and see how it fits your needs before committing to a subscription.',
    },
    {
      title: 'What types of calendars can I connect?',
      description:
        'You can connect calendars from Google, Apple, Outlook, and any other service that supports the .ics file format.',
    },
    {
      title: 'How often is my data updated?',
      description:
        'Your data is updated in real-time, ensuring that you always have the most current insights and analysis available.',
    },
    {
      title: 'What happens to my data if I cancel my subscription?',
      description:
        'If you cancel your subscription, your data will be securely deleted from our servers within 30 days.',
    },
    {
      title: 'Do you offer integrations with other tools?',
      description:
        'We are constantly working on adding new integrations. Currently, we support integrations with popular calendar services and are planning to expand to other productivity tools.',
    },
  ];

  return (
    <PageLayout>
      <div className="px-4 pt-32 mx-auto md:pt-32 lg:px-0 dark:bg-gray-900 flex flex-col justify-items-center">
        <div className="text-center">
          <h1 className="mb-3 text-3xl font-bold text-gray-900 sm:text-4xl sm:leading-none sm:tracking-tight dark:text-white">
            Simple Pricing
          </h1>
          <p className="mb-6 text-lg font-normal text-gray-500 sm:text-xl dark:text-gray-400">
            One plan that includes all features
            <br />
            {/*We're currently in beta, so you can enjoy a <b className="font-bold">FREE FOREVER</b> subscription.*/}
          </p>
        </div>

        <section className="grid grid-cols-1 space-y-12 md:space-y-0 md:gap-x-6 md:gap-6 py-8 mb-8 justify-center">
          <PriceCard
            name="Personal plan"
            description={
              <>
                Ideal for solopreneurs, busy individuals, and forward-thinkers
                who want to take control of both their personal and professional
                schedules.
              </>
            }
            actionButton={
              <SignupButton
                className="text-md
                group
                inline-flex
                items-center
                justify-center
                rounded-full
                py-2
                px-8
                font-semibold
                focus:outline-none
                focus-visible:outline-2
                focus-visible:outline-offset-2
                bg-slate-900
                text-white
                hover:bg-slate-800
                hover:text-slate-100
                active:bg-slate-800
                active:text-slate-300
                focus-visible:outline-slate-900
                transition
                duration-75
                ease-in-out
                transform
                hover:scale-105"
              >
                Start for free now
              </SignupButton>
            }
            actionButtonDescription={
              <>
                100% satisfaction guaranteed, cancel anytime. <br />
                14 day free trial, no credit card required.
              </>
            }
            price={personalPlanPrice}
            frequency="month"
            features={supportedFeatures}
          />

          {/* <EarlyAdoptersPlan/> */}

          {/*<PriceCard*/}
          {/*  name="Early Adopters"*/}
          {/*  description={<>*/}
          {/*    As an Early Adopter, you'll enjoy a unique opportunity to be among the first to experience*/}
          {/*    and influence our cutting-edge SaaS app. This plan is ideal for solopreneurs, busy individuals,*/}
          {/*    and forward-thinkers who want to take control of both their personal and professional schedules.*/}
          {/*</>}*/}
          {/*  price={0}*/}
          {/*  frequency="month"*/}
          {/*  features={[*/}
          {/*    { name: 'Access personalized predictive insights that empower you to proactively manage your time.', supported: true },*/}
          {/*    { name: 'Unlimited Calendars, up to 30 days of events', supported: true },*/}
          {/*    { name: 'Anonymous events, ensuring your privacy while maintaining accuracy', supported: true },*/}
          {/*    { name: 'Trend analysis to uncover patterns that optimize your scheduling decisions', supported: true },*/}
          {/*    { name: 'Unlimited product updates', supported: true },*/}
          {/*    { name: 'Free updates forever', supported: true },*/}
          {/*    { name: 'Early access to new features', supported: true },*/}
          {/*  ]}*/}
          {/*  special="earlyAdopters"*/}
          {/*/>*/}

          {/*<PriceCard*/}
          {/*  className="opacity-50"*/}
          {/*  name="Team"*/}
          {/*  description="Designed for small teams and collaborators."*/}
          {/*  price="0"*/}
          {/*  frequency=""*/}
          {/*  features={[*/}
          {/*    // { name: 'Everything in the Starter Plan', supported: true },*/}
          {/*    // { name: 'Unlimited Calendars, up to 3 months worth of events', supported: true },*/}
          {/*    // { name: 'Team size: 5 users', supported: true },*/}
          {/*    // { name: 'Collaborative event planning', supported: true },*/}
          {/*    // { name: 'Shared team analytics dashboard', supported: true },*/}
          {/*    // { name: 'Team-wide calendar synchronization', supported: true },*/}
          {/*    // { name: 'Customizable team branding', supported: true },*/}
          {/*    // { name: 'Monthly usage reports', supported: true },*/}
          {/*    // { name: 'Early access to new features', supported: true },*/}
          {/*  ]}*/}
          {/*  comingSoon*/}
          {/*/>*/}
          {/*<PriceCard*/}
          {/*  className="opacity-50"*/}
          {/*  name="Business"*/}
          {/*  description="Tailored for businesses and organizations."*/}
          {/*  price="0"*/}
          {/*  frequency=""*/}
          {/*  features={[*/}
          {/*    // { name: 'Everything in the Team Plan', supported: true },*/}
          {/*    // { name: 'Unlimited Calendars, unlimited events', supported: true },*/}
          {/*    // { name: 'Team size: unlimited', supported: true },*/}
          {/*    // { name: 'Team-wide trend tracking', supported: true },*/}
          {/*    // { name: 'Detailed reporting and analytics', supported: true },*/}
          {/*    // { name: 'Priority customer support', supported: true },*/}
          {/*  ]}*/}
          {/*  comingSoon*/}
          {/*/>*/}
        </section>

        <SectionFaq faqData={faqData} />
      </div>
    </PageLayout>
  );
}
