import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

export default function UserMenu ({ onDismiss, user }) {
  const navigate = useNavigate()
  const { name, email } = user

  const handleLogout = () => {
    navigate('/logout')
  }

  const notInDashboard = !window.location.pathname.startsWith('/my/')

  return (
    <div className="fixed top-0 left-0 z-50 w-full h-full" onClick={onDismiss}>
      <div
        className="absolute top-9 right-5 border z-50 my-4 w-56 text-base list-none bg-white rounded divide-y divide-gray-100 shadow-2xl dark:bg-gray-700 dark:divide-gray-600"
        id="userMenuDropdown"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="py-3 px-4">
          <span className="block text-sm font-semibold text-gray-900 dark:text-white truncate">{name}</span>
          <span
            className="block text-sm font-light text-gray-500 truncate dark:text-gray-400">{email}</span>
        </div>
        {notInDashboard && (<ul className="py-1 font-light text-gray-500 dark:text-gray-400">
          <li>
            <Link
              to="/my/dashboard"
              className="flex py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white"
            >
              <svg
                className="opacity-50 mr-2 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
              </svg>
              My Dashboard
            </Link>
          </li>
        </ul>)}
        <ul className="py-1 font-light text-gray-500 dark:text-gray-400" aria-labelledby="userMenuDropdownButton">
          <li>
            <Link
              to="/my/profile"
              className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white"
            >
              My profile
            </Link>
          </li>
          <li>
            <Link
              to="/my/subscription"
              className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white"
            >
              My subscription
            </Link>
          </li>
          {/*<li>*/}
          {/*  <a*/}
          {/*    href="#"*/}
          {/*    className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white"*/}
          {/*  >*/}
          {/*    Account settings*/}
          {/*  </a>*/}
          {/*</li>*/}
        </ul>
        {/*<ul className="py-1 font-light text-gray-500 dark:text-gray-400" aria-labelledby="userMenuDropdownButton">*/}
        {/*  <li>*/}
        {/*    <a href="#"*/}
        {/*       className="flex items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">*/}
        {/*      <svg className="mr-2 w-5 h-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20"*/}
        {/*           xmlns="http://www.w3.org/2000/svg">*/}
        {/*        <path fillRule="evenodd"*/}
        {/*              d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"*/}
        {/*              clipRule="evenodd"></path>*/}
        {/*      </svg>*/}
        {/*      My likes</a>*/}
        {/*  </li>*/}
        {/*  <li>*/}
        {/*    <a href="#"*/}
        {/*       className="flex items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">*/}
        {/*      <svg className="mr-2 w-5 h-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20"*/}
        {/*           xmlns="http://www.w3.org/2000/svg">*/}
        {/*        <path*/}
        {/*          d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path>*/}
        {/*      </svg>*/}
        {/*      Collections</a>*/}
        {/*  </li>*/}
        {/*</ul>*/}
        <ul className="py-1 font-light text-gray-500 dark:text-gray-400" aria-labelledby="dropdown">
          <li>
            <button
              onClick={handleLogout}
              className="w-full text-left py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
            >
              Sign out
            </button>
          </li>
        </ul>
      </div>
    </div>

  )
}