// import SectionFaq from './molecules/HomePageSections/SectionFaq'
// import SectionHeroLeft from './molecules/HomePageSections/SectionHeroLeft'
// import SectionFeaturesRight from './molecules/HomePageSections/SectionFeaturesRight'
import SectionHeroCenter from './molecules/HomePageSections/SectionHeroCenter';
import SectionHowItWorks from './molecules/HomePageSections/SectionHowItWorks';
import SectionWithCircleAndCTA from './molecules/HomePageSections/SectionWithCircleAndCTA';
import SectionFeaturesTop from './molecules/HomePageSections/SectionFeaturesTop';
import SectionQuote from './molecules/HomePageSections/SectionQuote';
import { SparklesPreview } from './atoms/SparklesPreview';
import { GoogleGeminiEffectDemo } from './atoms/GoogleGeminiEffectDemo';
import { useAuth0 } from '@auth0/auth0-react';

export default function HomePageContent() {
  const { loginWithRedirect } = useAuth0();

  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/sync_me",
      },
      authorizationParams: {
        prompt: "login",
        screen_hint: "signup",
      },
    });
  };

  return (
    <div>
      {/* <SparklesPreview title="CalendarTrends" /> */}
      <GoogleGeminiEffectDemo
        title={<SparklesPreview title="Calendar Analytics" className="h-auto" />}
        description={<div className="h-auto -mt-[100px]">for personal and professional calendars.</div>}
        cta="Sign up for free now"
        ctaDescription="14 day free trial, no credit card required"
        onClick={handleSignUp}
      />
      {/* <HeroScrollDemo /> */}
      {/* <SectionHeroCenter /> */}
      <SectionFeaturesTop />
      <SectionHowItWorks />
      <SectionWithCircleAndCTA />
      {/* <SectionFeaturesRight/> */}
      {/* <SectionHeroLeft/> */}
      {/* <SectionFaq/> */}
      <SectionQuote />
    </div>
  );
}
