import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { PageLoader } from './components/PageLoader'
import { AuthenticationGuard } from './auth0-components/authentication-guard'
import { AdminPage } from './pages/admin-page'
import { CallbackPage } from './pages/callback-page'
import { HomePage } from './pages/home-page'
import { NotFoundPage } from './pages/not-found-page'
import { ProtectedPage } from './pages/protected-page'
import PricingPage from './pages/pricing-page'
import ContactPage from './pages/contact-page'
import TermsPage from './pages/terms-page'
import AnonymizePage from './pages/anonymize-page'
import { DashboardPage } from './pages/dashboard-page'
import { DocsPage } from './pages/docs-page'
import SyncMePage from './pages/sync-me-page'
import LogoutPage from './pages/logout-page'
import PrivacyPage from './pages/privacy-page'
import ThankYouPage from './pages/thank-you-page'
import LoginPage from './pages/login-page'
import useReloadCheck from './components/hooks/useReloadCheck'

import './app.css'

export const App = () => {
  const { isLoading } = useAuth0()
  useReloadCheck()

  if (isLoading) {
    return <PageLoader/>
  }

  return (
    <Routes>
      <Route path="/" element={<HomePage/>}/>
      <Route path="/pricing" element={<PricingPage/>}/>
      <Route path="/thank-you" element={<ThankYouPage/>}/>
      <Route path="/contact" element={<ContactPage/>}/>
      <Route path="/terms" element={<TermsPage/>}/>
      <Route path="/privacy" element={<PrivacyPage/>}/>
      <Route path="/docs" element={<DocsPage/>}/>
      {/*<Route path="/about" element={<AboutPage />} />*/}
      <Route path="/anonymize" element={<AnonymizePage/>}/>
      <Route
        path="/protected"
        element={<AuthenticationGuard component={ProtectedPage}/>}
      />
      <Route
        path="/admin"
        element={<AuthenticationGuard component={AdminPage}/>}
      />
      <Route
        path="/sync_me"
        element={<AuthenticationGuard component={SyncMePage}/>}
      />
      {/*render the dashboard in "playground" (visitor) mode, or authenticated mode*/}
      {/*<Route path="/playground/*" element={<PlaygroundPage/>}/>*/}
      <Route
        path="/my/*"
        element={<AuthenticationGuard component={DashboardPage}/>}
      />
      <Route path="/callback" element={<CallbackPage/>}/>
      <Route path="/login" element={<LoginPage/>}/>
      <Route path="/logout" element={<LogoutPage/>}/>
      <Route path="*" element={<NotFoundPage/>}/>
    </Routes>
  )
}
